import { useCallback, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { Helmet } from 'react-helmet';
import { MenuItem, Select } from '@mui/material';
import * as Sentry from '@sentry/react';

import { PageTitle } from '../../../components/PageTitle';
import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { Spinner } from '../../../components/Spinner';

import { Industry } from '../AllIndustries';

import { getIndustries } from '../../../functions/industries';

import { useOrganisation } from '../../../hooks/organisations';
import { useToast } from '../../../hooks/toast';

import { Container, OrganisationCard, OrganisationsContainer } from './styles';
import { changeOrganisationIndustry } from '../../../functions/organisations';
import { firebaseFunctions, isProduction } from '../../../services/firebase';

export const OrgsIndustries = () => {
  const { organisations, setOrganisations } = useOrganisation();
  const { addToast } = useToast();

  const [industries, setIndustries] = useState([] as Industry[]);
  const [isLoadingIndustries, setIsLoadingIndustries] = useState(true);
  const [isChangingIndustry, setIsChangingIndustry] = useState(false);

  useEffect(() => {
    const getIndustriesAsync = async () => {
      try {
        const response = await getIndustries();
        setIndustries(response);
        setIsLoadingIndustries(false);
      } catch (error) {
        console.log(error);
        setIsLoadingIndustries(false);

        addToast({
          title: 'Error loading industries',
          description: 'Please, try again later',
          type: 'error'
        });
      }
    };

    getIndustriesAsync();
  }, [addToast]);

  const handleChangeIndustry = useCallback(
    async (orgId: string, industry: string) => {
      setIsChangingIndustry(true);

      try {
        await changeOrganisationIndustry(orgId, industry);

        const updateIndustryOnOrgScores = firebaseFunctions.httpsCallable(
          'updateOrgScoresIndustry'
        );

        await updateIndustryOnOrgScores({
          organisationId: orgId,
          industry
        });

        setOrganisations((oldState) => {
          const organisationsOldState = cloneDeep(oldState);

          const chosenOrganisationIndex = oldState.findIndex(
            ({ id }) => id === orgId
          );

          if (chosenOrganisationIndex < 0) return oldState;

          organisationsOldState[chosenOrganisationIndex].industry = industry;

          return organisationsOldState;
        });

        setIsChangingIndustry(false);
        addToast({
          title: 'Organisation updated',
          description: 'Changes will take place after you relog',
          type: 'success'
        });
      } catch (error) {
        setIsChangingIndustry(false);

        if (isProduction) {
          Sentry.captureException(error);
        }

        addToast({
          title: 'Error updating organisation industry',
          description: 'Please, try again later',
          type: 'error'
        });
      }
    },
    [setOrganisations, addToast]
  );

  return (
    <Container>
      {isChangingIndustry && <Spinner message="Changing industry" />}
      {isLoadingIndustries && <Spinner message="Loading Industries" />}

      <Helmet>
        <title>Change Orgs Industries</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle heading="Change Orgs Industries" />
      </PageTitleWrapper>

      <OrganisationsContainer>
        {organisations
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((organisation) => (
            <OrganisationCard key={organisation.id}>
              <h3>{organisation.name}</h3>

              <Select
                style={{ width: '100%' }}
                value={organisation.industry.trim()}
                onChange={(event) =>
                  handleChangeIndustry(organisation.id, event.target.value)
                }
              >
                {industries.map((industry) => (
                  <MenuItem
                    key={industry.name}
                    value={industry.name}
                    style={{ cursor: 'default' }}
                  >
                    {industry.name.trim()}
                  </MenuItem>
                ))}
              </Select>
            </OrganisationCard>
          ))}
      </OrganisationsContainer>
    </Container>
  );
};
