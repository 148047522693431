import firebase from 'firebase/compat/app';
import * as Sentry from '@sentry/react';
import { orderBy } from 'lodash';

import { Organisation } from '../models/organisation';
import { Division } from '../models/division';
import { firestore, isProduction } from '../services/firebase';

const collectionOrganisations = firestore.collection('organisations');

export const getDivisions = async (organisationId: string | undefined) => {
  try {
    const organisationSnapshot = await collectionOrganisations
      .doc(organisationId)
      .get();
    const organisation = organisationSnapshot.data() as Organisation;

    return orderBy(organisation.divisions, ['name'], 'asc');
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
    return null;
  }
};

interface CreateDivisionProps {
  division: Division;
  organisationId: string;
}

export const createDivision = async ({
  division,
  organisationId
}: CreateDivisionProps) => {
  try {
    await collectionOrganisations.doc(organisationId).update({
      divisions: firebase.firestore.FieldValue.arrayUnion(division)
    });

    return division;
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
    return false;
  }
};

interface DeleteDivisionProps {
  division: Division;
  organisationId: string;
}

export const deleteDivision = async ({
  division,
  organisationId
}: DeleteDivisionProps) => {
  try {
    await collectionOrganisations.doc(organisationId).update({
      divisions: firebase.firestore.FieldValue.arrayRemove(division)
    });

    return true;
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
    return false;
  }
};

interface UpdateDivisionProps {
  originalDivision: Division;
  editedDivision: Division;
  organisationId: string;
}

export const updateDivision = async ({
  originalDivision,
  editedDivision,
  organisationId
}: UpdateDivisionProps) => {
  try {
    await deleteDivision({ division: originalDivision, organisationId });
    await createDivision({ division: editedDivision, organisationId });

    return true;
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
    return false;
  }
};
