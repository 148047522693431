import { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Column } from 'react-table';
import { isArray, orderBy } from 'lodash';
import { Check, Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { Table } from '../../../components/Table';
import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { PageTitle } from '../../../components/PageTitle';
import { Spinner } from '../../../components/Spinner';

import { formatDate } from '../../../utils/formatDate';

import { useArticles } from '../../../hooks/articles';

import { Container, ArticleImage } from './styles';

import displayImage from '../../../assets/images/displayImage.svg';

interface ArticlesTableRow {
  index: number;
  coverImage: JSX.Element;
  title: string;
  categories: JSX.Element;
  status: JSX.Element;
  publishedAt: string;
}

export const Articles = () => {
  const { articles, isLoadingArticles } = useArticles();
  const navigate = useNavigate();

  const articlesColumns: Column<ArticlesTableRow>[] = useMemo(
    () => [
      {
        Header: `#`,
        accessor: 'index'
      },
      {
        Header: 'Cover',
        accessor: 'coverImage'
      },
      {
        Header: 'Title',
        accessor: 'title'
      },
      {
        Header: 'Categories',
        accessor: 'categories'
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Published at',
        accessor: 'publishedAt'
      }
    ],
    []
  );

  const articlesTableData = useMemo(() => {
    const articlesDataArray = [] as ArticlesTableRow[];

    orderBy(articles, ['publishedAt'], ['desc']);

    articles.map(
      (
        { title, categories, coverImageUrl, isActive, publishedAt },
        orgIndex
      ) => {
        const articleRow = {
          index: orgIndex + 1,
          coverImage: (
            <ArticleImage
              src={coverImageUrl || displayImage}
              alt={title}
              title={title}
            />
          ),
          title,
          categories: (
            <small>{isArray(categories) ? categories.join(', ') : ''}</small>
          ),
          status: (
            <aside className="activation-icon">
              {isActive ? (
                <Check color="success" titleAccess="Public (Active)" />
              ) : (
                <Close color="error" titleAccess="Hidden (Inactive)" />
              )}
            </aside>
          ),
          publishedAt: formatDate({ date: publishedAt as any })
        };

        return articlesDataArray.push(articleRow);
      }
    );

    return articlesDataArray;
  }, [articles]);

  const handleRowClick = useCallback(
    (article: Record<string, any>) => {
      const { id } = articles[article.index - 1];

      navigate(`/article/update/${id}`);
    },
    [navigate, articles]
  );

  return (
    <Container>
      <Helmet>
        <title>Articles - List</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle heading="Articles List" />
      </PageTitleWrapper>

      {isLoadingArticles && <Spinner message="Loading articles" />}

      <Table
        columns={articlesColumns}
        data={articlesTableData}
        handleRowClick={handleRowClick}
      />
    </Container>
  );
};
