import styled from 'styled-components';

export const Wrapper = styled.main`
  padding: 40px;
`;

export const EditInput = styled.input`
  height: 24px;
  margin: 0px;
  border-radius: 4px;
  padding: 4px;
  border: 0px;
  background: #f2f5f9;
  outline: none;
`;

export const Select = styled.select`
  height: 24px;
  margin: 0px;
  border-radius: 4px;
  padding: 4px;
  border: 0px;
  background: #f2f5f9;
  outline: none;
`;

export const TextArea = styled.textarea`
  height: 100px;
  margin: 0px;
  border-radius: 4px;
  padding: 4px;
  border: 0px;
  background: #f2f5f9;
  outline: none;
  width: 100%;
  resize: vertical;
`;

export const SaveEditButton = styled.button`
  cursor: pointer;
  border: none;

  padding-top: 5px;
  border-radius: 8px;

  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;
