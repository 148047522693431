import { useState, useEffect, useCallback } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { Helmet } from 'react-helmet';

import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { PageTitle } from '../../../components/PageTitle';
import { Question } from '../../../models/question';
import { getQuestions } from '../../../functions/questions';
import { Spinner } from '../../../components/Spinner';
import { QuestionCard } from '../components/QuestionCard';

import { Container, QuestionsGridWrapper, ToggleViewWrapper } from './styles';

interface HandleViewQuestionsProps {
  category: Question['category'];
  status: boolean;
}

export const QuestionsList = () => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [filteredQuestions, setFilteredQuestions] = useState<Question[]>([]);
  const [questionsFilter, setQuestionsFilter] = useState('');

  const [bodyQuestions, setBodyQuestions] = useState<Question[]>([]);
  const [lifeQuestions, setLifeQuestions] = useState<Question[]>([]);
  const [mindQuestions, setMindQuestions] = useState<Question[]>([]);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(true);
  const [isDeletingQuestion, setIsDeletingQuestion] = useState(false);
  const [isUpdatingQuestion, setIsUpdatingQuestion] = useState(false);

  const [isViewingBodyQuestions, setIsViewingBodyQuestions] = useState(true);
  const [isViewingLifeQuestions, setIsViewingLifeQuestions] = useState(false);
  const [isViewingMindQuestions, setIsViewingMindQuestions] = useState(false);

  useEffect(() => {
    const getQuestionsAsync = async () => {
      const response = await getQuestions();
      setQuestions(response);

      const bodyQuestionsArray = response.filter(
        (question) => question.category === 'Body'
      );
      const lifeQuestionsArray = response.filter(
        (question) => question.category === 'Life'
      );
      const mindQuestionsArray = response.filter(
        (question) => question.category === 'Mind'
      );

      setQuestions(bodyQuestionsArray);
      setBodyQuestions(bodyQuestionsArray);
      setLifeQuestions(lifeQuestionsArray);
      setMindQuestions(mindQuestionsArray);
      setIsLoadingQuestions(false);
    };
    getQuestionsAsync();
  }, []);

  const handleViewQuestions = useCallback(
    ({ category }: HandleViewQuestionsProps) => {
      switch (category) {
        case 'Body':
          setQuestions(bodyQuestions);
          setIsViewingBodyQuestions(true);
          setIsViewingMindQuestions(false);
          setIsViewingLifeQuestions(false);
          break;
        case 'Life':
          setQuestions(lifeQuestions);
          setIsViewingBodyQuestions(false);
          setIsViewingMindQuestions(false);
          setIsViewingLifeQuestions(true);
          break;
        default:
          setQuestions(mindQuestions);
          setIsViewingBodyQuestions(false);
          setIsViewingMindQuestions(true);
          setIsViewingLifeQuestions(false);
          break;
      }
    },
    [bodyQuestions, lifeQuestions, mindQuestions]
  );

  useEffect(() => {
    const questionsCopy = [...questions];

    const filterQuestions = questionsCopy.filter((question) =>
      question.questionnaire
        .toLowerCase()
        .includes(questionsFilter.toLowerCase())
    );

    filterQuestions.sort((questA, questB) =>
      questA.order > questB.order ? 1 : -1
    );

    setFilteredQuestions(filterQuestions);
  }, [questions, questionsFilter]);

  return (
    <Container>
      <Helmet>
        <title>Questions - List</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle heading="Questions List" />
      </PageTitleWrapper>

      <ToggleViewWrapper>
        <>
          <FormControlLabel
            sx={{ color: 'text.primary' }}
            control={
              <Switch
                checked={isViewingBodyQuestions}
                color="primary"
                onChange={() =>
                  handleViewQuestions({
                    category: 'Body',
                    status: !isViewingBodyQuestions
                  })
                }
              />
            }
            label="Body"
          />

          <FormControlLabel
            sx={{ color: 'text.primary' }}
            control={
              <Switch
                checked={isViewingLifeQuestions}
                color="info"
                onChange={() =>
                  handleViewQuestions({
                    category: 'Life',
                    status: !isViewingLifeQuestions
                  })
                }
              />
            }
            label="Life"
          />

          <FormControlLabel
            sx={{ color: 'text.primary' }}
            control={
              <Switch
                checked={isViewingMindQuestions}
                color="error"
                onChange={() =>
                  handleViewQuestions({
                    category: 'Mind',
                    status: !isViewingMindQuestions
                  })
                }
              />
            }
            label="Mind"
          />

          <input
            type="text"
            value={questionsFilter}
            onChange={(e) => setQuestionsFilter(e.target.value)}
            placeholder="Search by questionnaire"
          />
        </>
      </ToggleViewWrapper>

      {isLoadingQuestions && <Spinner message="Loading questions" />}
      {isDeletingQuestion && <Spinner message="Deleting question" />}
      {isUpdatingQuestion && <Spinner message="Updating question" />}

      <QuestionsGridWrapper>
        {filteredQuestions.map((question) => (
          <QuestionCard
            setQuestions={setQuestions}
            setIsDeletingQuestion={setIsDeletingQuestion}
            setIsUpdatingQuestion={setIsUpdatingQuestion}
            key={question.id}
            question={question}
          />
        ))}
      </QuestionsGridWrapper>
    </Container>
  );
};
