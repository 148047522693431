import { get } from 'lodash';

import {
  Organisation,
  OrganisationAverageScore,
  OrganisationCategoryScore,
  OrganisationScores
} from '../models/organisation';

export const getAverageChartData = (
  organisation: Organisation,
  divisionId: string
) => {
  const orgAverageScore = get(
    organisation,
    `scores.${divisionId}`
  ) as OrganisationScores;

  const totalLightsObject = {
    body: {
      red: 0,
      orange: 0,
      green: 0
    },
    mind: {
      red: 0,
      orange: 0,
      green: 0
    },
    life: {
      red: 0,
      orange: 0,
      green: 0
    }
  };

  if (orgAverageScore) {
    if (orgAverageScore.body) {
      const { red, orange, green } = extractTotalLights(orgAverageScore.body);
      const { body } = totalLightsObject;

      body.red = red;
      body.orange = orange;
      body.green = green;
    }

    if (orgAverageScore.mind) {
      const { red, orange, green } = extractTotalLights(orgAverageScore.mind);
      const { mind } = totalLightsObject;

      mind.red = red;
      mind.orange = orange;
      mind.green = green;
    }

    if (orgAverageScore.life) {
      const { red, orange, green } = extractTotalLights(orgAverageScore.life);
      const { life } = totalLightsObject;

      life.red = red;
      life.orange = orange;
      life.green = green;
    }

    return {
      totalLights: totalLightsObject,
      averageScore: orgAverageScore.averageScore
    };
  }

  return {
    totalLights: totalLightsObject,
    averageScore: {} as OrganisationAverageScore
  };
};

const extractTotalLights = (
  categoryScores: Record<string, OrganisationCategoryScore>
) => {
  let totalGreen = 0;
  let totalOrange = 0;
  let totalRed = 0;

  const scoreValues = Object.values(categoryScores);

  scoreValues.map(({ green, orange, red }) => {
    totalGreen += !green || Number.isNaN(green) ? 0 : green;
    totalOrange += !orange || Number.isNaN(orange) ? 0 : orange;
    totalRed += !red || Number.isNaN(red) ? 0 : red;
  });

  return {
    green: totalGreen,
    orange: totalOrange,
    red: totalRed
  };
};
