import { cloneDeep } from 'lodash';

import {
  CategoryDataProps,
  SectionProps,
  SectionQuestionnaireData
} from '../pages/Dashboard/components/CategoryStats';

import { QuestionnaireProps } from '../hooks/questions';

interface GenerateComparisonObjectProps {
  categoryData: Record<string, CategoryDataProps>;
  categoryQuestionnaires: QuestionnaireProps[];
  isLife?: boolean;
  sectionsObject: Record<string, SectionProps>;
  isComparisonData?: boolean;
  industryToCompare?: string;
}

export const generateComparisonObject = ({
  categoryData,
  categoryQuestionnaires,
  isLife = false,
  sectionsObject,
  isComparisonData = false,
  industryToCompare = ''
}: GenerateComparisonObjectProps) => {
  let questionnaires: string[] = [];

  if (!categoryData) return {};

  if (isLife) {
    const lifeEntries = Object.values(categoryData);
    questionnaires = lifeEntries.map((life) => life.question!);
  } else {
    questionnaires = Object.keys(categoryData);
  }

  const sectionsObjectClone = cloneDeep(sectionsObject);
  const validQuestionnaires = questionnaires;

  validQuestionnaires.map((questionnaire, questionnaireIndex) => {
    let camelCaseQuestionnaire = questionnaire;

    if (questionnaire === 'systolic' || questionnaire === 'diastolic') {
      camelCaseQuestionnaire = 'systolicDiastolic';
    }

    if (
      questionnaire === 'depression' ||
      questionnaire === 'anxiety' ||
      questionnaire === 'stress'
    ) {
      camelCaseQuestionnaire = 'depressionAnxietyAndStress';
    }

    let questionnaireData: QuestionnaireProps | undefined;

    if (isLife) {
      // eslint-disable-next-line prefer-destructuring
      questionnaireData = categoryQuestionnaires[0];
    } else {
      questionnaireData = categoryQuestionnaires.find(
        (data) => data.camelCaseQuestionnaire === camelCaseQuestionnaire
      );
    }

    if (!questionnaireData) return;

    let questionnaireName = '';
    let questionnaireIcon = questionnaireData.icon;

    if (questionnaire === 'systolic') {
      questionnaireName = 'Systolic';
      questionnaireIcon = 'systolic';
      camelCaseQuestionnaire = 'systolic';
    } else if (questionnaire === 'diastolic') {
      questionnaireName = 'Diastolic';
      questionnaireIcon = 'diastolic';
      camelCaseQuestionnaire = 'diastolic';
    } else if (questionnaire === 'depression') {
      questionnaireName = 'Depression';
      questionnaireIcon = 'depression';
      camelCaseQuestionnaire = 'depression';
    } else if (questionnaire === 'anxiety') {
      questionnaireName = 'Anxiety';
      questionnaireIcon = 'anxiety';
      camelCaseQuestionnaire = 'anxiety';
    } else if (questionnaire === 'stress') {
      questionnaireName = 'Stress';
      questionnaireIcon = 'stress';
      camelCaseQuestionnaire = 'stress';
    } else {
      questionnaireName = questionnaireData.questionnaire;
    }

    const { section } = questionnaireData;

    if (!sectionsObject[camelCaseQuestionnaire]) {
      sectionsObjectClone[camelCaseQuestionnaire] = {} as SectionProps;
      sectionsObjectClone[camelCaseQuestionnaire].section = section;
      sectionsObjectClone[camelCaseQuestionnaire].questionnairesData = [];
    }

    if (isLife && !categoryData[questionnaireIndex]) return;

    const { green, orange, red, calculatedAt, history, totalUsers } =
      categoryData[isLife ? questionnaireIndex : questionnaire];

    let formattedQuestionnaireName = isLife ? questionnaire : questionnaireName;

    if (isComparisonData) {
      formattedQuestionnaireName += ` (${industryToCompare})`;
    }

    const sectionQuestionnaire = {
      questionnaire: formattedQuestionnaireName,
      icon: questionnaireIcon,
      green,
      orange,
      red,
      calculatedAt,
      history,
      totalUsers
    } as SectionQuestionnaireData;

    sectionsObjectClone[camelCaseQuestionnaire].questionnairesData.push(
      sectionQuestionnaire
    );
  });

  return sectionsObjectClone;
};
