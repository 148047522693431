import { Card, CardContent, TextareaAutosize } from '@mui/material';

import { ExpandLess, ExpandMore, Delete } from '@mui/icons-material/';

import { formatDate } from '../../../../../utils/formatDate';
import profileImagePlaceHolder from '../../../../../assets/images/profile.jpg';

import {
  Container,
  ArticlePreviewBox,
  ArticlePreviewIcons,
  ArticlePreviewContent,
  TopPreviewBox,
  ImagePreviewBox,
  TitlePreviewBox,
  CoverImageInPreview,
  AuthorImageInPreview
} from './styles';

import { Article, TypeOfContent } from '../../../../../models/article';

interface ArticlePreviewProps {
  form: Article;
  articleBody: Array<TypeOfContent>;
  editArticleText: (positionInArray: number, newContent: string) => void;
  deleteItemFromPreview: (index: number) => void;
  sortPreviewItemDown: (index: number) => void;
  sortPreviewItemUp: (index: number) => void;
}

export const ArticlePreview: React.FC<ArticlePreviewProps> = ({
  form,
  articleBody,
  editArticleText,
  deleteItemFromPreview,
  sortPreviewItemDown,
  sortPreviewItemUp
}) => {
  return (
    <Container>
      <Card>
        <Card>
          <CardContent>
            <ArticlePreviewBox>
              <TopPreviewBox>
                <ImagePreviewBox>
                  <AuthorImageInPreview
                    src={form.authorImageUrl || profileImagePlaceHolder}
                    alt={form.authorName}
                    title={form.authorName}
                  />
                </ImagePreviewBox>
                <TitlePreviewBox>
                  <p>
                    {`${
                      form.authorName ? form.authorName : 'Author'
                    } - ${formatDate({
                      date: new Date() as any
                    })}`}
                  </p>

                  <h3>{form.title ? form.title : 'Title'}</h3>

                  {form?.hashtags.length > 0 && (
                    <span>#{form?.hashtags.join(' #')}</span>
                  )}
                </TitlePreviewBox>

                {form?.coverImageUrl && (
                  <CoverImageInPreview
                    src={form.coverImageUrl}
                    alt="Cover image"
                    title="Cover image"
                  />
                )}
              </TopPreviewBox>

              {articleBody &&
                articleBody.map((contents, index) => (
                  <ArticlePreviewContent key={index}>
                    {contents.type === 'title' && (
                      <TextareaAutosize
                        className="textareaTitle"
                        aria-label="Article Content"
                        value={contents.content}
                        onChange={(e) => editArticleText(index, e.target.value)}
                      />
                    )}

                    {contents.type === 'text' && (
                      <TextareaAutosize
                        aria-label="Article Content"
                        value={contents.content}
                        onChange={(e) => editArticleText(index, e.target.value)}
                      />
                    )}

                    {contents.type === 'video' && (
                      <div>
                        <h5>Cod. {contents.content}</h5>

                        <iframe
                          title="vimeo-player"
                          src={`https://player.vimeo.com/video/${contents.content.replace(
                            '/',
                            '?h='
                          )}`}
                          width="320"
                          height="180"
                          frameBorder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                    )}

                    {contents.type === 'image' && (
                      <img
                        width="25%"
                        style={{ borderRadius: '5px' }}
                        src={`${contents.content}`}
                        alt="ss"
                      />
                    )}

                    <ArticlePreviewIcons>
                      <div className="left-buttons">
                        <Delete
                          titleAccess="Delete item"
                          fontSize="small"
                          className="iconSize"
                          onClick={() => {
                            deleteItemFromPreview(index);
                          }}
                        />
                      </div>

                      <div>
                        <ExpandMore
                          titleAccess="Move down"
                          fontSize="small"
                          className="iconSize"
                          onClick={() => {
                            sortPreviewItemDown(index);
                          }}
                        />

                        <ExpandLess
                          titleAccess="Move up"
                          fontSize="small"
                          className="iconSize"
                          onClick={() => {
                            sortPreviewItemUp(index);
                          }}
                        />
                      </div>
                    </ArticlePreviewIcons>
                  </ArticlePreviewContent>
                ))}
            </ArticlePreviewBox>
          </CardContent>
        </Card>
      </Card>
    </Container>
  );
};
