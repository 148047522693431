import styled from 'styled-components';

export const Container = styled.div`
  margin: 15px;
  width: 100%;
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  gap: 10px;

  .answer-card {
    transition: 0.2s;
    border: 2px solid #bbb;

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
