import {
  Avatar,
  Box,
  Button,
  Hidden,
  lighten,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExitToApp } from '@mui/icons-material';

import { useAuth } from '../../../../hooks/auth';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        cursor: default;
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.text.secondary};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.text.secondary, 0.1)}
`
);

function HeaderUserbox() {
  const { user, signOut } = useAuth();

  return (
    <>
      {user && (
        <UserBoxButton color="secondary">
          <Avatar variant="rounded" src={user.avatarUrl} />
          <Hidden mdDown>
            <UserBoxText>
              <UserBoxLabel variant="body1">{user.firstName}</UserBoxLabel>
              {user.role && (
                <UserBoxDescription variant="body2">
                  {user.role === 'Clinician' && 'Clinician'}
                  {user.role === 'Kynd Admin' && 'Kynd Admin'}
                  {user.role === 'Org Admin' && user.organisation.name}
                </UserBoxDescription>
              )}
            </UserBoxText>
          </Hidden>
          <Button color="secondary" onClick={signOut} title="Sign out">
            <ExitToApp />
          </Button>
        </UserBoxButton>
      )}
    </>
  );
}

export default HeaderUserbox;
