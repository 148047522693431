import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Column } from 'react-table';
import { Check, Close } from '@mui/icons-material';

import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { PageTitle } from '../../../components/PageTitle';
import { Table } from '../../../components/Table';
import { Spinner } from '../../../components/Spinner';

import { getOrganisationCodes } from '../../../functions/codes';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import { OrganisationCode } from '../../../models/code';

import { Container } from './styles';

interface CodesTableRow {
  index: number;
  code: string;
  division: string;
  usage: string;
  activation: JSX.Element;
}

export const OrganisationCodes: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const [isLoadingCodes, setIsLoadingCodes] = useState(true);
  const [codes, setCodes] = useState<OrganisationCode[]>([]);

  useEffect(() => {
    const getCodesAsync = async () => {
      try {
        const response = await getOrganisationCodes(user?.organisation.id);
        if (response) setCodes(response);
        setIsLoadingCodes(false);
      } catch (error) {
        setIsLoadingCodes(false);
        addToast({
          title: 'Error fetching codes',
          description: 'Try again later',
          type: 'error'
        });
      }
    };
    getCodesAsync();
  }, [user, addToast]);

  const codesColumns: Column<CodesTableRow>[] = useMemo(
    () => [
      {
        Header: `#`,
        accessor: 'index'
      },
      {
        Header: 'Code',
        accessor: 'code'
      },
      {
        Header: 'Division',
        accessor: 'division'
      },
      {
        Header: 'Usage',
        accessor: 'usage'
      },
      {
        Header: 'Activation',
        accessor: 'activation'
      }
    ],
    []
  );

  const codesTableData = useMemo(() => {
    const codesDataArray = [] as CodesTableRow[];

    codes.map((code, index) => {
      const userRow = {
        index: index + 1,
        code: code.code,
        division: code.divisionName,
        usage: `${code.currentUsage}/${code.maximumUsage}`,
        activation: (
          <aside
            title={
              code.isActive
                ? 'Active code'
                : "Inactive code, it won't work to register new users"
            }
          >
            {code.isActive ? (
              <Check color="success" />
            ) : (
              <Close color="error" />
            )}
          </aside>
        )
      };

      return codesDataArray.push(userRow);
    });

    return codesDataArray;
  }, [codes]);

  return (
    <Container>
      {isLoadingCodes && <Spinner message="Loading codes" />}
      <Helmet>
        <title>Codes - List</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle heading="Codes List" />
      </PageTitleWrapper>

      <Table columns={codesColumns} data={codesTableData} rowHeight={60} />
    </Container>
  );
};
