import { useMemo } from 'react';

import { get } from 'lodash';
import { DoughnutChart } from '../DoughnutChart';

import { CategoryDataProps, SectionProps } from '../CategoryStats';

import { useOrganisation } from '../../../../hooks/organisations';
import { QuestionnaireProps } from '../../../../hooks/questions';

import {
  Container,
  QuestionnaireCard,
  QuestionnaireName,
  QuestionnairesWrapper,
  SectionCard,
  SectionCardHeader,
  SectionsWrapper
} from './styles';
import { generateComparisonObject } from '../../../../utils/generateComparisonObject';

export interface DataComparisonProps {
  categoryData: Record<string, CategoryDataProps>;
  categoryQuestionnaires: QuestionnaireProps[];
  isLife?: boolean;
  category: 'body' | 'mind' | 'life';
}

export const DataComparison = ({
  categoryData,
  categoryQuestionnaires,
  isLife = false,
  category
}: DataComparisonProps) => {
  const { orgIndustryComparison, industryToCompare } = useOrganisation();

  const sections: SectionProps[] = useMemo(() => {
    const comparisonCategoryObject = get(
      orgIndustryComparison,
      `scores.all.${category}`,
      {}
    );

    let sectionsObject = {} as Record<string, SectionProps>;

    sectionsObject = generateComparisonObject({
      categoryData,
      categoryQuestionnaires,
      isLife,
      sectionsObject
    });

    sectionsObject = generateComparisonObject({
      categoryData: comparisonCategoryObject,
      categoryQuestionnaires,
      isLife,
      sectionsObject,
      isComparisonData: true,
      industryToCompare
    });

    const sectionsValues = Object.values(sectionsObject);

    sectionsValues.sort((sectA, sectB) =>
      sectA.section > sectB.section ? -1 : 1
    );

    return sectionsValues;
  }, [
    category,
    categoryData,
    categoryQuestionnaires,
    industryToCompare,
    isLife,
    orgIndustryComparison
  ]);

  return (
    <Container>
      <SectionCardHeader>
        <h2>{`Comparison with ${industryToCompare}`}</h2>
      </SectionCardHeader>

      <SectionsWrapper>
        {sections.map(({ section, questionnairesData }) => (
          <SectionCard key={section}>
            <QuestionnairesWrapper>
              {questionnairesData.map((data) => (
                <QuestionnaireCard key={data.questionnaire + Math.random()}>
                  <QuestionnaireName>
                    <img
                      src={
                        /* eslint-disable */
                          require(`../../../../assets/icons/${
                            data.icon || 'life'
                          }.svg`).default
                          /* eslint-enable */
                      }
                      alt={data.icon}
                    />
                    <h3>{`${data.questionnaire} (${data.totalUsers})`}</h3>
                  </QuestionnaireName>

                  <DoughnutChart data={data} />
                </QuestionnaireCard>
              ))}
            </QuestionnairesWrapper>
          </SectionCard>
        ))}
      </SectionsWrapper>
    </Container>
  );
};
