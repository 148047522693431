import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  Box,
  MenuItem
} from '@mui/material';

import { useOrganisation } from '../../../hooks/organisations';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

import { OrganisationFilter } from '../../../components/OrganisationFilter';
import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { PageTitle } from '../../../components/PageTitle';
import { Spinner } from '../../../components/Spinner';

import { firebaseFunctions } from '../../../services/firebase';
import { addEmployee } from '../../../functions/organisations';
import { createUser, resetUserPassword } from '../../../functions/users';
import { OngoingHealthCheck, User } from '../../../models/user';

import { Hint, Wrapper } from './styles';

export const CreateUser: React.FC = () => {
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    lowerFirstName: '',
    lowerLastName: '',
    avatar: '',
    avatarUrl: '',
    email: '',
    phone: '',
    gender: '',
    sex: '',
    birthDate: '',
    organisation: {
      id: '',
      name: ''
    },
    division: {
      id: '',
      name: ''
    },
    role: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    isActive: true,
    answers: {},
    scores: {
      averageScore: {}
    },
    healthChecks: {},
    notifications: [],
    ongoingHealthCheck: {} as OngoingHealthCheck
  } as User);

  const { user } = useAuth();
  const { addToast } = useToast();
  const {
    currentOrganisation,
    chosenDivision,
    chosenDivisionId,
    chosenOrganisation
  } = useOrganisation();

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (chosenOrganisation === 'All' || chosenOrganisation === 'all') {
        return addToast({
          title: 'Please, choose an organisation',
          type: 'info'
        });
      }

      if (chosenDivision === 'All' || chosenDivision === 'all') {
        return addToast({
          title: 'Please, choose a division',
          type: 'info'
        });
      }

      const emailVerifierRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isValidEmail = emailVerifierRegex.test(form.email!.toLowerCase());

      if (!isValidEmail) {
        return addToast({
          title: 'Invalid email'
        });
      }

      setIsCreatingUser(true);

      if (!form.firstName || !form.lastName || !form.role || !form.email) {
        setIsCreatingUser(false);
        return addToast({
          title: 'Please, fill in all required fields'
        });
      }

      const verifyEmailAvailability = firebaseFunctions.httpsCallable(
        'verifyEmailAvailability'
      );

      const response = await verifyEmailAvailability(form.email);

      const emailAlreadyInUse = response.data;

      if (emailAlreadyInUse) {
        setIsCreatingUser(false);
        return addToast({
          title: `E-mail is already in use`
        });
      }

      const userData = { ...form };

      userData.lowerFirstName = form.firstName.toLowerCase();
      userData.lowerLastName = form.lastName.toLowerCase();

      const hashPassword = Math.random().toString(16).substr(2, 8);
      userData.password = hashPassword;

      userData.allowedOrganisationsIds = [] as string[];
      userData.notificationsSent = [];

      try {
        const userCreated = await createUser(userData);

        if (userCreated) {
          await addEmployee(form.organisation.id, userCreated.uid);
          resetUserPassword(form.email);
        }

        setIsCreatingUser(false);

        return addToast({
          title: 'User created',
          type: 'success'
        });
      } catch (error) {
        setIsCreatingUser(false);
        return addToast({
          title: 'Error creating user',
          description: 'Try again later',
          type: 'error'
        });
      }
    },
    [form, addToast, chosenDivision, chosenOrganisation]
  );

  useEffect(() => {
    setForm((oldState) => {
      return {
        ...oldState,
        organisation: {
          id: currentOrganisation.id,
          name: currentOrganisation.name
        },
        division: {
          id: chosenDivisionId,
          name: chosenDivision
        }
      };
    });
  }, [currentOrganisation, chosenDivision, chosenDivisionId]);

  const roles = useMemo(() => {
    if (user?.role === 'Kynd Admin') {
      return [
        {
          name: 'Kynd Admin'
        },
        {
          name: 'Org Admin'
        },
        {
          name: 'Clinician'
        }
      ];
    }
    return [{ name: 'Org Admin' }];
  }, [user?.role]);

  return (
    <Wrapper>
      {isCreatingUser && <Spinner message="Creating user" />}

      <Helmet>
        <title>Users - Create new</title>
      </Helmet>

      <OrganisationFilter hideUserSearch />

      <PageTitleWrapper>
        <PageTitle heading="Create User" />
      </PageTitleWrapper>

      <div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <Hint>
                User will be created for the organisation/division selected
                above
              </Hint>

              <CardContent>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 1, pl: 1, pr: 1 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        select
                        label="Select Role"
                        value={
                          user?.role === 'Org Admin' ? 'Org Admin' : form.role
                        }
                        required
                        onChange={(e) =>
                          user?.role === 'Kynd Admin' &&
                          setForm({ ...form, role: e.target.value })
                        }
                      >
                        {roles.map((role) => (
                          <MenuItem key={role.name} value={role.name}>
                            {role.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        value={form.email}
                        onChange={(e) =>
                          setForm({ ...form, email: e.target.value })
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="firstName"
                        required
                        fullWidth
                        id="firstName"
                        label="First Name"
                        value={form.firstName}
                        onChange={(e) =>
                          setForm({ ...form, firstName: e.target.value })
                        }
                        autoFocus
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="lastName"
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        value={form.lastName}
                        onChange={(e) =>
                          setForm({ ...form, lastName: e.target.value })
                        }
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isCreatingUser}
                    style={{ color: '#fff', marginTop: '10px' }}
                  >
                    {isCreatingUser ? 'Creating user...' : 'Create user'}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Wrapper>
  );
};
