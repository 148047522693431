import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  Box,
  MenuItem
} from '@mui/material';
import { v4 } from 'uuid';

import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { PageTitle } from '../../../components/PageTitle';
import { Spinner } from '../../../components/Spinner';

import { createOrganisation } from '../../../functions/organisations';
import { createCode, verifyIfCodeExists } from '../../../functions/codes';

import { useToast } from '../../../hooks/toast';
import { useOrganisation } from '../../../hooks/organisations';

import { Organisation } from '../../../models/organisation';
import { OrganisationCode } from '../../../models/code';

import { Wrapper } from './styles';

export const CreateOrganisation: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const {
    allIndustries,
    allOrganisations,
    setAllOrganisations,
    currentOrganisations,
    setCurrentOrganisations,
    chosenIndustry
  } = useOrganisation();

  const [industries, setIndustries] = useState(['']);
  const [isCreatingOrganisation, setIsCreatingOrganisation] = useState(false);
  const [form, setForm] = useState({
    name: '',
    code: '',
    address: '',
    phone: '',
    industry: industries[0],
    codeUsage: 20,
    divisionName: ''
  });

  useEffect(() => {
    const sortedIndustries = allIndustries.sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
    const industriesNames = sortedIndustries.map(({ name }) => name);

    setIndustries(industriesNames);
  }, [allIndustries]);

  const handleSubmit = useCallback(
    // eslint-disable-next-line consistent-return
    async (event: FormEvent) => {
      event.preventDefault();

      setIsCreatingOrganisation(true);

      if (
        !form.address ||
        !form.code ||
        !form.codeUsage ||
        !form.name ||
        !form.industry ||
        !form.divisionName
      ) {
        setIsCreatingOrganisation(false);
        return addToast({
          title: 'Please, fill in all required fields'
        });
      }

      const codeExists = await verifyIfCodeExists(form.code);

      if (codeExists) {
        setIsCreatingOrganisation(false);
        return addToast({
          title: 'Access code is already in use'
        });
      }

      const organisation = {} as Organisation;

      try {
        organisation.name = form.name;
        organisation.industry = form.industry;
        organisation.scores = {};
        organisation.isActive = true;
        organisation.managers = [];
        organisation.employees = [];
        organisation.phone = form.phone;
        organisation.address = form.address;
        organisation.notifications = [];
        organisation.divisions = [
          {
            id: v4(),
            codes: [],
            name: form.divisionName,
            description: ''
          }
        ];

        const createdOrganisation = await createOrganisation(organisation);

        organisation.id = createdOrganisation.id;

        const organisationCode: OrganisationCode = {
          code: form.code,
          divisionId: organisation.divisions[0].id,
          divisionName: organisation.divisions[0].name,
          organisationId: createdOrganisation.id,
          organisationName: organisation.name,
          isActive: true,
          currentUsage: 0,
          maximumUsage: form.codeUsage || 0
        };

        await createCode(organisationCode);

        setIsCreatingOrganisation(false);

        const organisationsCopy = [...allOrganisations];
        organisationsCopy.push(organisation);
        setAllOrganisations(organisationsCopy);

        if (organisation.industry === chosenIndustry) {
          const copyOrganisations = [...currentOrganisations, organisation];
          setCurrentOrganisations(copyOrganisations);
        }

        navigate('/organisations');

        return addToast({
          title: 'Organisation created',
          type: 'success'
        });
      } catch (error) {
        setIsCreatingOrganisation(false);
        console.log(error);
        return addToast({
          title: 'Error creating organisation',
          description: 'Try again later',
          type: 'error'
        });
      }
    },
    [
      form,
      addToast,
      allOrganisations,
      setAllOrganisations,
      chosenIndustry,
      navigate,
      currentOrganisations,
      setCurrentOrganisations
    ]
  );

  return (
    <Wrapper>
      {isCreatingOrganisation && <Spinner message="Creating organisation" />}
      <Helmet>
        <title>Organisations - Create new</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle heading="Create Organisation" />
      </PageTitleWrapper>

      <div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <Card>
                <CardContent>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 1, pl: 1, pr: 1 }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="name"
                          required
                          fullWidth
                          id="name"
                          label="Organisation name"
                          value={form.name}
                          onChange={(e) =>
                            setForm({ ...form, name: e.target.value })
                          }
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="address"
                          required
                          fullWidth
                          id="address"
                          label="Organisation address"
                          value={form.address}
                          onChange={(e) =>
                            setForm({ ...form, address: e.target.value })
                          }
                          autoFocus
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="phone"
                          required
                          fullWidth
                          id="phone"
                          label="Organisation phone"
                          value={form.phone}
                          onChange={(e) =>
                            setForm({ ...form, phone: e.target.value })
                          }
                          autoFocus
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          select
                          label="Industry"
                          value={form.industry}
                          required
                          onChange={(e) =>
                            setForm({
                              ...form,
                              industry: e.target.value
                            })
                          }
                        >
                          {industries.map((industry) => (
                            <MenuItem key={industry} value={industry}>
                              {industry}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12} sm={6} mt={0}>
                        <TextField
                          fullWidth
                          required
                          id="division"
                          label="First division name"
                          name="division"
                          value={form.divisionName}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              divisionName: e.target.value
                            })
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} mt={0}>
                        <TextField
                          fullWidth
                          required
                          id="code"
                          label="Division access code"
                          name="code"
                          value={form.code.toUpperCase()}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              code: e.target.value.toUpperCase()
                            })
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} mt={0}>
                        <TextField
                          fullWidth
                          required
                          id="codeUsage"
                          label="Code Usage Limit"
                          name="codeUsage"
                          type="number"
                          value={form.codeUsage}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              codeUsage: Number(e.target.value)
                            })
                          }
                        />
                      </Grid>
                    </Grid>

                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isCreatingOrganisation}
                      style={{ color: '#fff', marginTop: '10px' }}
                    >
                      {isCreatingOrganisation
                        ? 'Creating organisation...'
                        : 'Create organisation'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Wrapper>
  );
};
