import styled, { css } from 'styled-components';
import { PureLightTheme } from '../../../../styles/schemes/PureLightTheme';

interface ContainerProps {
  questionCategory: string;
}

export const Container = styled.div<ContainerProps>`
  border-radius: 10px;

  padding: 10px;
  transition: 0.2s;

  box-shadow: 2px 2px 10px;

  strong {
    border-radius: 5px;
    padding: 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;

    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
      0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
      0 16px 16px rgba(0, 0, 0, 0.12);

    div {
      display: flex;
    }

    button {
      margin: 0;
      cursor: pointer;
      border-radius: 5px;
      border: 0;
      background-color: transparent;

      &:hover {
        background-color: #eeeeeecc;
      }
    }

    svg {
      transition: color 0.2s;

      &:hover {
        color: #333;
      }
    }
  }

  &:hover {
    cursor: default;
    transform: scale(1.03);
  }

  ${(props) =>
    props.questionCategory === 'Body' &&
    css`
      border-color: ${PureLightTheme.colors.primary.main};

      strong {
        background-color: ${PureLightTheme.colors.primary.light};
      }

      &:hover {
        background: linear-gradient(
          ${PureLightTheme.colors.primary.light},
          ${PureLightTheme.colors.primary.main}
        );
      }
    `}

  ${(props) =>
    props.questionCategory === 'Life' &&
    css`
      border-color: ${PureLightTheme.colors.info.main};

      strong {
        background-color: ${PureLightTheme.colors.info.light};
      }

      &:hover {
        background: linear-gradient(
          ${PureLightTheme.colors.info.light},
          ${PureLightTheme.colors.info.main}
        );
      }
    `}

  ${(props) =>
    props.questionCategory === 'Mind' &&
    css`
      border-color: ${PureLightTheme.colors.error.light};

      strong {
        background-color: ${PureLightTheme.colors.error.light};
      }

      &:hover {
        background: linear-gradient(
          ${PureLightTheme.colors.error.lighter},
          ${PureLightTheme.colors.error.light}
        );
      }
    `}
`;

export const HintBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  background-color: #eeeeeecc;
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;

  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);

  h5 {
    margin: 0;
  }

  input {
    border: 0.5px solid #bbb;
    border-radius: 5px;
  }

  p {
    margin: 0;
    font-size: 10px;
  }
`;

export const HintBottom = styled.div`
  display: flex;
  gap: 5px;
`;
