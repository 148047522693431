export function makeHashPassword(hashLength = 8) {
  const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
  const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';

  let result = '';
  result += lowerCase.charAt(Math.floor(Math.random() * lowerCase.length));
  result += upperCase.charAt(Math.floor(Math.random() * upperCase.length));
  result += numbers.charAt(Math.floor(Math.random() * numbers.length));

  const characters = lowerCase + upperCase + numbers;

  while (result.length < hashLength) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}
