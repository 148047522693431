import { useCallback, useEffect, useMemo, useState } from 'react';
import lodash, { cloneDeep } from 'lodash';

import { Timestamp } from '../../../../models/user';
import { QuestionnaireProps } from '../../../../hooks/questions';

import { DoughnutChart } from '../DoughnutChart';
import { TableView } from '../TableView';
import { QuestionnaireModal } from '../QuestionnaireModal';

import { turnIntoCamelCase } from '../../../../utils/turnIntoCamelCase';

import {
  Container,
  QuestionnaireCard,
  QuestionnaireName,
  QuestionnairesWrapper,
  SectionCard,
  SectionCardHeader,
  SectionsWrapper,
  ToggleViewButton
} from './styles';

export interface CategoryDataProps {
  calculatedAt: Timestamp | string;
  green: number;
  orange: number;
  red: number;
  totalUsers: number;
  question?: string;
  history: Array<{
    calculatedAt: Timestamp | string;
    green: number;
    orange: number;
    red: number;
    totalUsers: number;
  }>;
}

export interface SectionQuestionnaireData extends CategoryDataProps {
  questionnaire: string;
  icon: string;
  questionnaireIndex?: number;
}

export interface SectionProps {
  section: string;
  questionnairesData: SectionQuestionnaireData[];
}

interface CategoryStatsProps {
  categoryQuestionnaires: QuestionnaireProps[];
  categoryData: Record<string, CategoryDataProps>;
  isLife?: boolean;
}

export const CategoryStats: React.FC<CategoryStatsProps> = ({
  categoryQuestionnaires,
  categoryData,
  isLife = false
}) => {
  const [tableView, setTableView] = useState(false);
  const [sections, setSections] = useState<SectionProps[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({} as SectionQuestionnaireData);

  useEffect(() => {
    let questionnaires: string[] = [];

    if (!categoryData) return;

    if (isLife) {
      const lifeEntries = Object.values(categoryData);
      questionnaires = lifeEntries.map((life) => life.question!);
    } else {
      questionnaires = Object.keys(categoryData);
    }

    const validQuestionnaires = questionnaires;
    const sectionsObject = {} as Record<string, SectionProps>;

    validQuestionnaires.map((questionnaire, questionnaireIndex) => {
      let camelCaseQuestionnaire = questionnaire;

      if (questionnaire === 'systolic' || questionnaire === 'diastolic') {
        camelCaseQuestionnaire = 'systolicDiastolic';
      }

      if (
        questionnaire === 'depression' ||
        questionnaire === 'anxiety' ||
        questionnaire === 'stress'
      ) {
        camelCaseQuestionnaire = 'depressionAnxietyAndStress';
      }

      let questionnaireData: QuestionnaireProps | undefined;

      if (isLife) {
        // eslint-disable-next-line prefer-destructuring
        questionnaireData = categoryQuestionnaires[0];
      } else {
        questionnaireData = categoryQuestionnaires.find(
          (data) => data.camelCaseQuestionnaire === camelCaseQuestionnaire
        );
      }

      if (!questionnaireData) return;

      let questionnaireName = '';
      let questionnaireIcon = questionnaireData.icon;

      if (questionnaire === 'systolic') {
        questionnaireName = 'Systolic';
      } else if (questionnaire === 'diastolic') {
        questionnaireName = 'Diastolic';
      } else if (questionnaire === 'depression') {
        questionnaireName = 'Depression';
        questionnaireIcon = 'depression';
      } else if (questionnaire === 'anxiety') {
        questionnaireName = 'Anxiety';
        questionnaireIcon = 'anxiety';
      } else if (questionnaire === 'stress') {
        questionnaireName = 'Stress';
        questionnaireIcon = 'stress';
      } else {
        questionnaireName = questionnaireData.questionnaire;
      }

      const { section } = questionnaireData;
      const camelCaseSection = turnIntoCamelCase(section);

      if (!sectionsObject[camelCaseSection]) {
        sectionsObject[camelCaseSection] = {} as SectionProps;
        sectionsObject[camelCaseSection].section = section;
        sectionsObject[camelCaseSection].questionnairesData = [];
      }

      if (isLife && !categoryData[questionnaireIndex]) return;

      const { green, orange, red, calculatedAt, history, totalUsers } =
        categoryData[isLife ? questionnaireIndex : questionnaire];

      const sectionQuestionnaire = {
        questionnaire: isLife ? questionnaire : questionnaireName,
        icon: questionnaireIcon,
        green,
        orange,
        red,
        calculatedAt,
        history,
        totalUsers
      } as SectionQuestionnaireData;

      sectionsObject[camelCaseSection].questionnairesData.push(
        sectionQuestionnaire
      );
    });

    const sectionsValues = Object.values(sectionsObject);

    sectionsValues.sort((sectA, sectB) =>
      sectA.section > sectB.section ? 1 : -1
    );

    sectionsValues.forEach(({ questionnairesData }) => {
      questionnairesData.forEach((data, index) => {
        // eslint-disable-next-line no-param-reassign
        data.questionnaireIndex = index;
      });

      questionnairesData.sort((a, b) =>
        a.questionnaire > b.questionnaire ? 1 : -1
      );
    });

    setSections(sectionsValues);
  }, [categoryQuestionnaires, categoryData, isLife]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleOpenModal = useCallback(
    (
      questionnaireData: SectionQuestionnaireData,
      questionnaireIndex: number
    ) => {
      const fullModalData = {
        ...questionnaireData,
        questionnaireIndex
      };

      setModalData(fullModalData);
      setIsModalOpen(true);
    },
    []
  );

  const totalUsers = useMemo(() => {
    const questionnaireTotalUsers = lodash.get(
      sections,
      `[0].questionnairesData[0].totalUsers`
    );

    return questionnaireTotalUsers || '';
  }, [sections]);

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ToggleViewButton onClick={() => setTableView(!tableView)}>
          {tableView ? 'Chart view' : 'Table view'}
        </ToggleViewButton>
      </div>

      {isModalOpen && (
        <QuestionnaireModal
          modalData={modalData}
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          isLife={isLife}
        />
      )}

      {!tableView ? (
        <SectionsWrapper>
          {sections.map(({ section, questionnairesData }) => (
            <SectionCard key={section}>
              <SectionCardHeader>
                <h2>
                  {section} {isLife && `(${totalUsers})`}
                </h2>
              </SectionCardHeader>

              <QuestionnairesWrapper isLife={isLife}>
                {questionnairesData.map((data, questionnaireIndex) => {
                  const dataCopy = cloneDeep(data);

                  return (
                    <QuestionnaireCard
                      key={dataCopy.questionnaire}
                      onClick={() =>
                        handleOpenModal(
                          dataCopy,
                          dataCopy.questionnaireIndex || questionnaireIndex
                        )
                      }
                      title="Click to see more info"
                    >
                      <QuestionnaireName>
                        <img
                          src={
                            /* eslint-disable */
                            require(`../../../../assets/icons/${
                              dataCopy.icon || 'life'
                            }.svg`).default
                              /* eslint-enable */
                          }
                          alt={dataCopy.icon}
                        />

                        <h3>
                          {isLife
                            ? `${dataCopy.questionnaire}`
                            : `${dataCopy.questionnaire} (${dataCopy.totalUsers})`}
                        </h3>
                      </QuestionnaireName>

                      <DoughnutChart data={dataCopy} />
                    </QuestionnaireCard>
                  );
                })}
              </QuestionnairesWrapper>
            </SectionCard>
          ))}
        </SectionsWrapper>
      ) : (
        <div style={{ margin: '24px 0px' }}>
          <TableView
            sections={sections}
            isLife={isLife}
            onRowClick={handleOpenModal}
          />
        </div>
      )}
    </Container>
  );
};
