import {
  FormEvent,
  useCallback,
  useState,
  ChangeEvent,
  useEffect
} from 'react';
import lodash, { cloneDeep, findIndex, remove } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/react';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import {
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  ListItemText,
  OutlinedInput,
  Checkbox,
  Card,
  CardContent,
  Box,
  MenuItem,
  FormControlLabel,
  Switch,
  IconButton,
  Modal,
  Slider
} from '@mui/material';

import {
  Delete,
  AddAPhoto,
  Copyright as AddCover,
  PhotoCamera,
  Crop,
  CropOriginal
} from '@mui/icons-material/';

import { ArticlePreview } from './components/ArticlePreview';

import { useToast } from '../../../hooks/toast';
import { useQuestions } from '../../../hooks/questions';
import { useArticles } from '../../../hooks/articles';

import { resizeFile } from '../../../utils/handleFileSizes';

import getCroppedImg from './components/EasyCrop/cropImage';

import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { PageTitle } from '../../../components/PageTitle';
import { Spinner } from '../../../components/Spinner';

import { storage, isProduction } from '../../../services/firebase';
import {
  Article,
  TypeOfContent,
  ArticleImages,
  Questionnaire
} from '../../../models/article';

import {
  createArticle,
  updateArticle,
  getArticle,
  deleteArticles
} from '../../../functions/articles';

import {
  Wrapper,
  ImageBox,
  Figure,
  FieldDescription,
  BackButton,
  ImageArticle,
  BoxCropPicture
} from './styles';

export const CreateArticle = () => {
  const { articles, setArticles } = useArticles();

  const { addToast } = useToast();
  const { questions } = useQuestions();
  const params = useParams();
  const navigate = useNavigate();

  const [articleBody, setArticleBody] = useState([] as Array<TypeOfContent>);
  const [isSavingArticle, setIsSavingArticle] = useState(false);
  const [isSavingImages, setIsSavingImages] = useState(false);
  const [isLoadingArticle, setIsLoadingArticle] = useState(false);

  const categories = ['Mind', 'Life', 'Body'];

  const [form, setForm] = useState({
    title: '',
    categories: [],
    questionnaires: [],
    hashtags: [],
    body: [],
    coverImageUrl: '',
    bodyImagesUrls: [],
    authorName: '',
    authorImage: '',
    authorImageUrl: '',
    isActive: true,
    publishedAt: new Date(),
    createdAt: new Date()
  } as Article);

  const [hashtag, setHashtag] = useState('');
  const [articleBodyTitle, setArticleBodyTitle] = useState<TypeOfContent>({
    type: '',
    content: ''
  });

  const [articleBodyText, setArticleBodyText] = useState<TypeOfContent>({
    type: '',
    content: ''
  });

  const [articleBodyVideoID, setArticleBodyVideoID] = useState<TypeOfContent>({
    type: '',
    content: ''
  });

  const [uploadedImagesUrls, setUploadedImagesUrls] = useState(
    [] as Array<ArticleImages>
  );

  const [questionnaires, setQuestionnaires] = useState(
    [] as Array<Questionnaire>
  );
  const [questionnairesFilter, setQuestionnairesFilter] = useState(
    [] as Array<Questionnaire>
  );

  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [uploadedImage, setUploadedImage] = useState<File>();

  const [open, setOpen] = useState(false);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  useEffect(() => {
    const loadQuestions = async () => {
      const questionnaireTemp = [] as Array<Questionnaire>;
      questions.map((question) => {
        if (
          questionnaireTemp.find(
            (element) => element.questionnaire === question.questionnaire
          ) === undefined
        ) {
          questionnaireTemp.push({
            category: question.category,
            questionnaire: question.questionnaire
          });
        }
      });
      const orderedQuestionnaires = lodash.orderBy(
        questionnaireTemp,
        ['category', 'questionnaire'],
        ['asc', 'asc']
      );

      setQuestionnaires(orderedQuestionnaires);
    };

    loadQuestions();
  }, [questions]);

  useEffect(() => {
    const getArticleForEditing = async (articleId: string) => {
      const temporaryArticle = await getArticle(articleId);

      if (temporaryArticle) {
        setForm(temporaryArticle);
        setHashtag(temporaryArticle.hashtags.slice(0, 5).join(' '));
        setArticleBody(temporaryArticle.body);
        setUploadedImagesUrls(temporaryArticle.bodyImagesUrls);
        setIsLoadingArticle(false);
      }
    };

    if (params.id) {
      setIsLoadingArticle(true);
      getArticleForEditing(params.id);
    }
  }, [params]);

  useEffect(() => {
    const filteredQuestionnaires = questionnaires.filter((questionnaire) => {
      return form.categories.includes(questionnaire.category);
    });
    setQuestionnairesFilter(filteredQuestionnaires);
  }, [form.categories, questionnaires]);

  const splitHashtagStringIntoAnArray = (allHashtags: string) => {
    setHashtag(allHashtags);

    setForm({
      ...form,
      hashtags: allHashtags.split(' ').filter((hash) => hash.length >= 2)
    });
  };

  const handleImageUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) return;

      const file = event.target.files[0];
      const fileType = file.type.split('/')[0] || '';

      if (fileType === 'image') {
        setUploadedImageUrl(URL.createObjectURL(file));
        setUploadedImage(file);

        handleModalOpening();
      }
    },
    []
  );

  const saveArticleImage = useCallback(
    async (file) => {
      setIsSavingImages(true);
      const fileName = `article-${new Date().getTime()}`;
      const resizedFile = await resizeFile(file!);
      const upload = storage.ref(`images/article/${fileName}`).put(resizedFile);

      upload.on('state_changed', async (snapshot) => {
        const imagemUrl = await snapshot.ref.getDownloadURL();

        setUploadedImagesUrls([
          ...uploadedImagesUrls,
          {
            imageName: fileName,
            imageURL: imagemUrl
          }
        ]);
        setIsSavingImages(false);
      });
      setOpen(false);
    },
    [uploadedImagesUrls]
  );

  const cropImage = useCallback(async () => {
    try {
      const croppedImageTemp: any = await getCroppedImg(
        uploadedImageUrl,
        croppedAreaPixels
      );

      saveArticleImage(croppedImageTemp);
    } catch (e) {
      console.error(e);
    }
  }, [uploadedImageUrl, croppedAreaPixels, saveArticleImage]);

  const onCropComplete = useCallback((croppedArea: Area, areaPixels: Area) => {
    setCroppedAreaPixels(areaPixels);
  }, []);

  const handleModalOpening = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const saveAuthorImage = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) return;

      setIsSavingImages(true);

      const file = event.target.files[0];
      const fileType = file.type.split('/')[0] || '';

      if (fileType === 'image') {
        setForm({ ...form, authorImageUrl: URL.createObjectURL(file) });

        const fileName = `${new Date().getTime()}`;
        const resizedFile = await resizeFile(file!);

        const upload = storage
          .ref(`images/author/${fileName}`)
          .put(resizedFile);

        upload.on(
          'state_changed',
          async (snapshot) => {
            const authorImageUrl = await snapshot.ref.getDownloadURL();

            setForm({ ...form, authorImageUrl, authorImage: fileName });

            setIsSavingImages(false);
          },
          (error) => {
            console.log(error);

            addToast({
              title: 'Error updating author image',
              description: 'Please, try again later',
              type: 'error'
            });

            if (isProduction) {
              Sentry.captureException(error);
            }
          }
        );

        if (form.authorImage) {
          try {
            await storage.ref(`images/author/${form.authorImage}`).delete();
          } catch (error) {
            // Prevents errors when feature was introduced, when the storage doesnt
            // have the author images loaded yet for articles created before this feature
            console.log(error);
          }
        }
      }
    },
    [addToast, form]
  );

  const deleteImageFromArticle = useCallback(
    async (image: ArticleImages) => {
      const confirmDeleteImage = window.confirm('Delete image?');

      if (!confirmDeleteImage) return;

      try {
        setIsSavingImages(true);
        await storage.ref(`images/article/${image.imageName}`).delete();

        if (image.imageURL === form.coverImageUrl) {
          setForm({ ...form, coverImageUrl: '' });
        }

        lodash.remove(articleBody, (item) => {
          return item.content === image.imageURL;
        });
        setArticleBody([...articleBody]);

        lodash.remove(uploadedImagesUrls, (item) => {
          return item.imageURL === image.imageURL;
        });
        setUploadedImagesUrls([...uploadedImagesUrls] as Array<ArticleImages>);
        setIsSavingImages(false);
      } catch (error) {
        if (isProduction) {
          Sentry.captureException(error);
        }

        addToast({
          title: 'Error deleting picture',
          type: 'error'
        });

        setIsSavingImages(false);
      }
    },
    [form, articleBody, uploadedImagesUrls, addToast]
  );

  const addSubtitleInPreview = () => {
    if (articleBodyTitle.content !== '')
      setArticleBody([...articleBody, articleBodyTitle]);
    setArticleBodyTitle({ content: '', type: '' });
  };

  const addTextInPreview = () => {
    if (articleBodyText.content !== '')
      setArticleBody([...articleBody, articleBodyText]);
    setArticleBodyText({ content: '', type: '' });
  };

  const addVideoInPreview = () => {
    if (articleBodyVideoID.content !== '')
      setArticleBody([...articleBody, articleBodyVideoID]);
  };

  const addImageInPreview = (imageUrl: string) => {
    setArticleBody([
      ...articleBody,
      {
        type: 'image',
        content: imageUrl
      }
    ]);
  };

  const sortPreviewItemUp = (index: number) => {
    if (index > 0) {
      const temp = articleBody[index - 1];
      articleBody[index - 1] = articleBody[index];
      articleBody[index] = temp;
      setArticleBody([...articleBody] as Array<TypeOfContent>);
    }
  };

  const sortPreviewItemDown = (index: number) => {
    if (index < articleBody.length - 1) {
      const temp = articleBody[index + 1];
      articleBody[index + 1] = articleBody[index];
      articleBody[index] = temp;
      setArticleBody([...articleBody] as Array<TypeOfContent>);
    }
  };

  const deleteItemFromPreview = (index: number) => {
    const confirmDeleteItem = window.confirm('Delete item?');

    if (!confirmDeleteItem) return;

    if (index >= 0 && index < articleBody.length) {
      articleBody.splice(index, 1);
      setArticleBody([...articleBody] as Array<TypeOfContent>);
    }
  };

  const editArticleText = (positionInArray: number, newContent: string) => {
    const articleBodyCopy = cloneDeep(articleBody);
    articleBodyCopy[positionInArray].content = newContent;
    setArticleBody(articleBodyCopy);
  };

  const searchImageInPreview = (imageURL: string) => {
    const index = findIndex(articleBody, (item) => {
      return item.content === imageURL;
    });

    return index >= 0;
  };

  const handleRemoveArticle = useCallback(async () => {
    const alertResponse = window.confirm(
      'Are you sure you want to delete this article?'
    );

    if (!alertResponse) return;
    if (form?.id)
      try {
        form.bodyImagesUrls.map(async (image) => {
          await deleteImageFromArticle(image);
        });

        await deleteArticles(form?.id);

        navigate('/articles/all');
      } catch (error) {
        addToast({
          title: 'Error trying to delete question',
          type: 'error',
          description: 'Please, try again later'
        });
      }
  }, [addToast, form, navigate, deleteImageFromArticle]);

  const handleSubmit = useCallback(
    // eslint-disable-next-line consistent-return
    async (event: FormEvent) => {
      event.preventDefault();

      setIsSavingArticle(true);

      if (!form.title || form.hashtags.length <= 0) {
        setIsSavingArticle(false);
        return addToast({
          title: 'Please, fill in all required fields'
        });
      }

      if (articleBodyTitle.content !== '') {
        setIsSavingArticle(false);
        return addToast({
          title: 'Please add typed subtitle to your article'
        });
      }

      if (articleBodyText.content !== '') {
        setIsSavingArticle(false);
        return addToast({
          title: 'Please add typed text to your article'
        });
      }

      const article = {} as Article;

      try {
        article.title = form.title;
        article.categories = form.categories;
        article.questionnaires = form.questionnaires;
        article.hashtags = form.hashtags;
        article.body = articleBody;
        article.coverImageUrl = form.coverImageUrl;
        article.bodyImagesUrls = uploadedImagesUrls;
        article.authorName = form.authorName;
        article.authorImage = form.authorImage;
        article.authorImageUrl = form.authorImageUrl;
        article.isActive = form.isActive;
        article.publishedAt = form.publishedAt;
        article.createdAt = form.createdAt;

        if (params.id) {
          article.id = params.id;
          await updateArticle(article);
        } else {
          const createdArticle = await createArticle(article);
          article.id = createdArticle.id;
        }

        const articlesCopy = cloneDeep(articles);
        remove(articlesCopy, (item) => {
          return item.id === article.id;
        });
        articlesCopy.unshift(article);
        setArticles(articlesCopy);

        navigate('/articles/all');

        return addToast({
          title: params?.id ? 'Article edited' : 'Article created',
          type: 'success'
        });
      } catch (error) {
        setIsSavingArticle(false);
        console.log(error);
        return addToast({
          title: 'Error creating article',
          description: 'Try again later',
          type: 'error'
        });
      }
    },
    [
      form,
      articles,
      setArticles,
      addToast,
      navigate,
      articleBody,
      params.id,
      uploadedImagesUrls,
      articleBodyTitle.content,
      articleBodyText.content
    ]
  );

  const handleGoBack = () => {
    const confirm = window.confirm('Are you sure you want to leave this page?');

    if (!confirm) return;

    navigate('/articles/all');
  };

  return (
    <Wrapper>
      {isSavingArticle && (
        <Spinner
          message={params.id ? 'Updating article' : 'Creating article'}
        />
      )}
      {isSavingImages && <Spinner message="Uploading image" />}
      {isLoadingArticle && <Spinner message="Loading article" />}
      <Helmet>
        <title>Manage Articles</title>
      </Helmet>
      <BackButton type="button" onClick={handleGoBack}>
        Back to articles list
      </BackButton>
      <PageTitleWrapper>
        <PageTitle heading={params.id ? 'Update Article' : 'Create Article'} />
      </PageTitleWrapper>

      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="Crop image"
        aria-describedby="Crop image"
      >
        <BoxCropPicture>
          <div className="crop-container">
            <Cropper
              image={uploadedImageUrl}
              crop={crop}
              zoom={zoom}
              aspect={16 / 9}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>

          <div className="controls">
            <h4>Zoom</h4>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zooms) => setZoom(Number(zooms))}
              classes={{ root: 'slider' }}
            />
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              style={{ color: '#fff' }}
              onClick={cropImage}
              startIcon={<Crop />}
            >
              Crop image
            </Button>

            <Button
              fullWidth
              variant="contained"
              color="secondary"
              style={{ color: '#fff' }}
              onClick={() => saveArticleImage(uploadedImage)}
              startIcon={<CropOriginal />}
            >
              Use full image
            </Button>
          </div>
        </BoxCropPicture>
      </Modal>

      <div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={6}>
            <Card>
              <Card>
                <CardContent>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 1, pl: 1, pr: 1 }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          name="tile"
                          required
                          fullWidth
                          id="title"
                          label="Article Title"
                          value={form.title}
                          onChange={(e) =>
                            setForm({ ...form, title: e.target.value })
                          }
                          autoFocus
                        />
                      </Grid>

                      <Grid item xs={12} sm={10}>
                        <TextField
                          name="authorName"
                          required
                          fullWidth
                          id="authorName"
                          label="Author name"
                          value={form.authorName}
                          onChange={(e) =>
                            setForm({ ...form, authorName: e.target.value })
                          }
                          autoFocus
                        />
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <label
                          htmlFor="icon_button_file"
                          title="Click to upload author avatar image"
                        >
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            size="large"
                          >
                            <PhotoCamera />
                          </IconButton>
                          <input
                            className="authorPhotoEntry"
                            accept=".png,.jpg,.jpeg"
                            id="icon_button_file"
                            onChange={saveAuthorImage}
                            type="file"
                          />
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel>Category *</InputLabel>
                          <Select
                            label="Category"
                            placeholder="Category"
                            fullWidth
                            required
                            multiple
                            value={form.categories}
                            onChange={(e) => {
                              const {
                                target: { value }
                              } = e;
                              setForm({
                                ...form,
                                categories:
                                  typeof value === 'string'
                                    ? value.split(',')
                                    : value
                              });
                            }}
                            input={<OutlinedInput label="Category *" />}
                            renderValue={(selected) => selected.join(', ')}
                          >
                            {categories.map((category) => (
                              <MenuItem key={category} value={category}>
                                <Checkbox
                                  checked={
                                    form.categories.indexOf(category) > -1
                                  }
                                />
                                <ListItemText primary={category} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel>Questionnaires *</InputLabel>
                          <Select
                            label="Questionnaires"
                            placeholder="Questionnaires"
                            fullWidth
                            required
                            multiple
                            value={form.questionnaires}
                            onChange={(e) => {
                              const {
                                target: { value }
                              } = e;
                              setForm({
                                ...form,
                                questionnaires:
                                  typeof value === 'string'
                                    ? value.split(',')
                                    : value
                              });
                            }}
                            input={<OutlinedInput label="Questionnaire *" />}
                            renderValue={(selected) => selected.join(', ')}
                          >
                            {questionnairesFilter.map(
                              (filteredQuestionnaire, index) => (
                                <MenuItem
                                  key={index}
                                  value={filteredQuestionnaire.questionnaire}
                                >
                                  <Checkbox
                                    checked={
                                      form.questionnaires.indexOf(
                                        filteredQuestionnaire.questionnaire
                                      ) > -1
                                    }
                                  />
                                  <ListItemText
                                    primary={
                                      filteredQuestionnaire.category +
                                      ' - ' +
                                      filteredQuestionnaire.questionnaire
                                    }
                                  />
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField
                          required
                          name="hashtag"
                          fullWidth
                          id="hashtag"
                          label="Hashtags"
                          value={hashtag}
                          onChange={(e) =>
                            splitHashtagStringIntoAnArray(e.target.value)
                          }
                        />
                        <FieldDescription>
                          Use spaces to separate hashtags
                        </FieldDescription>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <h3>Build your article</h3>
                        <h6>
                          Use the fields below to add titles, images, texts and
                          videos.
                        </h6>
                      </Grid>

                      <Grid item xs={12} sm={10}>
                        <TextField
                          fullWidth
                          id="articleBodyTitle"
                          label="Subtitle"
                          value={articleBodyTitle.content}
                          onChange={(e) =>
                            setArticleBodyTitle({
                              type: 'title',
                              content: e.target.value
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          style={{ color: '#fff' }}
                          onClick={addSubtitleInPreview}
                        >
                          Add
                        </Button>
                      </Grid>

                      <Grid item xs={12} sm={10}>
                        <TextField
                          fullWidth
                          id="text"
                          label="Text"
                          value={articleBodyText.content}
                          multiline
                          minRows={4}
                          onChange={(e) =>
                            setArticleBodyText({
                              type: 'text',
                              content: e.target.value
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          style={{ color: '#fff' }}
                          onClick={addTextInPreview}
                        >
                          Add
                        </Button>
                      </Grid>

                      <Grid item xs={12} sm={10}>
                        <TextField
                          fullWidth
                          id="video"
                          label="Vimeo video ID"
                          onChange={(e) =>
                            setArticleBodyVideoID({
                              type: 'video',
                              content: e.target.value
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          style={{ color: '#fff' }}
                          onClick={addVideoInPreview}
                        >
                          Add
                        </Button>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        {uploadedImagesUrls.length > 0 && (
                          <ImageBox>
                            {uploadedImagesUrls.map(
                              (image: ArticleImages, index) => {
                                return (
                                  <Figure key={index}>
                                    <ImageArticle
                                      isIncluded={searchImageInPreview(
                                        image.imageURL
                                      )}
                                      src={image.imageURL}
                                      alt="Article image"
                                    />
                                    <div>
                                      <Delete
                                        titleAccess="Delete image"
                                        onClick={() =>
                                          deleteImageFromArticle(image)
                                        }
                                        color="secondary"
                                        className="buttonSizeImageControl"
                                      />
                                      <AddCover
                                        titleAccess="Set image as cover"
                                        onClick={() =>
                                          setForm({
                                            ...form,
                                            coverImageUrl: image.imageURL
                                          })
                                        }
                                        color="secondary"
                                        className="buttonSizeImageControl"
                                      />
                                      <AddAPhoto
                                        titleAccess="Add image to article preview"
                                        onClick={() =>
                                          addImageInPreview(image.imageURL)
                                        }
                                        color="secondary"
                                        className="buttonSizeImageControl"
                                      />
                                    </div>
                                  </Figure>
                                );
                              }
                            )}
                          </ImageBox>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <label htmlFor="contained-button-file">
                          <input
                            style={{ display: 'none' }}
                            accept="image/*"
                            id="contained-button-file"
                            name="imageUpload"
                            onChange={handleImageUpload}
                            type="file"
                          />

                          <Button
                            component="span"
                            fullWidth
                            variant="contained"
                            color="secondary"
                          >
                            Upload Images
                          </Button>
                        </label>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        sx={{ color: 'text.primary' }}
                        control={
                          <Switch
                            checked={form.isActive}
                            color="primary"
                            onChange={(e) =>
                              setForm({
                                ...form,
                                isActive: !form.isActive,
                                publishedAt: e.target.checked
                                  ? new Date()
                                  : form.publishedAt
                              })
                            }
                          />
                        }
                        label={
                          form.isActive ? 'Public article' : 'Hidden article'
                        }
                      />
                    </Grid>

                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSavingArticle}
                      style={{ color: '#fff', marginTop: '10px' }}
                    >
                      {isSavingArticle
                        ? params.id
                          ? 'Editing article...'
                          : 'Creating article...'
                        : params.id
                        ? 'Save changes'
                        : 'Create article'}
                    </Button>

                    {params.id && (
                      <Button
                        type="button"
                        variant="outlined"
                        disabled={isSavingArticle}
                        size="small"
                        onClick={handleRemoveArticle}
                        style={{
                          marginTop: '15px',
                          marginLeft: '10px'
                        }}
                      >
                        {isSavingArticle
                          ? 'Deleting article...'
                          : 'Delete article'}
                      </Button>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <ArticlePreview
              form={form}
              articleBody={articleBody}
              editArticleText={editArticleText}
              deleteItemFromPreview={deleteItemFromPreview}
              sortPreviewItemDown={sortPreviewItemDown}
              sortPreviewItemUp={sortPreviewItemUp}
            />
          </Grid>
        </Grid>
      </div>
    </Wrapper>
  );
};
