import { FormEvent, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  Box,
  Autocomplete
} from '@mui/material';

import { cloneDeep } from 'lodash';
import { useOrganisation } from '../../../hooks/organisations';
import { useToast } from '../../../hooks/toast';

import { OrganisationFilter } from '../../../components/OrganisationFilter';
import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { PageTitle } from '../../../components/PageTitle';
import { Spinner } from '../../../components/Spinner';

import { Hint, Wrapper } from './styles';
import { changeUserOrganisation } from '../../../functions/users';

export const ChangeUserOrganisation: React.FC = () => {
  const { addToast } = useToast();
  const {
    organisations,
    industriesNames,
    users,
    currentOrganisation,
    setUsers
  } = useOrganisation();

  const [isChangingUserOrg, setIsChangingUserOrg] = useState(false);
  const [usersList, setUsersList] = useState<string[]>([]);
  const [divisionsList, setDivisionsList] = useState<string[]>([]);
  const [chosenUser, setChosenUser] = useState('');
  const [chosenIndustry, setChosenIndustry] = useState(industriesNames[0]);
  const [chosenOrganisation, setChosenOrganisation] = useState(
    organisations.filter((org) => org.industry === industriesNames[0])[0]
      ?.name || ''
  );
  const [chosenDivision, setChosenDivision] = useState(
    organisations.filter((org) => org.industry === industriesNames[0])[0]
      .divisions[0]?.name || ''
  );

  const [currentOrganisations, setCurrentOrganisations] = useState(() => {
    const filteredOrgs = organisations.filter(
      (org) => org.industry === industriesNames[0]
    );
    return filteredOrgs.map(({ name }) => name);
  });

  useEffect(() => {
    const usersNames = users.map(
      (user) =>
        `${user.firstName} ${user.lastName} - ${user.email || user.phone}`
    );
    setUsersList(usersNames);
    setChosenUser(usersNames[0]);
  }, [users]);

  useEffect(() => {
    const filteredOrgs = organisations.filter(
      (org) => org.industry === chosenIndustry
    );

    setCurrentOrganisations(filteredOrgs.map(({ name }) => name));
    setChosenOrganisation(filteredOrgs[0].name);
    setDivisionsList(filteredOrgs[0].divisions.map(({ name }) => name));
    setChosenDivision(filteredOrgs[0].divisions[0]?.name || '');
  }, [organisations, chosenIndustry]);

  useEffect(() => {
    const chosenOrg = organisations.find(
      ({ name }) => name === chosenOrganisation
    );

    if (!chosenOrg) return;

    const firstDivision = chosenOrg.divisions[0]?.name || '';
    setDivisionsList(chosenOrg.divisions.map(({ name }) => name));
    setChosenDivision(firstDivision);
  }, [organisations, chosenOrganisation]);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (!chosenIndustry || !chosenOrganisation || !chosenDivision) {
        return addToast({
          title: 'Please, choose an option in every field to proceed',
          type: 'info'
        });
      }

      const chosenUserInstance = users.find(
        (user) =>
          chosenUser ===
          `${user.firstName} ${user.lastName} - ${user.email || user.phone}`
      );
      const chosenOrganisationInstance = organisations.find(
        ({ name }) => chosenOrganisation === name
      );

      if (!chosenUserInstance || !chosenOrganisationInstance) {
        return addToast({
          title: 'An error has occurred',
          type: 'error',
          description: 'Please, try again later'
        });
      }

      const chosenDivisionInstance = chosenOrganisationInstance.divisions.find(
        ({ name }) => chosenDivision === name
      );

      if (!chosenDivisionInstance) {
        return addToast({
          title: 'An error has occurred',
          type: 'error',
          description: 'Please, try again later'
        });
      }

      setIsChangingUserOrg(true);

      try {
        await changeUserOrganisation({
          userId: chosenUserInstance.id!,
          oldOrgId: chosenUserInstance.organisation.id,
          oldDivisionId: chosenUserInstance.division.id,
          newOrgName: chosenOrganisationInstance.name,
          newOrgId: chosenOrganisationInstance.id,
          newDivisionName: chosenDivisionInstance?.name,
          newDivisionId: chosenDivisionInstance.id
        });

        setUsers((oldState) => {
          const copyUsers = cloneDeep(oldState);

          const userIndex = copyUsers.findIndex(
            ({ id }) => chosenUserInstance.id === id
          );

          if (userIndex >= 0) {
            copyUsers.splice(userIndex, 1);
            return copyUsers;
          }
          return copyUsers;
        });

        setIsChangingUserOrg(false);

        addToast({
          title: `User's organisation changed`,
          type: 'success'
        });
      } catch (error) {
        setIsChangingUserOrg(false);
        console.log(error);

        addToast({
          title: 'An error has occurred',
          type: 'error',
          description: 'Please, try again later'
        });
      }
    },
    [
      addToast,
      chosenDivision,
      chosenIndustry,
      chosenOrganisation,
      chosenUser,
      organisations,
      setUsers,
      users
    ]
  );

  return (
    <Wrapper>
      {isChangingUserOrg && <Spinner message="Changing user organisation" />}

      <Helmet>
        <title>Users - Change organisation</title>
      </Helmet>

      <OrganisationFilter hideDivision />

      <PageTitleWrapper>
        <PageTitle heading="Change user organisation" />
      </PageTitleWrapper>

      <div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <Hint>
                Choose an organisation above and search for the desired users
              </Hint>

              <CardContent>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 1, pl: 1, pr: 1 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        disablePortal
                        id="users-combo-box"
                        options={usersList}
                        value={chosenUser}
                        freeSolo={false}
                        disableClearable
                        onChange={(e) => {
                          setChosenUser(e.currentTarget.innerHTML);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            fullWidth
                            label="User to be moved"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        disablePortal
                        id="industry-combo-box"
                        options={industriesNames}
                        value={chosenIndustry}
                        freeSolo={false}
                        disableClearable
                        onChange={(e) => {
                          setChosenIndustry(
                            (e.currentTarget as HTMLElement).innerText
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            fullWidth
                            label="Select Industry"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        disablePortal
                        id="organisation-combo-box"
                        options={currentOrganisations}
                        value={chosenOrganisation}
                        freeSolo={false}
                        disableClearable
                        onChange={(e) => {
                          setChosenOrganisation(
                            (e.currentTarget as HTMLElement).innerText
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            fullWidth
                            label="Select Organisation"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        disablePortal
                        id="division-combo-box"
                        options={divisionsList}
                        value={chosenDivision}
                        freeSolo={false}
                        disableClearable
                        onChange={(e) => {
                          setChosenDivision(
                            (e.currentTarget as HTMLElement).innerText
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            fullWidth
                            label="Select Division"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  {chosenUser && (
                    <Hint style={{ margin: '10px 0' }}>
                      <strong>{chosenUser}</strong> will be moved from{' '}
                      <strong>{currentOrganisation.name}</strong> to{' '}
                      <strong>{chosenOrganisation}</strong> |{' '}
                      <strong>{chosenDivision}</strong>
                    </Hint>
                  )}

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isChangingUserOrg}
                    style={{ color: '#fff', marginTop: '10px' }}
                  >
                    {isChangingUserOrg
                      ? 'Changing user organisation'
                      : 'Change user organisation'}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Wrapper>
  );
};
