import { FormEvent, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, TextField, Grid, Card, CardContent, Box } from '@mui/material';

import { OrganisationFilter } from '../../../components/OrganisationFilter';
import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { PageTitle } from '../../../components/PageTitle';
import { Spinner } from '../../../components/Spinner';

import { useToast } from '../../../hooks/toast';
import { useOrganisation } from '../../../hooks/organisations';

import { createCode, verifyIfCodeExists } from '../../../functions/codes';
import { OrganisationCode } from '../../../models/code';

import { Hint, Wrapper } from './styles';

export const CreateCode: React.FC = () => {
  const { addToast } = useToast();
  const {
    currentOrganisation,
    chosenDivision,
    chosenDivisionId,
    chosenOrganisation
  } = useOrganisation();

  const [isCreatingCode, setIsCreatingCode] = useState(false);
  const [form, setForm] = useState({
    code: '',
    maximumUsage: 100,
    currentUsage: 0,
    isActive: true
  });

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      const code = { ...form } as OrganisationCode;
      code.divisionName = chosenDivision;
      code.divisionId = chosenDivisionId;
      code.organisationId = currentOrganisation.id;
      code.organisationName = currentOrganisation.name;

      if (chosenOrganisation === 'All' || chosenOrganisation === 'all') {
        return addToast({
          title: 'Please, choose an organisation',
          type: 'info'
        });
      }

      if (chosenDivision === 'All' || chosenDivision === 'all') {
        return addToast({
          title: 'Please, choose a division',
          type: 'info'
        });
      }

      if (!code.code || !code.maximumUsage) {
        return addToast({
          title: 'Please, fill in all required fields',
          type: 'info'
        });
      }

      setIsCreatingCode(true);

      try {
        const codeExists = await verifyIfCodeExists(code.code);

        if (codeExists) {
          setIsCreatingCode(false);
          return addToast({
            title: 'This code is already in use',
            description: code.code,
            type: 'info'
          });
        }

        await createCode(code);

        setIsCreatingCode(false);

        return addToast({
          title: 'Code created',
          type: 'success'
        });
      } catch (error) {
        setIsCreatingCode(false);
        addToast({
          title: 'Error creating code',
          description: 'Please, try again later',
          type: 'error'
        });
      }
      return true;
    },
    [
      form,
      addToast,
      chosenDivision,
      chosenDivisionId,
      currentOrganisation,
      chosenOrganisation
    ]
  );

  return (
    <>
      <OrganisationFilter hideUserSearch />

      <Wrapper>
        {isCreatingCode && <Spinner message="Creating code" />}

        <Helmet>
          <title>Codes - Create new</title>
        </Helmet>

        <PageTitleWrapper>
          <PageTitle heading="Create Code" />
        </PageTitleWrapper>

        <div>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12}>
              <Hint>
                The code will be created for the division selected above
              </Hint>

              <Card>
                <CardContent>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 1, pl: 1, pr: 1 }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} mb={2}>
                        <TextField
                          fullWidth
                          required
                          id="usage"
                          label="Maximum usage"
                          name="usage"
                          type="number"
                          value={form.maximumUsage}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              maximumUsage: Number(e.target.value)
                            })
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} mb={2}>
                        <TextField
                          fullWidth
                          required
                          id="code"
                          label="Code"
                          name="code"
                          type="text"
                          value={form.code.toUpperCase()}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              code: e.target.value.toUpperCase()
                            })
                          }
                        />
                      </Grid>
                    </Grid>

                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isCreatingCode}
                      style={{ color: '#fff' }}
                    >
                      {isCreatingCode ? 'Creating code...' : 'Create code'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Wrapper>
    </>
  );
};
