import { User } from '@sentry/react';
import { Organisation } from '../../../../../models/organisation';

export const hasHearingVisionAlertsPermission = (
  currentUser: User | undefined,
  currentOrganisation: Organisation | undefined
): boolean => {
  if (!currentUser || !currentOrganisation) return false;

  const organizationHasAccess = currentOrganisation?.customQuestionnaires?.some(
    (questionnaire) =>
      questionnaire === 'hearing-v2' || questionnaire === 'vision-v2'
  );
  const userHasAccess = currentUser.role === 'Kynd Admin';

  return organizationHasAccess || userHasAccess;
};
