import {
  CardContent,
  Card,
  Typography,
  CardProps,
  Divider,
  CardHeader
} from '@mui/material';
import { CardsContainer, Container } from './styles';

interface AnswerCardProps extends CardProps {
  title: string;
  cardsText: string[];
}

export function AnswerCard({ cardsText, title, ...rest }: AnswerCardProps) {
  return (
    <Container {...rest}>
      <Divider />
      <CardHeader title={title} />

      <CardsContainer>
        {cardsText.map((text) => (
          <Card key={text} className="answer-card">
            <CardContent>
              <Typography variant="h4" component="p">
                {text}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </CardsContainer>
    </Container>
  );
}
