import { useState, useEffect, useMemo, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Column } from 'react-table';
import {
  Checkbox,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch
} from '@mui/material';
import {
  Check,
  Close,
  CloudDownloadOutlined,
  ModeEditOutlineOutlined,
  Save
} from '@mui/icons-material';

import { cloneDeep, isEmpty, uniqueId } from 'lodash';
import { Organisation, ManagerProps } from '../../../models/organisation';
import { OrganisationCode } from '../../../models/code';
import {
  removeOrganisationManager,
  toggleOrganisationActivation,
  updateOrganisationAvailableQuestionnaires
} from '../../../functions/organisations';
import {
  getOrganisationCodes,
  updateCodesMaxUsage,
  updateCodeStatus
} from '../../../functions/codes';
import { OrganisationFilter } from '../../../components/OrganisationFilter';
import { useOrganisation } from '../../../hooks/organisations';
import { useToast } from '../../../hooks/toast';

import { Spinner } from '../../../components/Spinner';
import { Table } from '../../../components/Table';
import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { PageTitle } from '../../../components/PageTitle';

import {
  Container,
  OrganisationCodeLine,
  ManagerLine,
  CodeUsageButton,
  BorderlessButton,
  SaveCodesMaxUsageButton
} from './styles';
import { isCustomQuestionnaireUpdated } from './utils';

interface OrganisationsTableRow {
  index: number;
  name: string;
  managers: JSX.Element;
  codes: JSX.Element;
  employees: number;
  status: JSX.Element;
  customQuestionnaires: JSX.Element;
}

interface CodeMaxUsageProps {
  codeId: string;
  maxUsage: number;
}

interface LabelKeyData {
  key: string;
  label: string;
}

const availableCustomQuestionnaires: LabelKeyData[] = [
  {
    key: 'hearing-v2',
    label: 'Hearing V2'
  },
  {
    key: 'vision-v2',
    label: 'Vision V2'
  },
  {
    key: 'structural-health',
    label: 'Structural Health'
  }
];

export const Organisations = () => {
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [codes, setCodes] = useState<Array<OrganisationCode[]>>([]);
  const [codesMaxUsages, setCodesMaxUsages] = useState<
    Array<CodeMaxUsageProps[]>
  >([]);
  const [activeOrganisations, setActiveOrganisations] = useState<
    Organisation[]
  >([]);
  const [inactiveOrganisations, setInactiveOrganisations] = useState<
    Organisation[]
  >([]);
  const [isViewingActiveCorps, setIsViewingActiveCorps] = useState(false);
  const [isRemovingManager, setIsRemovingManager] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [isUpdatingCode, setIsUpdatingCode] = useState(false);
  const [isEditingCodes, setIsEditingCodes] = useState(false);

  const [selectedCustomQuestionnaires, setSelectedCustomQuestionnaires] =
    useState<Record<string, string[]>>({});
  const [
    currentUpdatingCustomQuestionnaire,
    setCurrentUpdatingCustomQuestionnaire
  ] = useState<{ id: string; questionnaires: string[] } | undefined>();

  const { addToast } = useToast();
  const { currentOrganisations, setCurrentOrganisations } = useOrganisation();

  useEffect(() => {
    const activeCorpsArray = currentOrganisations.filter(
      (corp) => corp.isActive
    );
    const inactiveCorpsArray = currentOrganisations.filter(
      (corp) => !corp.isActive
    );

    const organisationsCodesArray = new Array(activeCorpsArray.length);
    organisationsCodesArray.fill(null);
    setCodes(organisationsCodesArray);
    setCodesMaxUsages(organisationsCodesArray);

    setActiveOrganisations([...activeCorpsArray]);
    setInactiveOrganisations([...inactiveCorpsArray]);
    setOrganisations([...activeCorpsArray, ...inactiveCorpsArray]);
  }, [currentOrganisations]);

  useEffect(() => {
    setIsViewingActiveCorps(false);
  }, [currentOrganisations]);

  const handleViewActiveCorps = useCallback(
    (status: boolean) => {
      setOrganisations(
        status
          ? [...activeOrganisations]
          : [...activeOrganisations, ...inactiveOrganisations]
      );
      setIsViewingActiveCorps(status);
    },
    [activeOrganisations, inactiveOrganisations]
  );

  const handleCorpActivation = useCallback(
    async (orgId: string, status: boolean) => {
      const activationWord = status ? 'REACTIVATE' : 'INACTIVATE';
      const confirmStatus = window.confirm(
        `Are you sure you want to ${activationWord} this organisation?`
      );

      if (!confirmStatus) return;

      setIsUpdatingStatus(true);

      try {
        await toggleOrganisationActivation(orgId, status);

        const currentOrgsCopy = [...currentOrganisations];

        const orgIndex = currentOrgsCopy.findIndex((org) => org.id === orgId);
        currentOrgsCopy[orgIndex].isActive = status;
        setCurrentOrganisations(currentOrgsCopy);

        setIsUpdatingStatus(false);

        addToast({
          title: 'Status updated',
          type: 'success'
        });
      } catch (error) {
        console.log(error);
        addToast({
          title: 'Error updating status',
          description: 'Contact support',
          type: 'error'
        });
      }
    },
    [currentOrganisations, setCurrentOrganisations, addToast]
  );

  const handleRemoveOrganisationManager = useCallback(
    async (organisationId: string, manager: ManagerProps) => {
      const confirmManagerRemoval = window.confirm('Confirm manager removal?');

      if (!confirmManagerRemoval) return;
      setIsRemovingManager(true);

      try {
        await removeOrganisationManager(organisationId, manager);

        const organisationsCopy = [...currentOrganisations];
        const organisationIndex = organisationsCopy.findIndex(
          (corp) => corp.id === organisationId
        );

        const managerIndex = organisationsCopy[
          organisationIndex
        ].managers.findIndex((_manager) => _manager.id === manager.id);

        organisationsCopy[organisationIndex].managers.splice(managerIndex, 1);
        setCurrentOrganisations(organisationsCopy);

        setIsRemovingManager(false);

        addToast({
          title: 'Manager removed',
          type: 'success'
        });
      } catch (error) {
        console.log(error);
        addToast({
          title: 'Error removing manager',
          description: 'Please try again later',
          type: 'error'
        });
      }
    },
    [currentOrganisations, setCurrentOrganisations, addToast]
  );

  const handleUpdateOrganisationCustomQuestionnaires = useCallback(async () => {
    if (!currentUpdatingCustomQuestionnaire) return;

    const { id: orgId, questionnaires } = currentUpdatingCustomQuestionnaire;

    const selectedOrg = organisations.find(({ id }) => id === orgId);

    // If the organisation does not exists (it will always exists, but as find can return undefined, TS does not know that);
    if (
      !selectedOrg ||
      isCustomQuestionnaireUpdated(
        selectedOrg?.customQuestionnaires,
        questionnaires
      )
    ) {
      return;
    }

    const confirmManagerRemoval = window.confirm(
      `Confirm update available custom questionnaires for ${selectedOrg.name}? `
    );

    if (!confirmManagerRemoval) return;
    setIsRemovingManager(true);

    try {
      await updateOrganisationAvailableQuestionnaires(orgId, questionnaires);

      const organisationsCopy = cloneDeep(currentOrganisations);
      const orgIndex = organisationsCopy.findIndex((corp) => corp.id === orgId);

      organisationsCopy[orgIndex].customQuestionnaires = questionnaires;
      setCurrentOrganisations(organisationsCopy);

      setIsRemovingManager(false);

      addToast({
        title: 'Available Custom Questionnaire Updated',
        type: 'success'
      });
    } catch (error) {
      console.log(error);
      addToast({
        title: 'Error updating available custom questionnaire',
        description: 'Please try again later',
        type: 'error'
      });
    }

    setCurrentUpdatingCustomQuestionnaire(undefined);
  }, [
    addToast,
    currentOrganisations,
    currentUpdatingCustomQuestionnaire,
    organisations,
    setCurrentOrganisations
  ]);

  const handleGetOrganisationCodes = useCallback(
    async (organisationId: string, organisationIndex) => {
      const copyOrganisationCodesArray = [...codes];
      const organisationCodes = await getOrganisationCodes(organisationId);

      if (!organisationCodes) return;
      copyOrganisationCodesArray[organisationIndex] = organisationCodes;

      const copyCodesMaxUsages = [...codesMaxUsages];
      const orgCodesMaxUsages = organisationCodes.map((code) => {
        return {
          codeId: code.id!,
          maxUsage: code.maximumUsage
        };
      });

      copyCodesMaxUsages[organisationIndex] = orgCodesMaxUsages;

      setCodes(copyOrganisationCodesArray);
      setCodesMaxUsages(copyCodesMaxUsages);
    },
    [codes, codesMaxUsages]
  );

  const handleChangeCodeStatus = useCallback(
    async (code: OrganisationCode, orgIndex: number, codeIndex: number) => {
      const status = !code.isActive;
      await updateCodeStatus(code, status);

      const corpCodes = [...codes];
      corpCodes[orgIndex][codeIndex].isActive = status;

      setCodes(corpCodes);
    },
    [codes]
  );

  const handleEditCodeMaxUsage = useCallback(
    async (
      event: React.ChangeEvent<HTMLInputElement>,
      codeIndex: number,
      orgIndex: number,
      newMaxValue: number
    ) => {
      const copyCodesMaxUsage = cloneDeep(codesMaxUsages);

      copyCodesMaxUsage[orgIndex][codeIndex].maxUsage = newMaxValue;

      setCodesMaxUsages(copyCodesMaxUsage);
    },
    [codesMaxUsages]
  );

  const handleSaveCodesMaximumUsage = useCallback(
    async (orgIndex: number) => {
      setIsUpdatingCode(true);

      const copyOrganisationCodes = cloneDeep(codes);
      const copyMaxUsages = cloneDeep(codesMaxUsages);

      const codesToUpdate = copyMaxUsages[orgIndex].filter((code) => {
        const oldCode = copyOrganisationCodes[orgIndex].find(
          ({ id }) => id === code.codeId
        );

        if (!oldCode) return;

        if (oldCode.maximumUsage === code.maxUsage) return;

        return code;
      });

      if (!codesToUpdate.length) {
        setIsUpdatingCode(false);
        return;
      }

      try {
        await updateCodesMaxUsage(codesToUpdate);

        copyOrganisationCodes[orgIndex].forEach((code) => {
          const newMaxUsage = codesMaxUsages[orgIndex].find(
            ({ codeId }) => codeId === code.id
          );

          if (!newMaxUsage) return;

          // eslint-disable-next-line no-param-reassign
          code.maximumUsage = newMaxUsage.maxUsage;
        });

        setCodes(copyOrganisationCodes);
        setIsUpdatingCode(false);
      } catch (error) {
        console.log(error);
        setIsUpdatingCode(false);

        addToast({
          title: 'Error updating codes',
          description: 'Please, try again later',
          type: 'error'
        });
      }
    },
    [codes, codesMaxUsages, addToast]
  );

  const organisationsColumns: Column<OrganisationsTableRow>[] = useMemo(
    () => [
      {
        Header: `#`,
        accessor: 'index'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Managers',
        accessor: 'managers'
      },
      {
        Header: 'Codes',
        accessor: 'codes'
      },
      {
        Header: '#Employees',
        accessor: 'employees'
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Custom Questionnaires',
        accessor: 'customQuestionnaires'
      }
    ],
    []
  );

  const handleChangeCustomQuestionnaire = (
    value: string | string[],
    id: string
  ) => {
    setSelectedCustomQuestionnaires((prev) => ({
      ...prev,
      [id]: typeof value === 'string' ? value.split(',') : value
    }));
    setCurrentUpdatingCustomQuestionnaire({
      id,
      questionnaires: typeof value === 'string' ? value.split(',') : value
    });
  };

  const organisationsTableData = useMemo(() => {
    const organisationsDataArray = [] as OrganisationsTableRow[];

    organisations.forEach(
      ({ id, name, managers, isActive, employees }, orgIndex) => {
        const organisationRow = {
          index: orgIndex + 1,
          name,
          managers: managers?.length ? (
            <Select style={{ width: '100%' }}>
              {managers.map((manager) => (
                <MenuItem
                  key={manager.id}
                  value={manager.id}
                  style={{ cursor: 'default' }}
                >
                  <ManagerLine>
                    {managers.length > 1 && (
                      <BorderlessButton
                        type="button"
                        title="Remove manager: This user will still be able to access their mobile app through their company, but will no longer have access to the dashboard admin"
                        onClick={() =>
                          handleRemoveOrganisationManager(id, manager)
                        }
                      >
                        <Close color="error" />
                      </BorderlessButton>
                    )}
                    {manager.name}
                  </ManagerLine>
                </MenuItem>
              ))}
            </Select>
          ) : (
            <></>
          ),
          codes:
            codes[orgIndex] === null ? (
              <BorderlessButton
                className="regular-button"
                type="button"
                onClick={() => handleGetOrganisationCodes(id, orgIndex)}
                title="Load codes"
                style={{ background: 'transparent' }}
              >
                <CloudDownloadOutlined />
              </BorderlessButton>
            ) : (
              <Select style={{ width: '100%' }} value="">
                {isEditingCodes && (
                  <SaveCodesMaxUsageButton
                    type="button"
                    onClick={() => handleSaveCodesMaximumUsage(orgIndex)}
                  >
                    <Save color="primary" />
                    <p>Save codes max usage</p>
                  </SaveCodesMaxUsageButton>
                )}
                {codes[orgIndex]?.map((code, codeIndex) => (
                  <MenuItem
                    key={code.id}
                    value={code.id}
                    style={{ cursor: 'default' }}
                  >
                    <OrganisationCodeLine>
                      <div>
                        <i>hidden</i>
                        <strong>{code.code}</strong>
                        <p>
                          {code.divisionName} | {code.currentUsage}/
                          {isEditingCodes &&
                          codesMaxUsages[orgIndex] &&
                          codesMaxUsages[orgIndex].length ? (
                            <input
                              id={code.id}
                              name={code.code}
                              type="number"
                              value={
                                codesMaxUsages[orgIndex][codeIndex].maxUsage
                              }
                              onChange={(event) =>
                                handleEditCodeMaxUsage(
                                  event,
                                  codeIndex,
                                  orgIndex,
                                  Number(event.target.value)
                                )
                              }
                            />
                          ) : (
                            code.maximumUsage
                          )}
                        </p>
                      </div>

                      {!isEditingCodes && (
                        <BorderlessButton
                          className="regular-button"
                          type="button"
                          title={
                            code.isActive
                              ? 'Active code. Click to make it inactivate, so people can no longer use it'
                              : 'Inactive code. Click to make it active again, so people can use it again'
                          }
                          onClick={() =>
                            handleChangeCodeStatus(
                              { ...code },
                              orgIndex,
                              codeIndex
                            )
                          }
                        >
                          {code.isActive ? (
                            <Check color="success" />
                          ) : (
                            <Close color="error" />
                          )}
                        </BorderlessButton>
                      )}
                    </OrganisationCodeLine>
                  </MenuItem>
                ))}
              </Select>
            ),
          employees: employees ? employees.length : 0,
          status: (
            <aside>
              <BorderlessButton
                className="status-button"
                type="button"
                title={
                  isActive
                    ? 'Active organisation. Click to make them inactivate and make their employees unable to use their app'
                    : 'Inactive organisation. Click to make them active, so user can use their app again'
                }
                onClick={() => handleCorpActivation(id, !isActive)}
              >
                {isActive ? <Check color="success" /> : <Close color="error" />}
              </BorderlessButton>
            </aside>
          ),
          customQuestionnaires: (
            <Select
              fullWidth
              required
              multiple
              value={selectedCustomQuestionnaires[id] || []}
              onClose={handleUpdateOrganisationCustomQuestionnaires}
              onChange={(e) =>
                handleChangeCustomQuestionnaire(e?.target?.value || '', id)
              }
              // input={<OutlinedInput label="Category *" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {availableCustomQuestionnaires.map(({ key, label }) => (
                <MenuItem key={uniqueId()} value={key}>
                  <Checkbox
                    checked={
                      selectedCustomQuestionnaires[id]?.indexOf(key) > -1
                    }
                  />
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
          )
        };

        return organisationsDataArray.push(organisationRow);
      }
    );

    return organisationsDataArray;
  }, [
    organisations,
    codes,
    isEditingCodes,
    selectedCustomQuestionnaires,
    handleUpdateOrganisationCustomQuestionnaires,
    handleRemoveOrganisationManager,
    handleGetOrganisationCodes,
    handleSaveCodesMaximumUsage,
    codesMaxUsages,
    handleEditCodeMaxUsage,
    handleChangeCodeStatus,
    handleCorpActivation
  ]);

  useEffect(() => {
    if (isEmpty(organisations)) return;

    const organisationsCustomQuestionnaires: Record<string, string[]> = {};
    organisations
      .filter((organisation) => !isEmpty(organisation.customQuestionnaires))
      .forEach(({ id, customQuestionnaires }) => {
        if (customQuestionnaires) {
          organisationsCustomQuestionnaires[id] = customQuestionnaires;
        }
      });

    setSelectedCustomQuestionnaires(organisationsCustomQuestionnaires);
  }, [organisations]);

  return (
    <Container>
      {isUpdatingCode && <Spinner message="Updating codes" />}
      <OrganisationFilter hideOrganisation hideDivision hideUserSearch />

      <Helmet>
        <title>Organisations - List</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle heading="Organisations List" />
      </PageTitleWrapper>

      <FormControlLabel
        sx={{ color: 'text.primary' }}
        control={
          <Switch
            checked={isViewingActiveCorps}
            onChange={() => handleViewActiveCorps(!isViewingActiveCorps)}
          />
        }
        label="Active orgs only"
      />

      <CodeUsageButton
        className="regular-button"
        type="button"
        onClick={() => setIsEditingCodes(!isEditingCodes)}
      >
        <ModeEditOutlineOutlined color="primary" />
        <p>
          {isEditingCodes ? 'Cancel max usage edit' : 'Edit codes max usage'}
        </p>
      </CodeUsageButton>

      {isRemovingManager && <Spinner message="Removing manager" />}
      {isUpdatingStatus && <Spinner message="Updating status" />}

      <Table columns={organisationsColumns} data={organisationsTableData} />
    </Container>
  );
};
