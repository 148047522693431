import * as Sentry from '@sentry/react';
import { set } from 'lodash';
import { HazardAction, HearingVisionAlert } from '../models/hearingVisionAlert';
import { firestore, isProduction, firebaseSDK } from '../services/firebase';

const questionnaireAlertsCollection = firestore.collection(
  'questionnaireAlerts'
);

export const getHearingVisionAlerts = async (orgId: string) => {
  const redFlaggedUsers: HearingVisionAlert[] = [];

  try {
    const users = await questionnaireAlertsCollection
      .where('organisation.id', '==', orgId)
      .get();

    users.docs.forEach((flaggedUser) => {
      const flagData = flaggedUser.data() as Omit<HearingVisionAlert, 'id'>;

      redFlaggedUsers.push({
        id: flaggedUser.id,
        ...flagData
      });
    });

    return redFlaggedUsers;
  } catch (error) {
    console.error(error);
    if (isProduction) {
      Sentry.captureException(error);
    }

    throw new Error('Fail to get Hearing and Vision alerts');
  }
};

interface UpdateObjectProps {
  actionTaken: HazardAction;
  status: string;
  hearingStatus?: string;
  visionStatus?: string;
}

export const updateAlert = async (
  alertId: string,
  updateProps: UpdateObjectProps
) => {
  try {
    const { status, actionTaken, hearingStatus, visionStatus } = updateProps;

    const updateObject = {
      actions: firebaseSDK.firestore.FieldValue.arrayUnion(actionTaken),
      status
    };

    if (hearingStatus) {
      set(updateObject, 'hearingStatus', hearingStatus);
    }
    if (visionStatus) {
      set(updateObject, 'visionStatus', visionStatus);
    }

    await questionnaireAlertsCollection.doc(alertId).update(updateObject);

    return;
  } catch (error) {
    console.error('Fail to update hearing and vision alert.', error);
    if (isProduction) {
      Sentry.captureException(error);
    }
    throw new Error('Fail to update Hearing and Vision alert');
  }
};
