import styled from 'styled-components';

export const Wrapper = styled.div`
  .answer-button {
    border: 0;
    border-radius: 7px;
    height: 53px;
    width: 53px;
    margin: 0;
    cursor: pointer;
    background-color: #fff;
    border: 0.5px solid #bbb;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    transition: 0.1s;
  }

  .custom-options {
    display: flex;
    gap: 10px;

    .add-answer {
      svg {
        color: #0f0;
      }
    }
  }
`;

export const CustomAnswerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  margin-bottom: 10px;

  .remove-answer {
    svg {
      color: #faa;
    }

    &:hover {
      svg {
        color: #f33;
      }
    }
  }
`;
