export const groupsQuestionnaire = [
  {
    category: 'Life',
    questionnaire: 'Life',
    fieldPath: 'scores.life.life.color'
  },
  {
    category: 'Mind',
    questionnaire: 'Mindfulness',
    fieldPath: 'scores.mind.mindfulness.color'
  },
  {
    category: 'Mind',
    questionnaire: 'Sleep',
    fieldPath: 'scores.mind.sleep.color'
  },
  {
    category: 'Mind',
    questionnaire: 'Gratitude',
    fieldPath: 'scores.mind.gratitude.color'
  },
  {
    category: 'Mind',
    questionnaire: 'Depression, Anxiety and Stress',
    fieldPath: 'scores.mind.depressionAnxietyAndStress.color'
  },
  {
    category: 'Mind',
    questionnaire: 'Emotional Index',
    fieldPath: 'scores.mind.emotionalIndex.color'
  },
  {
    category: 'Body',
    questionnaire: 'Weight',
    fieldPath: 'scores.body.weight.color'
  },
  {
    category: 'Body',
    questionnaire: 'Waist Circumference',
    fieldPath: 'scores.body.waistCircumference.color'
  },
  {
    category: 'Body',
    questionnaire: 'Smoking',
    fieldPath: 'scores.body.smoking.color'
  },
  {
    category: 'Body',
    questionnaire: 'Lung function',
    fieldPath: 'scores.body.lungFunction.color'
  },
  {
    category: 'Body',
    questionnaire: 'Resting Heart Rate',
    fieldPath: 'scores.body.restingHeartRate.color'
  },
  {
    category: 'Body',
    questionnaire: 'Ratio (Cholesterol/HDL)',
    fieldPath: 'scores.body.ratioCholesterolHdl.color'
  },
  {
    category: 'Body',
    questionnaire: 'Alcohol',
    fieldPath: 'scores.body.alcohol.color'
  },
  {
    category: 'Body',
    questionnaire: 'HDL',
    fieldPath: 'scores.body.hdl.color'
  },
  {
    category: 'Body',
    questionnaire: 'Triglycerides',
    fieldPath: 'scores.body.triglycerides.color'
  },
  {
    category: 'Body',
    questionnaire: 'LDL',
    fieldPath: 'scores.body.ldl.color'
  },
  {
    category: 'Body',
    questionnaire: 'Height',
    fieldPath: 'scores.body.height.color'
  },
  {
    category: 'Body',
    questionnaire: 'HbA1C',
    fieldPath: 'scores.body.hbA1C.color'
  },
  {
    category: 'Body',
    questionnaire: 'Systolic/Diastolic',
    fieldPath: 'scores.body.systolicDiastolic.color'
  },
  {
    category: 'Body',
    questionnaire: 'Total Cholesterol',
    fieldPath: 'scores.body.totalCholesterol.color'
  }
];
