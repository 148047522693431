import { RouteObject, Navigate } from 'react-router-dom';

import { SidebarLayout } from '../layout/SidebarLayout';
import { BaseLayout } from '../layout/BaseLayout';

import { Dashboard } from '../pages/Dashboard';
import { NotFound } from '../pages/NotFound';
import { SignIn } from '../pages/SignIn';
import { Users } from '../pages/Users/UsersList';
import { CreateUser } from '../pages/Users/CreateUser';
import { Organisations } from '../pages/Organisations/OrganisationsList';
import { CreateOrganisation } from '../pages/Organisations/CreateOrganisation';
import { QuestionsList } from '../pages/Questions/QuestionsList';
import { CreateQuestion } from '../pages/Questions/Create';
import { Divisions } from '../pages/Divisions/ListDivisions';
import { CreateDivision } from '../pages/Divisions/CreateDivision';
import { OrganisationCodes } from '../pages/Codes/ListCodes';
import { CreateCode } from '../pages/Codes/CreateNew';
import { ListNotifications } from '../pages/Notifications/ListNotifications';
import { CreateNotification } from '../pages/Notifications/CreateNotification';
import { InitialMessages } from '../pages/Notifications/Initial';
import { Clinicians } from '../pages/Users/Clinicians';
import { Body } from '../pages/Dashboard/Body';
import { Life } from '../pages/Dashboard/Life';
import { Mind } from '../pages/Dashboard/Mind';
import { Industries } from '../pages/Industries/AllIndustries';
import { OrgsIndustries } from '../pages/Industries/OrgsIndustries';
import { Articles } from '../pages/Articles/ArticlesList';
import { CreateArticle } from '../pages/Articles/CreateArticle';
import { ChangeUserOrganisation } from '../pages/Users/ChangeOrg';
import { HearingVisionAlerts } from '../pages/HearingVisionAlerts';

export const publicRoutes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <SignIn />
      },
      {
        path: 'signin',
        element: <Navigate to="/" replace />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export const privateRoutes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" replace />
      },
      {
        path: 'dashboard',
        element: <Dashboard />
      },
      {
        path: 'dashboard/body',
        element: <Body />
      },
      {
        path: 'dashboard/life',
        element: <Life />
      },
      {
        path: 'dashboard/mind',
        element: <Mind />
      },
      {
        path: 'users',
        element: <Users />
      },
      {
        path: 'users/create',
        element: <CreateUser />
      },
      {
        path: 'users/clinicians',
        element: <Clinicians />
      },
      {
        path: 'users/change-user-organisation',
        element: <ChangeUserOrganisation />
      },
      {
        path: 'divisions',
        element: <Divisions />
      },
      {
        path: 'divisions/create',
        element: <CreateDivision />
      },
      {
        path: 'codes',
        element: <OrganisationCodes />
      },
      {
        path: 'codes/create',
        element: <CreateCode />
      },
      {
        path: 'organisations',
        element: <Organisations />
      },
      {
        path: 'organisations/create',
        element: <CreateOrganisation />
      },
      {
        path: 'questions',
        element: <QuestionsList />
      },
      {
        path: 'questions/create',
        element: <CreateQuestion />
      },
      {
        path: 'notifications',
        element: <ListNotifications />
      },
      {
        path: 'notifications/create',
        element: <CreateNotification />
      },
      {
        path: 'notifications/initial',
        element: <InitialMessages />
      },
      {
        path: 'industries/all',
        element: <Industries />
      },
      {
        path: 'industries/orgs-industries',
        element: <OrgsIndustries />
      },
      {
        path: 'articles/all',
        element: <Articles />
      },
      {
        path: 'article/create',
        element: <CreateArticle />
      },
      {
        path: 'article/update/:id',
        element: <CreateArticle />
      },
      {
        path: 'hearing-vision-alerts',
        element: <HearingVisionAlerts />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export const Routes = () => {
  const user = localStorage.getItem('@KyndDashboard:user');

  const isAuthenticated = !!user;

  return !isAuthenticated ? publicRoutes : privateRoutes;
};
