import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { cloneDeep, get, set, capitalize } from 'lodash';
import {
  Autocomplete,
  TextField,
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  Checkbox
} from '@mui/material';
import { Cancel, ModeEdit, Save } from '@mui/icons-material';

import Markdown from 'markdown-to-jsx';
import { Spinner } from '../../../components/Spinner';
import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { PageTitle } from '../../../components/PageTitle';
import {
  getInitialMessages,
  updateInitialMessage
} from '../../../functions/initialMessages';
import { InitialMessage } from '../../../models/initialMessage';
import { useToast } from '../../../hooks/toast';

import { Wrapper, EditInput, SaveEditButton, Select, TextArea } from './styles';

const bodyMeasurements = [] as any;
const mindMeasurements = [] as any;
const lifeMeasurements = [] as any;
let allMessages = [] as any;

const frequencyOptions = [
  {
    value: '1',
    label: 'One day'
  },
  {
    value: '7',
    label: 'One week'
  },
  {
    value: '14',
    label: 'Two weeks'
  },
  {
    value: '30',
    label: 'Monthly'
  },
  {
    value: '90',
    label: 'Three months'
  },
  {
    value: '180',
    label: 'Six months'
  },
  {
    value: '365',
    label: 'Annually'
  }
];

export const InitialMessages: React.FC = () => {
  const [currentCategory, setCurrentCategory] = React.useState('Life');
  const [currentMessage, setCurrentMessage] = React.useState(
    {} as InitialMessage
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const { addToast } = useToast();

  const getMessagesAsync = async () => {
    setIsLoading(true);
    const response = await getInitialMessages();
    allMessages = response;
    response.forEach((message) => {
      if (message.category === 'Mind') {
        mindMeasurements.push(message);
      } else if (message.category === 'Life') {
        lifeMeasurements.push(message);
      } else if (message.category === 'Body') {
        bodyMeasurements.push(message);
      }
    });
    setIsLoading(false);
  };

  const checkMeasurements = () => {
    let response;
    switch (currentCategory) {
      case 'Life':
        response = lifeMeasurements;
        break;
      case 'Body':
        response = bodyMeasurements;
        break;
      case 'Mind':
        response = mindMeasurements;
        break;
      default:
        break;
    }
    return response.map((i: any) => {
      return i.measurement;
    });
  };

  const handleChangeMeasurement = (measurement: string) => {
    setCurrentMessage(
      allMessages.filter((i: any) => i.measurement === measurement)[0]
    );
    setIsEditing(false);
  };

  useEffect(() => {
    getMessagesAsync();
  }, []);

  const sendUpdate = async () => {
    setIsLoading(true);
    try {
      const greenMessage = get(currentMessage, `green.message`, '').replace(
        /(?:\r\n|\r|\n)/g,
        ' <br/> '
      );
      const orangeMessage = get(currentMessage, `orange.message`, '').replace(
        /(?:\r\n|\r|\n)/g,
        ' <br/> '
      );
      const redMessage = get(currentMessage, `red.message`, '').replace(
        /(?:\r\n|\r|\n)/g,
        ' <br/> '
      );

      const formattedMessage = cloneDeep(currentMessage);

      formattedMessage.green.message = greenMessage;
      formattedMessage.orange.message = orangeMessage;
      formattedMessage.red.message = redMessage;

      await updateInitialMessage(formattedMessage);
      addToast({
        title: 'Message updated',
        type: 'success'
      });
    } catch (error) {
      addToast({
        title: 'Error updating message',
        description: 'Please, try again later',
        type: 'error'
      });
    }
    setIsLoading(false);
    setIsEditing(false);
  };

  const handleEdit = (path: string, value: any) => {
    set(currentMessage, path, value);
    setCurrentMessage({
      ...currentMessage
    });
  };

  const createCart = (colorCategory: string, background: string) => {
    return (
      <Card
        style={{
          marginTop: '16px',
          backgroundColor: get(currentMessage, `${colorCategory}.isActive`)
            ? background
            : '#d9d9d9',
          marginBottom: '16px'
        }}
      >
        <CardContent>
          <h2 style={{ margin: '0px' }}>
            {capitalize(colorCategory)} messages
          </h2>
          {isEditing && (
            <div style={{ display: 'flex' }}>
              <h3>Active?</h3>
              <Checkbox
                style={{
                  color: 'white'
                }}
                checked={get(currentMessage, `${colorCategory}.isActive`)}
                onClick={() => {
                  handleEdit(
                    `${colorCategory}.isActive`,
                    !get(currentMessage, `${colorCategory}.isActive`)
                  );
                }}
              />
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <h3>Frequency:</h3>
              {isEditing ? (
                <Select
                  value={get(currentMessage, `${colorCategory}.frequency`)}
                  onChange={(e) =>
                    handleEdit(`${colorCategory}.frequency`, e.target.value)
                  }
                >
                  <option value="">Select frequency</option>
                  {frequencyOptions?.map((frequency) => (
                    <option key={frequency.value} value={frequency.value}>
                      {frequency.label}
                    </option>
                  ))}
                </Select>
              ) : (
                <p>
                  {frequencyOptions?.map((frequency) => {
                    return frequency.value ===
                      get(currentMessage, `${colorCategory}.frequency`)
                      ? frequency.label
                      : null;
                  })}
                </p>
              )}
            </div>
          </div>
          <h3>Subject:</h3>
          {isEditing ? (
            <EditInput
              type="text"
              value={get(currentMessage, `${colorCategory}.subject`)}
              onChange={(e) =>
                handleEdit(`${colorCategory}.subject`, e.target.value)
              }
              style={{ width: '100%' }}
            />
          ) : (
            <p>{get(currentMessage, `${colorCategory}.subject`)}</p>
          )}
          <h3>Message:</h3>
          {isEditing ? (
            <TextArea
              value={get(
                currentMessage,
                `${colorCategory}.message`,
                ''
              ).replace(/<br\s?\/?>/g, '\n')}
              onChange={(e) =>
                handleEdit(`${colorCategory}.message`, e.target.value)
              }
            />
          ) : (
            <p>
              <Markdown>
                {get(currentMessage, `${colorCategory}.message`, '')}
              </Markdown>
            </p>
          )}
        </CardContent>
      </Card>
    );
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Messages templates</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle heading="Initial Messages Templates" />
      </PageTitleWrapper>

      <Container>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box component="form" noValidate sx={{ mt: 1, pl: 1, pr: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        options={['Life', 'Mind', 'Body']}
                        disableClearable
                        onChange={(e) =>
                          setCurrentCategory(e.currentTarget.innerHTML)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            fullWidth
                            label="Category"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        options={checkMeasurements()}
                        disableClearable
                        onChange={(e) =>
                          handleChangeMeasurement(e.currentTarget.innerHTML)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            fullWidth
                            label="Measurements"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  {currentMessage.measurement && (
                    <>
                      {isEditing ? (
                        <>
                          <SaveEditButton
                            style={{ marginTop: '16px' }}
                            title="Save"
                            type="button"
                            onClick={sendUpdate}
                          >
                            <Save color="secondary" />
                          </SaveEditButton>

                          <SaveEditButton
                            style={{ marginLeft: '16px' }}
                            title="Cancel"
                            type="button"
                            onClick={() => setIsEditing(!isEditing)}
                          >
                            <Cancel color="secondary" />
                          </SaveEditButton>
                        </>
                      ) : (
                        <SaveEditButton
                          style={{ marginTop: '16px' }}
                          title="Save edited messages"
                          type="button"
                          onClick={() => setIsEditing(!isEditing)}
                        >
                          <ModeEdit color="secondary" />
                        </SaveEditButton>
                      )}
                    </>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {createCart('green', '#5dc45d')}
        {createCart('orange', '#e09c69')}
        {createCart('red', '#fc6161')}
      </Container>

      {isLoading && <Spinner message="Loading" />}
    </Wrapper>
  );
};
