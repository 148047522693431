import { FormEvent, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { v4 } from 'uuid';
import {
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  Box,
  Container
} from '@mui/material';

import { cloneDeep } from 'lodash';
import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { PageTitle } from '../../../components/PageTitle';
import { Spinner } from '../../../components/Spinner';

import { createDivision } from '../../../functions/divisions';
import { useOrganisation } from '../../../hooks/organisations';
import { useToast } from '../../../hooks/toast';
import { Division } from '../../../models/division';

import { Hint, Wrapper } from './styles';
import { OrganisationFilter } from '../../../components/OrganisationFilter';

export const CreateDivision: React.FC = () => {
  const [isCreatingDivision, setIsCreatingDivision] = useState(false);
  const [form, setForm] = useState({
    name: '',
    description: ''
  });

  const {
    chosenOrganisation,
    currentOrganisation,
    setOrganisations,
    setCurrentOrganisation,
    setDivisionsNames
  } = useOrganisation();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (!form.name) {
        return addToast({
          title: 'Fill in all required fields'
        });
      }

      if (chosenOrganisation === 'All' || chosenOrganisation === 'all') {
        return addToast({
          title: 'Please, choose an organisation',
          type: 'info'
        });
      }

      setIsCreatingDivision(true);

      const division = {} as Division;

      const organisationId = currentOrganisation.id;

      try {
        division.id = v4();
        division.name = form.name;
        division.description = form.description;
        division.codes = [];

        if (!organisationId) {
          throw new Error('Missing organisation id');
        }
        await createDivision({ division, organisationId });

        setOrganisations((organisations) => {
          const orgsCopy = cloneDeep(organisations);

          const currentOrgIndex = organisations.findIndex(
            (searchOrg) => searchOrg.id === currentOrganisation.id
          );

          if (currentOrgIndex >= 0) {
            orgsCopy[currentOrgIndex].divisions.push(division);
          }

          setCurrentOrganisation(orgsCopy[currentOrgIndex]);
          return orgsCopy;
        });

        setDivisionsNames((currentDivisions) => {
          const divisionsCopy = cloneDeep(currentDivisions);

          divisionsCopy.push(division.name);

          return divisionsCopy;
        });

        setIsCreatingDivision(false);

        return addToast({
          title: 'Division created',
          type: 'success'
        });
      } catch (error) {
        setIsCreatingDivision(false);
        return addToast({
          title: 'Error creating division',
          description: 'Try again later',
          type: 'error'
        });
      }
    },
    [
      addToast,
      form,
      chosenOrganisation,
      currentOrganisation.id,
      setOrganisations,
      setCurrentOrganisation,
      setDivisionsNames
    ]
  );

  return (
    <Wrapper>
      {isCreatingDivision && <Spinner message="Creating division" />}

      <OrganisationFilter hideUserSearch hideDivision />

      <Helmet>
        <title>Divisions - Create new</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle heading="New Division" />
      </PageTitleWrapper>

      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Hint>
              The division will be created for the organisation selected above
            </Hint>

            <Card>
              <Card>
                <CardContent>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 1, pl: 1, pr: 1 }}
                  >
                    <Grid container spacing={2} mb={2}>
                      <Grid item xs={12} sm={6}>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            name="name"
                            required
                            fullWidth
                            id="name"
                            label="Division name"
                            value={form.name}
                            onChange={(e) =>
                              setForm({ ...form, name: e.target.value })
                            }
                            autoFocus
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} mt={2}>
                          <TextField
                            fullWidth
                            id="description"
                            label="Description"
                            name="description"
                            value={form.description}
                            onChange={(e) =>
                              setForm({ ...form, description: e.target.value })
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isCreatingDivision}
                      style={{ color: '#fff' }}
                    >
                      {isCreatingDivision
                        ? 'Creating division...'
                        : 'Create division'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};
