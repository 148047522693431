import React from 'react';

import { AuthProvider } from './auth';
import { OrganisationProvider } from './organisations';
import { ArticleProvider } from './articles';
import { QuestionsProvider } from './questions';
import { SidebarProvider } from './sidebar';
import { ToastProvider } from './toast';

export const AppProvider: React.FC = ({ children }) => (
  <ToastProvider>
    <AuthProvider>
      <OrganisationProvider>
        <ArticleProvider>
          <QuestionsProvider>
            <SidebarProvider>{children}</SidebarProvider>
          </QuestionsProvider>
        </ArticleProvider>
      </OrganisationProvider>
    </AuthProvider>
  </ToastProvider>
);
