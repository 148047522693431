import { Add } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { PageTitle } from '../../../components/PageTitle';
import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { Spinner } from '../../../components/Spinner';

import { createIndustry, getIndustries } from '../../../functions/industries';
import { useOrganisation } from '../../../hooks/organisations';
import { useToast } from '../../../hooks/toast';
import { formatDate } from '../../../utils/formatDate';

import {
  Container,
  IndustriesContainer,
  IndustryCard,
  IndustryCreation
} from './styles';

export interface Industry {
  id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
}

export const Industries = () => {
  const { addToast } = useToast();
  const { allIndustries, setAllIndustries } = useOrganisation();

  const [industries, setIndustries] = useState([] as Industry[]);
  const [isLoadingIndustries, setIsLoadingIndustries] = useState(true);
  const [isCreatingIndustry, setIsCreatingIndustry] = useState(false);
  const [newIndustry, setNewIndustry] = useState('');

  useEffect(() => {
    const getIndustriesAsync = async () => {
      try {
        const response = await getIndustries();
        setIndustries(response);
        setIsLoadingIndustries(false);
      } catch (error) {
        console.log(error);
        setIsLoadingIndustries(false);

        addToast({
          title: 'Error loading industries',
          description: 'Please, try again later',
          type: 'error'
        });
      }
    };

    getIndustriesAsync();
  }, [addToast]);

  const handleCreateIndustry = useCallback(async () => {
    if (!newIndustry) {
      return addToast({
        title: 'Choose a name for the new industry',
        type: 'info'
      });
    }

    const industryAlreadyExists = industries.find(
      (industry) => industry.name.toUpperCase() === newIndustry.toUpperCase()
    );

    if (industryAlreadyExists) {
      return addToast({
        title: 'Industry already exists',
        type: 'info'
      });
    }

    try {
      setIsCreatingIndustry(true);
      const industry = await createIndustry(newIndustry);

      const industriesCopy = [...industries];

      industriesCopy.push(industry);
      setIndustries(industriesCopy);

      const allIndustriesCopy = [...allIndustries];
      allIndustriesCopy.push(industry);
      setAllIndustries(allIndustriesCopy);

      setIsCreatingIndustry(false);
      setNewIndustry('');

      addToast({
        title: 'Industry created',
        type: 'success'
      });
    } catch (error) {
      console.log(error);

      setIsCreatingIndustry(false);
      addToast({
        title: 'Error creating industry',
        description: 'Please, try again later',
        type: 'error'
      });
    }
  }, [industries, newIndustry, addToast, allIndustries, setAllIndustries]);

  return (
    <Container>
      {isCreatingIndustry && <Spinner message="Creating industry" />}
      {isLoadingIndustries && <Spinner message="Loading industries" />}

      <Helmet>
        <title>Create industries</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle heading="Create Industries" />
      </PageTitleWrapper>

      <IndustryCreation>
        <TextField
          name="industry"
          required
          fullWidth
          label="Industry"
          value={newIndustry}
          onChange={(e) => setNewIndustry(e.target.value)}
        />

        <button
          type="button"
          onClick={handleCreateIndustry}
          title="Create industry"
        >
          <Add fontSize="large" />
        </button>
      </IndustryCreation>

      <IndustriesContainer>
        {industries
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((industry) => (
            <IndustryCard key={industry.id}>
              <h3>{industry.name}</h3>

              <div>
                <p>Created at</p>
                <strong>
                  {formatDate({ date: industry.createdAt as any })}
                </strong>
              </div>
            </IndustryCard>
          ))}
      </IndustriesContainer>
    </Container>
  );
};
