import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px;
`;

export const IndustryCreation = styled.header`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 50%;

  @media (max-width: 700px) {
    width: 100%;
  }

  button {
    border: 0;
    border-radius: 7px;
    height: 53px;
    width: 53px;
    margin: 0 0 0 10px;
    cursor: pointer;
    background-color: #fff;
    border: 0.5px solid #bbb;

    transition: 0.1s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    svg {
      color: #0f0;
    }
  }
`;

export const IndustriesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 5px;

  margin-top: 24px;
  border-radius: 10px;
  background-color: #fff;
  padding: 5px;
`;

export const IndustryCard = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;

  background-color: rgba(0, 0, 0, 0.05);

  transition: transform 0.1s;

  &:hover {
    transform: scale(1.02);
  }

  div {
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: default;

    p {
      margin-right: 5px;
    }

    p,
    strong {
      font-size: 10px;
    }
  }
`;
