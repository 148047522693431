import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px;

  aside {
    display: flex;
    justify-content: center;
    align-items: center;

    height: fit-content;
  }

  p {
    margin: 0;
  }

  button {
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
  }

  svg {
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: #fff;
    }
  }
`;

export const DivisionCodesView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  p {
    margin: 0;
    height: fit-content;
  }

  button {
    margin: 0 0 0 10px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  svg {
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      filter: brightness(0.5);
    }
  }
`;
