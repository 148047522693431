import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
  useEffect
} from 'react';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import {
  ConfirmationResult,
  RecaptchaVerifier,
  browserLocalPersistence,
  getAuth,
  setPersistence
} from 'firebase/auth';

import { useToast } from './toast';
import { auth, firebaseFunctions, isProduction } from '../services/firebase';

import { User } from '../models/user';
import {
  checkIfUserIsActive,
  getUserData,
  resetUserPassword
} from '../functions/users';
import { checkIfOrganisationIsActive } from '../functions/organisations';

interface AuthState {
  user?: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface SignInPhoneCredentials {
  phone: string;
  appVerifier: RecaptchaVerifier;
}

interface AuthContextData {
  user?: User;
  loading: boolean;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): Promise<void>;
  updateUser(user: User): void;
  userIsLogged: boolean;
  setUpRecaptcha(containerRecaptcha: string): Promise<RecaptchaVerifier>;
  confirmOTPCodeAndSignIn(
    confirmationResult: ConfirmationResult,
    code: string
  ): Promise<boolean | undefined>;
  signInWithPhone(
    credentials: SignInPhoneCredentials
  ): Promise<ConfirmationResult>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({} as AuthState);

  const { addToast } = useToast();

  useEffect(() => {
    const authInstance = getAuth();
    const authUser = authInstance.currentUser;

    const storedUser = localStorage.getItem('@KyndDashboard:user');

    if (!authUser && storedUser) {
      localStorage.removeItem('@KyndDashboard:user');

      setData({} as AuthState);

      navigate('/');
    }
  }, [navigate]);

  const signIn = async ({ email, password }: SignInCredentials) => {
    setLoading(true);

    const superAdminEmails = [
      'livi@kyndwellness.com',
      'tom@drtomonamission.com',
      'tania@kyndwellness.com',
      'dee@kyndwellness.com',
      'jo.davies@kyndwellness.com'
    ];

    const trimmedEmail = email.trim();

    try {
      await setPersistence(auth, browserLocalPersistence);

      const { user } = await auth.signInWithEmailAndPassword(
        trimmedEmail,
        password
      );

      if (user) {
        const userData = await getUserData(user.uid);

        if (userData) {
          const changeUserCustomClaim = firebaseFunctions.httpsCallable(
            'changeUserCustomClaim'
          );

          changeUserCustomClaim(user.uid);

          const userLoggedIn = {
            id: user.uid,
            ...userData
          };

          const organisationIsActive = await checkIfOrganisationIsActive(
            userData.organisation.id
          );

          if (
            (userLoggedIn.role &&
              userLoggedIn.role !== 'Kynd Admin' &&
              userLoggedIn.role !== 'Org Admin' &&
              !superAdminEmails.includes(trimmedEmail)) ||
            !userLoggedIn.isActive ||
            !organisationIsActive
          ) {
            addToast({
              type: 'info',
              title: "You don't have permission to get in"
            });
          } else {
            localStorage.setItem(
              '@KyndDashboard:user',
              JSON.stringify(userLoggedIn)
            );

            setData({
              user: userLoggedIn
            });
          }
        }

        setLoading(false);
      }
    } catch (error) {
      const untypedError = error as any;
      if (untypedError.code === 'auth/wrong-password') {
        try {
          const checkIfUserIsMigrated = firebaseFunctions.httpsCallable(
            'checkIfUserIsMigrated'
          );

          const hasToSendRecoverPasswordEmail = await checkIfUserIsMigrated(
            trimmedEmail
          );

          if (hasToSendRecoverPasswordEmail.data) {
            await resetUserPassword(trimmedEmail);

            addToast({
              title: 'Welcome to the new version of KYND Wellness.',
              description:
                'We have sent you an e-mail to reset your password. It is required as an extra security layer.',
              type: 'info',
              duration: 30000
            });

            addToast({
              title:
                'If you have not received an e-mail, please go over in the Forgot Password section in the mobile app and try it there.',
              type: 'info',
              duration: 30000
            });
          } else {
            setLoading(false);
            addToast({
              title: 'Error signing in',
              description: 'Please, check your credentials',
              type: 'info'
            });
          }
        } catch (cloudFunctionError) {
          setLoading(false);
          console.log(cloudFunctionError);

          if (isProduction) {
            Sentry.captureException(cloudFunctionError);
          }

          addToast({
            title: 'Error signing in',
            description: 'Please, check your credentials',
            type: 'info'
          });
        }
      } else {
        addToast({
          title: 'Error signing in',
          description: 'Please, check your credentials',
          type: 'info'
        });
      }

      setLoading(false);
    }
  };

  const signOut = useCallback(async () => {
    localStorage.removeItem('@KyndDashboard:user');

    await auth.signOut();

    setData({} as AuthState);
    setLoading(false);
    navigate('/');
  }, [navigate]);

  useEffect(() => {
    async function checkUserActivity() {
      if (!data || !data.user || !data.user.id) return;

      const isUserActive = await checkIfUserIsActive(data.user.id);
      const isOrganisationActive = await checkIfOrganisationIsActive(
        data.user.organisation.id
      );

      if (
        typeof isUserActive === 'undefined' ||
        typeof isOrganisationActive === 'undefined'
      )
        return;

      if (!isUserActive || !isOrganisationActive) {
        addToast({
          title: 'Your subscription has ended',
          description: 'Your company no longer offers the app'
        });
        signOut();
      }
    }

    checkUserActivity();
  }, [data, addToast, signOut]);

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem('@KyndDashboard:user', JSON.stringify(user));

      setData({
        user
      });
    },
    [setData]
  );

  const userIsLogged = useMemo(() => {
    return Boolean(data.user && data.user.firstName);
  }, [data.user]);

  const setUpRecaptcha = async (
    containerRecaptcha: string
  ): Promise<RecaptchaVerifier> => {
    try {
      const recaptchaVerifier = new RecaptchaVerifier(
        containerRecaptcha,
        {
          size: 'invisible'
        },
        getAuth()
      );

      return recaptchaVerifier;
    } catch (error) {
      throw new Error('Recaptcha error');
    }
  };

  const signInWithPhone = useCallback(
    async ({ phone, appVerifier }: SignInPhoneCredentials): Promise<any> => {
      const getUserByPhone = firebaseFunctions.httpsCallable('getUserByPhone');

      let editedPhone = phone;

      if (phone[0] === '0') {
        editedPhone = phone.substring(1);
      }

      if (phone[0] !== '+' && phone[1] + phone[2] !== '64') {
        editedPhone = '+64' + editedPhone;
      }

      let userReturned: any;

      try {
        userReturned = await getUserByPhone(editedPhone);

        if (!userReturned.data) throw new Error();

        await setPersistence(auth, browserLocalPersistence);

        const trimmedPhone = editedPhone.trim();

        const confirmationResult = await auth.signInWithPhoneNumber(
          trimmedPhone,
          appVerifier
        );

        return confirmationResult;
      } catch (error) {
        console.log(error);
        setLoading(false);
        throw new Error('Phone number not found');
      }
    },
    []
  );

  const confirmOTPCodeAndSignIn = useCallback(
    async (confirmationResult: ConfirmationResult, code: string) => {
      try {
        setLoading(true);

        const { user } = await confirmationResult.confirm(code);

        if (user) {
          const userData = await getUserData(user.uid);

          if (
            (userData &&
              userData.role &&
              userData.role !== 'Kynd Admin' &&
              userData.role !== 'Org Admin') ||
            !userData?.isActive
          ) {
            addToast({
              type: 'info',
              title: "You don't have permission to get in"
            });
          } else {
            localStorage.setItem(
              '@KyndDashboard:user',
              JSON.stringify(userData)
            );

            setData({
              user: userData
            });
          }

          setLoading(false);
          return true;
        }
      } catch (error) {
        const typedError = error as Error;
        setLoading(false);
        throw new Error(typedError.message);
      }

      setLoading(false);
      return false;
    },
    [addToast]
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        loading,
        signIn,
        signOut,
        updateUser,
        userIsLogged,
        signInWithPhone,
        setUpRecaptcha,
        confirmOTPCodeAndSignIn
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}
