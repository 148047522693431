import firebase from 'firebase/compat/app';
import * as Sentry from '@sentry/react';
import { orderBy } from 'lodash';

import { Organisation, ManagerProps } from '../models/organisation';
import { Notification, OrganisationNotification } from '../models/notification';
import { firestore, isProduction } from '../services/firebase';

import {
  getUserData,
  toggleUsersSubscriptionFlag,
  updateUserRole
} from './users';
import { User } from '../models/user';
import { QuestionnaireHistory } from '../pages/Dashboard/components/HistoryChart';

const collectionOrganisations = firestore.collection('organisations');
const collectionOrganisationsScoresHistories = firestore.collection(
  'organisationsScoresHistories'
);
const collectionUsers = firestore.collection('users');

export const createOrganisation = async (org: Organisation) => {
  try {
    const organisation = await collectionOrganisations.add({
      name: org.name,
      address: org.address,
      phone: org.phone,
      isActive: org.isActive,
      managers: org.managers,
      employees: org.employees,
      divisions: org.divisions,
      industry: org.industry,
      scores: org.scores,
      notifications: org.notifications,
      createdAt: new Date()
    });

    return organisation;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const updateOrganisation = async (org: Organisation) => {
  try {
    const organisation = await collectionOrganisations.doc(org.id).update({
      name: org.name,
      address: org.address,
      phone: org.phone,
      isActive: org.isActive,
      managers: org.managers,
      employees: org.employees,
      divisions: org.divisions,
      industry: org.industry,
      scores: org.scores,
      notifications: org.notifications
    });

    return organisation;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const updateOrganisationAvailableQuestionnaires = async (
  orgId: string,
  questionnaires: string[]
) => {
  try {
    const organisation = await collectionOrganisations.doc(orgId).update({
      customQuestionnaires: questionnaires
    });

    return organisation;
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
    throw new Error('Fail to update organisation with custom questionnaires.');
  }
};

export const toggleOrganisationActivation = async (
  organisationId: string,
  status: boolean
) => {
  try {
    const organisation = await collectionOrganisations
      .doc(organisationId)
      .update({
        isActive: status
      });

    await toggleUsersSubscriptionFlag(status, organisationId);

    return organisation;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const getOrganisation = async (
  organisationId: string
): Promise<Organisation | undefined> => {
  try {
    const organisation = await collectionOrganisations
      .doc(organisationId)
      .get();

    return {
      id: organisationId,
      ...organisation.data()
    } as Organisation;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const getOrganisations = async () => {
  const organisationsFormatted: Organisation[] = [];

  try {
    const organisations = await collectionOrganisations.get();

    organisations.docs.forEach((organisation) => {
      const organisationData = organisation.data() as Omit<Organisation, 'id'>;

      organisationsFormatted.push({
        id: organisation.id,
        ...organisationData
      });
    });

    return orderBy(organisationsFormatted, ['name'], 'asc');
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const getOrganisationUsers = async (orgId: string) => {
  const usersFormatted: User[] = [];

  try {
    const users = await collectionUsers
      .where('organisation.id', '==', orgId)
      .get();

    users.docs.forEach((user) => {
      const userData = user.data() as Omit<User, 'id'>;

      usersFormatted.push({
        id: user.id,
        ...userData
      });
    });

    return usersFormatted;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const getDivisionUsers = async (divisionId: string) => {
  const usersFormatted: User[] = [];

  try {
    const users = await collectionUsers
      .where('division.id', '==', divisionId)
      .get();

    users.docs.forEach((user) => {
      const userData = user.data() as Omit<User, 'id'>;

      usersFormatted.push({
        id: user.id,
        ...userData
      });
    });

    return usersFormatted;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const getSearchedUsers = async (
  propToFilter: 'lowerFirstName' | 'lowerLastName',
  searchString: string,
  orgId: string
) => {
  const usersFormatted: User[] = [];

  const lowerString = searchString.toLowerCase();

  try {
    const users = await collectionUsers
      .where('organisation.id', '==', orgId)
      .where(propToFilter, '>=', lowerString)
      .where(propToFilter, '<=', lowerString + '\uf8ff')
      .get();

    users.docs.forEach((user) => {
      const userData = user.data() as Omit<User, 'id'>;

      usersFormatted.push({
        id: user.id,
        ...userData
      });
    });

    return usersFormatted;
  } catch (error) {
    console.log(error);
  }
};

export const getOrganisationQuestionnaireHistory = async (
  orgId: string,
  divisionId: string,
  questionnaire: string
) => {
  try {
    const historySnapshot = await collectionOrganisationsScoresHistories
      .doc(`${orgId}-${divisionId}-${questionnaire}`)
      .get();

    const history = historySnapshot.data() as QuestionnaireHistory | undefined;

    return history;
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
  }
};

export const addOrganisationManager = async (
  organisationId: string,
  manager: ManagerProps
) => {
  try {
    await collectionOrganisations.doc(organisationId).update({
      managers: firebase.firestore.FieldValue.arrayUnion(manager)
    });

    return manager;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const addEmployee = async (
  organisationId: string,
  employeeId: string
) => {
  try {
    await collectionOrganisations.doc(organisationId).update({
      employees: firebase.firestore.FieldValue.arrayUnion(employeeId)
    });

    return employeeId;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const removeOrganisationManager = async (
  organisationId: string,
  manager: ManagerProps
) => {
  try {
    await collectionOrganisations.doc(organisationId).update({
      managers: firebase.firestore.FieldValue.arrayRemove(manager)
    });

    const role = 'User';
    await updateUserRole(manager.id, role);

    const user = await getUserData(manager.id);

    await collectionOrganisations.doc(organisationId).update({
      employees: firebase.firestore.FieldValue.arrayRemove(user)
    });

    return manager;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

interface NotificationOrganisation extends Notification {
  color?: string[];
  measurement?: string;
}

export const addOrganisationNotification = async (
  organisationId: string,
  notification: Pick<
    NotificationOrganisation,
    'title' | 'body' | 'color' | 'measurement'
  >
): Promise<Omit<NotificationOrganisation, 'seenAt' | 'seen'>> => {
  try {
    const newNotification = {
      createdAt: new Date(),
      ...notification
    };

    await collectionOrganisations
      .doc(organisationId)
      .collection('notifications')
      .add(newNotification);

    return newNotification;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const getOrganisationNotifications = async (organisationId: string) => {
  try {
    const formattedNotifications = [] as OrganisationNotification[];

    const notificationsSnapshot = await collectionOrganisations
      .doc(organisationId)
      .collection('notifications')
      .orderBy('createdAt', 'desc')
      .limit(100)
      .get();

    const rawNotifications = notificationsSnapshot.docs;

    rawNotifications.map((notification) => {
      const notData = notification.data() as Omit<
        OrganisationNotification,
        'id'
      >;

      formattedNotifications.push({
        id: notification.id,
        ...notData
      });
    });

    return formattedNotifications;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const checkIfOrganisationIsActive = async (organisationId: string) => {
  try {
    const organisationSnaptshot = await collectionOrganisations
      .doc(organisationId)
      .get();
    const organisation = organisationSnaptshot.data() as Organisation;

    return organisation.isActive;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const changeOrganisationIndustry = async (
  orgId: string,
  industry: string
) => {
  try {
    await collectionOrganisations.doc(orgId).update({
      industry
    });

    return industry;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};
