import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import Markdown from 'markdown-to-jsx';
import { PageTitle } from '../../../components/PageTitle';
import { OrganisationFilter } from '../../../components/OrganisationFilter';
import { OrganisationNotification } from '../../../models/notification';
import { useAuth } from '../../../hooks/auth';
import { useOrganisation } from '../../../hooks/organisations';
import { formatDate, getUnixSeconds } from '../../../utils/formatDate';

import {
  Container,
  HeaderTopic,
  NotificationBody,
  NotificationHeader,
  NotificationItem,
  NotificationsWrapper
} from './styles';
import { getOrganisationNotifications } from '../../../functions/organisations';
import { Spinner } from '../../../components/Spinner';

export const ListNotifications: React.FC = () => {
  const { user } = useAuth();
  const { currentOrganisation, chosenOrganisation } = useOrganisation();

  const [isLoadingNotifications, setIsLoadingNotifications] = useState(true);
  const [notifications, setNotifications] = useState<
    OrganisationNotification[]
  >([]);

  useEffect(() => {
    if (chosenOrganisation === 'All') {
      setNotifications([]);
      return;
    }

    const getNotificationsAsync = async () => {
      setIsLoadingNotifications(true);

      const orgNotifications = await getOrganisationNotifications(
        currentOrganisation.id
      );

      const sortedNotifications = orgNotifications.sort((notA, notB) =>
        getUnixSeconds(notA.createdAt) > getUnixSeconds(notB.createdAt) ? -1 : 1
      );

      setNotifications(sortedNotifications);
      setIsLoadingNotifications(false);
    };

    getNotificationsAsync();
  }, [user, currentOrganisation, chosenOrganisation]);

  return (
    <Container>
      {isLoadingNotifications && <Spinner message="Fetching notifications" />}
      <OrganisationFilter hideDivision hideUserSearch />

      <Helmet>
        <title>Notifications</title>
      </Helmet>

      <PageTitle heading="Notifications" />
      {!notifications.length && <h2>No notifications yet</h2>}

      <NotificationsWrapper>
        {notifications.map((notification) => (
          <NotificationItem key={String(notification.createdAt)}>
            <NotificationHeader>
              <HeaderTopic>
                <strong>Sent on</strong>
                <p>
                  {formatDate({
                    date: notification.createdAt as any
                  })}
                </p>
              </HeaderTopic>

              {notification.measurement && (
                <HeaderTopic>
                  <strong>Measurement</strong>
                  <p>{notification.measurement}</p>
                </HeaderTopic>
              )}

              {notification.color && notification.color?.length > 0 && (
                <HeaderTopic>
                  <strong>Risk level</strong>
                  <p>{notification.color.join(', ')}</p>
                </HeaderTopic>
              )}

              <HeaderTopic>
                <strong>Title</strong>
                <p>{notification.title}</p>
              </HeaderTopic>
            </NotificationHeader>

            <NotificationBody>
              <Markdown>{notification.body}</Markdown>
            </NotificationBody>
          </NotificationItem>
        ))}
      </NotificationsWrapper>
    </Container>
  );
};
