import React, {
  FormEvent,
  useCallback,
  useEffect,
  useState,
  useRef
} from 'react';
import { useNavigate } from 'react-router-dom';

import {
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Container,
  Switch
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { useAuth } from '../../hooks/auth';

import Logo from '../../assets/logo.png';
import { useToast } from '../../hooks/toast';
import { LoginModeRow } from './styles';

interface FormLoginProps {
  email: string;
  password: string;
}

export const SignIn: React.FC = () => {
  const recaptchaWrapperRef = useRef<HTMLDivElement>({} as HTMLDivElement);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const {
    loading,
    signIn,
    userIsLogged,
    setUpRecaptcha,
    signInWithPhone,
    confirmOTPCodeAndSignIn
  } = useAuth();

  const [confirmationResult, setConfirmationResult] = useState<any>();
  const [showInputCode, setShowInputCode] = useState(false);
  const [loginMode, setLoginMode] = useState<'email' | 'phone'>('email');
  const [phone, setPhone] = useState('');
  const [otpCode, setOtpCode] = useState('');
  const [isSendingOtpCode, setIsSendingOtpCode] = useState(false);
  const [form, setForm] = useState<FormLoginProps>({
    email: '',
    password: ''
  });
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!userIsLogged) await signIn({ ...form });
  };

  useEffect(() => {
    if (userIsLogged) navigate('/dashboard');
  }, [userIsLogged, navigate]);

  useEffect(() => {
    const email = localStorage.getItem('@KyndDashboardEmail');
    const phoneStoraged = localStorage.getItem('@KyndDashboardPhone');

    if (email) {
      setForm({
        email,
        password: ''
      });
      setPhone(phoneStoraged || '');
      setRememberMe(true);
    }
  }, [rememberMe]);

  const handleRememberMe = useCallback(() => {
    if (rememberMe) {
      localStorage.removeItem('@KyndDashboardEmail');
      localStorage.removeItem('@KyndDashboardPhone');
    }

    setRememberMe(!rememberMe);
  }, [rememberMe]);

  useEffect(() => {
    if (rememberMe) {
      localStorage.setItem('@KyndDashboardEmail', form.email);
      localStorage.setItem('@KyndDashboardPhone', phone);
    }
  }, [form.email, phone, rememberMe]);

  const loginWithPhone = async (event: FormEvent): Promise<void> => {
    event.preventDefault();

    setIsSendingOtpCode(true);
    let appVerifier;
    if (!phone) return;

    try {
      appVerifier = await setUpRecaptcha('container-recaptcha');

      const confirmation = await signInWithPhone({
        phone,
        appVerifier
      });

      setConfirmationResult(confirmation);
      setIsSendingOtpCode(false);
      setShowInputCode(true);
    } catch (error) {
      setIsSendingOtpCode(false);
      console.log(error);
      if (appVerifier && recaptchaWrapperRef && recaptchaWrapperRef.current) {
        appVerifier.clear();
        recaptchaWrapperRef.current.innerHTML =
          '<div id="container-recaptcha" />';
      }

      addToast({
        type: 'error',
        title: 'Error signing in',
        description: 'Please, try again later'
      });
    }
  };

  const confirmCode = async (
    event: FormEvent
  ): Promise<boolean | undefined> => {
    event.preventDefault();
    if (!otpCode) return;

    try {
      await confirmOTPCodeAndSignIn(confirmationResult, otpCode);
    } catch (error) {
      const typedError = error as Error;
      console.log(error);
      addToast({
        type: 'error',
        title: 'Invalid OTP code',
        description: typedError.message
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <img src={Logo} alt="Logo KYND" />

        <h2>DASHBOARD ADMIN</h2>

        <Typography component="h1" variant="h5">
          Sign in
        </Typography>

        <LoginModeRow>
          <FormControlLabel
            sx={{ color: 'text.primary' }}
            control={
              <Switch
                checked={loginMode === 'email'}
                color="info"
                onChange={() =>
                  setLoginMode(loginMode === 'email' ? 'phone' : 'email')
                }
              />
            }
            label={`Login mode: ${loginMode.toUpperCase()}`}
          />
        </LoginModeRow>

        {loginMode === 'email' ? (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
              autoComplete="email"
              autoFocus
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={form.password}
              onChange={(e) => setForm({ ...form, password: e.target.value })}
              autoComplete="current-password"
            />

            <FormControlLabel
              checked={rememberMe}
              control={
                <Checkbox
                  checked={rememberMe}
                  color="primary"
                  onChange={handleRememberMe}
                />
              }
              label="Remember me"
            />

            <LoadingButton
              type="submit"
              fullWidth
              loading={loading}
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 3 }}
            >
              Sign In
            </LoadingButton>
            {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid> */}
          </Box>
        ) : !showInputCode ? (
          <Box
            component="form"
            onSubmit={loginWithPhone}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="phone"
              type="number"
              label="Phone number"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value.trim())}
              autoFocus
            />

            <FormControlLabel
              checked={rememberMe}
              control={
                <Checkbox
                  checked={rememberMe}
                  color="primary"
                  onChange={handleRememberMe}
                />
              }
              label="Remember me"
            />

            <LoadingButton
              type="submit"
              fullWidth
              loading={isSendingOtpCode}
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 3 }}
            >
              Send OTP Code
            </LoadingButton>
          </Box>
        ) : (
          <Box
            component="form"
            onSubmit={confirmCode}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="otp"
              type="number"
              label="OTP Code"
              name="otp"
              value={otpCode}
              onChange={(e) => setOtpCode(e.target.value.trim())}
              autoFocus
            />

            <LoadingButton
              type="submit"
              fullWidth
              loading={loading}
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 3 }}
            >
              Confirm code and sign in
            </LoadingButton>
          </Box>
        )}
      </Box>

      <div ref={recaptchaWrapperRef}>
        <div id="container-recaptcha" />
      </div>
    </Container>
  );
};
