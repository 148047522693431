import styled from 'styled-components';

export const Container = styled.main``;

export const ArticlePreviewBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopPreviewBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;

  p {
    font-size: 10px;
  }

  h3 {
    font-size: 17px;
    font-weight: 500;
    margin: 3px 0;
    font-style: normal;
    width: 80%;
  }

  span {
    font-size: 11px;
  }
`;

export const AuthorImageInPreview = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 20px;
`;

export const ImagePreviewBox = styled.div`
  width: 20%;
`;

export const TitlePreviewBox = styled.div`
  width: 80%;
  padding-left: 15px;
`;

export const CoverImageInPreview = styled.img`
  width: 100%;
  aspect-ratio: 16 / 6;
  object-fit: cover;
  object-position: 50% 20%;
  margin-top: 20px;
  border-radius: 5px;
`;

export const ArticlePreviewContent = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid #ecf8f7;
  border-radius: 5px;
  padding: 10px 10px 0px 10px;
  margin: 5px 0;
  align-items: center;

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 12px;
  }

  h6 {
    white-space: pre-line;
    text-align: justify;
  }

  img {
    width: 320px;
    height: fit-content;
    object-fit: contain;
    align-items: center;
  }

  textarea {
    border: 0;
    background-color: transparent;

    width: 100%;
    font-size: 10.72px;
    font-weight: 410;
    margin-bottom: 10px;
    text-align: justify;
    white-space: pre-line;
    line-height: 1rem;
  }

  .textareaTitle {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }

  transition: background-color 0.1s;

  &:hover {
    background-color: #33333311;
  }
`;

export const ArticlePreviewIcons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  padding: 2px;
  border-radius: 2px;
  margin: 2px 0;
  background-color: #11111111;

  div {
    height: 21px;
  }

  .left-buttons {
    margin-right: auto;
  }

  svg {
    cursor: pointer;
    border-radius: 2px;

    transition: background-color 0.1s;

    &:hover {
      background-color: #33333333;
    }
  }
`;
