import { ReactNode } from 'react';
import {
  HelpOutline,
  DesignServicesTwoTone,
  BrightnessLowTwoTone,
  ManageAccountsTwoTone,
  LocalMallTwoTone,
  NotificationsNoneTwoTone,
  VpnKey,
  SelfImprovementOutlined,
  PsychologyOutlined,
  SettingsAccessibilityOutlined,
  EmojiTransportationOutlined,
  DescriptionOutlined,
  Flag
} from '@mui/icons-material';
import { hasHearingVisionAlertsPermission } from './accessCheckers/hasHearingVisionAlertsPermission';
import { Organisation } from '../../../../models/organisation';
import { User } from '../../../../models/user';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  role?: string;
  hasPermission?: (
    currentUser: User | undefined,
    currentOrganisation: Organisation | undefined
  ) => boolean;
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'Dashboards',
    items: [
      {
        name: 'Dashboard',
        icon: DesignServicesTwoTone,
        link: '/dashboard'
      },
      {
        name: 'Body',
        icon: SettingsAccessibilityOutlined,
        link: '/dashboard/body'
      },
      {
        name: 'Mind',
        icon: PsychologyOutlined,
        link: '/dashboard/mind'
      },
      {
        name: 'Life',
        icon: SelfImprovementOutlined,
        link: '/dashboard/life'
      },
      {
        name: 'Users',
        link: '/users',
        icon: ManageAccountsTwoTone,
        role: 'Kynd Admin',
        items: [
          {
            name: 'Users',
            link: '/users'
          },
          {
            name: 'Create admin',
            link: '/users/create'
          },
          {
            name: 'Manage clinicians',
            link: '/users/clinicians'
          },
          {
            name: 'Change organisation',
            link: '/users/change-user-organisation'
          }
        ]
      },
      {
        name: 'Users',
        link: '/users',
        icon: ManageAccountsTwoTone,
        role: 'Org Admin'
      },
      {
        name: 'Organisations',
        link: '/organisations',
        role: 'Kynd Admin',
        icon: BrightnessLowTwoTone,
        items: [
          {
            name: 'All organisations',
            link: '/organisations'
          },
          {
            name: 'Create new',
            link: '/organisations/create'
          }
        ]
      },
      {
        name: 'Divisions',
        link: '/divisions',
        icon: LocalMallTwoTone,
        role: 'Org Admin'
      },
      {
        name: 'Create division',
        link: '/divisions/create',
        icon: LocalMallTwoTone,
        role: 'Kynd Admin'
      },
      {
        name: 'Create code',
        link: '/codes/create',
        icon: VpnKey,
        role: 'Kynd Admin'
      },
      {
        name: 'Codes',
        link: '/codes',
        icon: VpnKey,
        role: 'Org Admin'
      },
      {
        name: 'Notifications',
        icon: NotificationsNoneTwoTone,
        role: 'Kynd Admin',
        items: [
          {
            name: 'Notifications sent',
            link: 'notifications'
          },
          {
            name: 'Initial messages',
            link: 'notifications/initial'
          },
          {
            name: 'Create new',
            link: 'notifications/create'
          }
        ]
      },
      {
        name: 'Questions',
        icon: HelpOutline,
        role: 'Kynd Admin',
        link: 'questions'
      },
      {
        name: 'Hearing Vision Alerts',
        link: '/hearing-vision-alerts',
        icon: Flag,
        hasPermission: hasHearingVisionAlertsPermission
      },
      // {
      //   name: 'Create question',
      //   icon: HelpOutline,
      //   role: 'Kynd Admin',
      //   link: 'questions/create'
      // },
      {
        name: 'Industries',
        icon: EmojiTransportationOutlined,
        role: 'Kynd Admin',
        items: [
          {
            name: 'All industries',
            link: 'industries/all'
          },
          {
            name: 'Orgs industries',
            link: 'industries/orgs-industries'
          }
        ]
      },
      {
        name: 'Articles',
        icon: DescriptionOutlined,
        role: 'Kynd Admin',
        items: [
          {
            name: 'All articles',
            link: 'articles/all'
          },
          {
            name: 'Create new',
            link: 'article/create'
          }
        ]
      }
    ]
  }
];

export default menuItems;
