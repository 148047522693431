import { useState, useEffect, useMemo, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { cloneDeep, orderBy, set } from 'lodash';

import { TextField, Card, CardContent, Autocomplete } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { Column } from 'react-table';
import { Spinner } from '../../../components/Spinner';
import { Table } from '../../../components/Table';
import { Organisation } from '../../../models/organisation';

import { User } from '../../../models/user';
import { getClinicians, updateUser } from '../../../functions/users';

import { Container } from './styles';
import { PageTitle } from '../../../components/PageTitle';
import { useOrganisation } from '../../../hooks/organisations';

interface Rows {
  index: number;
  industry: string;
  name: string;
  manage: JSX.Element;
}

export const Clinicians: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allowedOrgs, setAllowedOrgs] = useState<Organisation[]>([]);
  const [notAllowedOrgs, setNotAllowedOrgs] = useState<Organisation[]>([]);
  const [clinicians, setClinicians] = useState([] as User[]);
  const [currentClinician, setCurrentClinician] = useState<User>();

  const { organisations } = useOrganisation();

  useEffect(() => {
    const getUsersAndOrgs = async () => {
      setIsLoading(true);
      try {
        const allUsers = await getClinicians();
        const cliniciansTemp = allUsers.filter(
          (user) => user.role === 'Clinician'
        );

        setClinicians(cliniciansTemp);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
      setIsLoading(false);
    };

    getUsersAndOrgs();
  }, []);

  const getCliniciansNames = () => {
    const names = clinicians.map((i: any) => {
      return `${i.firstName} ${i.lastName} - ${i.email}`;
    });
    return names;
  };

  const filterOrgs = useCallback(
    (clinicianName: string) => {
      const clinicianEmail = clinicianName.split(' ').slice(-1)[0];

      const chosenClinician = clinicians.find(
        (clinician) => clinician.email === clinicianEmail
      );

      if (!chosenClinician) return;

      const clinicianOrgsIds = chosenClinician.allowedOrganisationsIds;

      const allowedOrganisations = [] as Organisation[];
      const notAllowedOrganisations = [] as Organisation[];

      organisations.map((organisation) => {
        if (clinicianOrgsIds?.includes(organisation.id)) {
          allowedOrganisations.push(organisation);
        } else {
          notAllowedOrganisations.push(organisation);
        }
      });

      setAllowedOrgs(allowedOrganisations);
      setNotAllowedOrgs(notAllowedOrganisations);
    },
    [clinicians, organisations]
  );

  const handleUpdateClinician = useCallback(
    async (
      type: string,
      index: number,
      org: Organisation,
      updatedClinician: User
    ) => {
      setIsLoading(true);
      try {
        await updateUser(updatedClinician);
        if (type === 'Remove') {
          const allowedOrganisations = [...allowedOrgs];
          const notAllowedOrganisations = [...notAllowedOrgs];

          allowedOrganisations.splice(index, 1);
          notAllowedOrganisations.push(org);

          setAllowedOrgs(allowedOrganisations);
          setNotAllowedOrgs(notAllowedOrganisations);
        } else {
          const allowedOrganisations = [...allowedOrgs];
          const notAllowedOrganisations = [...notAllowedOrgs];

          allowedOrganisations.push(org);
          notAllowedOrganisations.splice(index, 1);

          setAllowedOrgs(allowedOrganisations);
          setNotAllowedOrgs(notAllowedOrganisations);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }

      setIsLoading(false);
    },
    [allowedOrgs, notAllowedOrgs]
  );

  const handleChangeClinician = (e: string) => {
    const clinicianEmail = e.split(' ').slice(-1)[0];
    const current = clinicians.filter((i) => i.email === clinicianEmail)[0];
    setCurrentClinician(current);
  };

  const Columns: Column<Rows>[] = useMemo(
    () => [
      {
        Header: `#`,
        accessor: 'index'
      },
      {
        Header: 'Industry',
        accessor: 'industry'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Manage',
        accessor: 'manage'
      }
    ],
    []
  );

  const allowedData = useMemo(() => {
    const orgsDataArray = [] as Rows[];
    allowedOrgs.map((organisation, index) => {
      const orgRow = {
        index: index + 1,
        industry: organisation.industry || '',
        name: organisation.name,
        manage: (
          <aside>
            <button
              type="button"
              title="Remove permission"
              onClick={() => {
                const idIndex =
                  currentClinician?.allowedOrganisationsIds?.indexOf(
                    organisation.id
                  );

                const copyClinician = cloneDeep(currentClinician!);
                copyClinician?.allowedOrganisationsIds?.splice(idIndex!, 1);

                setCurrentClinician(copyClinician);
                handleUpdateClinician(
                  'Remove',
                  index,
                  organisation,
                  copyClinician
                );
              }}
            >
              <Close color="error" />
            </button>
          </aside>
        )
      };

      return orgsDataArray.push(orgRow);
    });

    return orgsDataArray;
  }, [allowedOrgs, handleUpdateClinician, currentClinician]);

  const notAllowedData = useMemo(() => {
    const orgsDataArray = [] as Rows[];
    notAllowedOrgs.map((organisation, index) => {
      const orgRow = {
        index: index + 1,
        name: organisation.name,
        industry: organisation.industry || '',
        manage: (
          <aside>
            <button
              type="button"
              title="Add permission"
              onClick={() => {
                const copyClinician = cloneDeep(currentClinician!);

                if (!copyClinician.allowedOrganisationsIds) {
                  set(copyClinician, 'allowedOrganisationsIds', []);
                }

                copyClinician.allowedOrganisationsIds!.push(organisation.id);

                setCurrentClinician(copyClinician);
                handleUpdateClinician(
                  'Add',
                  index,
                  organisation,
                  copyClinician
                );
              }}
            >
              <Check color="success" />
            </button>
          </aside>
        )
      };

      return orgsDataArray.push(orgRow);
    });

    return orgsDataArray;
  }, [notAllowedOrgs, handleUpdateClinician, currentClinician]);

  return (
    <Container>
      <Helmet>
        <title>Clinicians</title>
      </Helmet>

      <PageTitle heading="Manage Clinicians" />

      <Card>
        <CardContent>
          <Autocomplete
            disablePortal
            id="industries-combo-box"
            options={getCliniciansNames()}
            freeSolo={false}
            disableClearable
            onChange={(event: any, newValue: any | null) => {
              handleChangeClinician(newValue);
              filterOrgs(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select clinician" />
            )}
          />
        </CardContent>
        {currentClinician && (
          <main style={{ padding: '24px' }}>
            <h2> Current permissions </h2>

            <Table
              columns={Columns}
              data={orderBy(allowedData, ['industry', 'name'], ['asc', 'asc'])}
            />

            <h2 style={{ marginTop: '48px' }}>
              Add permission to {currentClinician.firstName}{' '}
              {currentClinician.lastName}
            </h2>
            <Table
              columns={Columns}
              data={orderBy(
                notAllowedData,
                ['industry', 'name'],
                ['asc', 'asc']
              )}
            />
          </main>
        )}
      </Card>
      {isLoading && <Spinner />}
    </Container>
  );
};
