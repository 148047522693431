import * as Sentry from '@sentry/react';
import { firestore, isProduction } from '../services/firebase';

import { Question } from '../models/question';

const collectionQuestions = firestore.collection('questions');

export const createQuestion = async (data: Question) => {
  const {
    label,
    type,
    category,
    answers,
    section,
    questionnaire,
    unit,
    order,
    invertedAnswers,
    clinicianQuestion,
    icon
  } = data;

  try {
    const question = await collectionQuestions.add({
      category,
      section,
      questionnaire,
      label,
      type,
      unit,
      order,
      invertedAnswers,
      clinicianQuestion,
      icon,
      answers
    });

    return question;
  } catch (error) {
    throw new Error();
  }
};

export const updateQuestion = async (question: Question) => {
  const {
    id,
    label,
    type,
    category,
    answers,
    section,
    questionnaire,
    unit,
    order,
    invertedAnswers,
    clinicianQuestion,
    icon
  } = question;

  try {
    await collectionQuestions.doc(id).update({
      category,
      section,
      questionnaire,
      label,
      type,
      unit,
      order,
      invertedAnswers,
      clinicianQuestion,
      icon,
      answers
    });

    return question;
  } catch (error) {
    throw new Error();
  }
};

export const getQuestions = async () => {
  const questionsFormatted: Question[] = [];

  try {
    const questions = await collectionQuestions.get();

    questions.docs.forEach((question) => {
      const questionData = question.data() as Omit<Question, 'id'>;

      questionsFormatted.push({
        id: question.id,
        ...questionData
      });
    });

    return questionsFormatted;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const getQuestionData = async (
  questionId: string
): Promise<Question | undefined> => {
  try {
    const question = await collectionQuestions.doc(questionId).get();

    return question.data() as Question;
  } catch (error) {
    throw new Error();
  }
};

export const deleteQuestion = async (question: Question) => {
  try {
    await collectionQuestions.doc(question.id).delete();

    return true;
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
    return false;
  }
};
