import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px;

  aside {
    display: flex;
    justify-content: center;
    align-items: center;

    height: fit-content;
    width: fit-content;

    background-color: #fff;
    margin: 0 auto;
    border-radius: 50%;
  }

  p {
    margin: 0;
  }
`;
