import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import lodash from 'lodash';

import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { OrganisationFilter } from '../../../components/OrganisationFilter';
import { PageTitle } from '../../../components/PageTitle';
import { CategoryStats } from '../components/CategoryStats';
import { DataComparison } from '../components/DataComparison';

import { useOrganisation } from '../../../hooks/organisations';
import { useQuestions } from '../../../hooks/questions';
import { useAuth } from '../../../hooks/auth';

import { BlockData, Container } from '../styles';

export const Mind: React.FC = () => {
  const {
    isComparingOrganisations,
    currentOrganisation,
    chosenDivisionId,
    MINIMUM_USERS_TO_SEE_STATISTICS
  } = useOrganisation();
  const { mindQuestionnaires } = useQuestions();
  const { user } = useAuth();

  const { mind, averageScore } = useMemo(() => {
    const orgAverageScore = lodash.get(
      currentOrganisation,
      `scores.${chosenDivisionId}`
    );

    if (orgAverageScore) {
      return orgAverageScore;
    }

    return {
      mind: {},
      averageScore: {
        activeUsers: 0
      }
    };
  }, [currentOrganisation, chosenDivisionId]);

  return (
    <Container>
      <OrganisationFilter hideUserSearch showOrgsComparison />

      <Helmet>
        <title>Mind summary</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle heading="Mind summary" />
      </PageTitleWrapper>

      {averageScore.activeUsers > MINIMUM_USERS_TO_SEE_STATISTICS ||
      user?.role === 'Kynd Admin' ? (
        isComparingOrganisations ? (
          <DataComparison
            categoryData={mind}
            categoryQuestionnaires={mindQuestionnaires}
            category="mind"
          />
        ) : (
          <CategoryStats
            categoryData={mind}
            categoryQuestionnaires={mindQuestionnaires}
          />
        )
      ) : (
        <BlockData>
          You will be able to see statistics here when we gather data from at
          least {MINIMUM_USERS_TO_SEE_STATISTICS} users
        </BlockData>
      )}
    </Container>
  );
};
