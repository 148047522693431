/* eslint-disable */
export interface ContentProps {
  type: 'text' | 'video' | 'title';
  content: string;
}

export const aboutInfo = {
  life: [
    {
      type: 'video',
      content: 'https://vimeo.com/682771815/c336907d32'
    },
    {
      type: 'text',
      content: `
  KYND Life is a reflection of your social wellness or taha whānau. Social wellness refers to the relationships we have and how we interact with others. Social wellness involves building healthy, nurturing, and supportive relationships as well as fostering a genuine connection with those around you.\n \n
  Find out how your KYND life score stacks up from day to day, week to week. What makes it better and what makes it worse? Focus on areas you want to improve on. You can compare your KYND life score with friends, workmates and your loved one’s scores. See how improving one area influences others and improves your satisfaction with life.
      `
    }
  ],
  emotionalIndex: [
    {
      type: 'video',
      content: 'https://vimeo.com/682769092/64c9f2d26f'
    },
    {
      type: 'text',
      content: `
  Emotions are a normal and healthy part of the human experience. All emotions are valid, however sometimes our emotions can be harmful for us if we do not have the right tools to manage them well. Healthy Thinking is a set of tools developed by our KYND founder, Dr. Tom Mulholland. It is a tool that uses cognitive behavioral therapy, which essentially means changing our thoughts to then change our emotions. This section on the app gives you an indication on how well you may manage your emotions.\n \n
  Having healthy thoughts helps people manage their emotions to get better clarity and enjoyment out of life. You are able to control your emotions rather than them controlling you.\n \n
  Practicing healthy thinking means you know how thoughts influence your emotions and feelings. Using accurate thoughts that encourage you, instead of negative thoughts that discourage you, means you are better equipped to care for yourself and handle life's challenges.
      `
    }
  ],
  gratitude: [
    {
      type: 'text',
      content: `
  Feeling gratitude is a thankful appreciation for what we have or receive. With gratitude, people acknowledge the goodness in their lives.\n \n
  Research shows gratitude is strongly and consistently associated with greater happiness. Gratitude can help people feel more positive emotions, relish good experiences, improve their health, deal with adversity, and build strong relationships.
      `
    }
  ],
  mindfulness: [
    {
      type: 'text',
      content: `
  Mindfulness is the idea of learning how to be fully present in the moment and aware of your feelings and body sensations as you experience them, without distraction or judgment. Both science and experience has shown it has benefits for our health, happiness, work, and relationships. Mindfulness helps you notice your needs and the effect other people have on you.\n \n
  Mindfulness is great for helping reduce symptoms of stress, anxiety and depression. The more you practise being mindful, the more you benefit from it.
      `
    }
  ],
  sleep: [
    {
      type: 'video',
      content: 'https://vimeo.com/341724623'
    },
    {
      type: 'text',
      content: `
  Sleep is a basic human need and plays a vital role in our health and wellbeing.\n \n
  Sleep deficiency can lead to physical and mental health problems, injuries and a loss of productivity. Without enough sleep, the brain cannot function properly. This can impair your ability to concentrate, think clearly, and process memories.\n \n
  Healthy sleep also helps the body remain healthy and prevent disease. A lack of sleep has also been linked to obesity, type 2 diabetes, high blood pressure, heart disease, stroke. There are many health conditions and issues that can affect people's sleep. 
      `
    }
  ],
  obstructiveSleepApnea: [
    {
      type: 'text',
      content: `
  Obstructive Sleep Apnea (OSA) is a common sleep disorder that can have serious effects on health. OSA causes interruptions to breathing while you’re asleep. These interruptions can last for up to ten seconds or more and occur when the airway narrows so much that it closes and breathing stops. The brain reacts by briefly waking the person, causing the airways to reopen and the breathing to restart. This process can be repeated up to several hundred times during the night without the person being aware.\n \n
  Most people with OSA complain of daytime sleepiness which increases the likelihood of fatigue-related accidents. OSA can have a profound impact on all aspects of health and wellbeing.
      `
    }
  ],
  depression: [
    {
      type: 'text',
      content: `
  Depression has been said to be a cruel disease that can affect anyone. It results in a persistent feeling of sadness and loss of interest which can interfere with a person's day to day life and affect them not only mentally but physically as well. Depression is common and it is estimated that worldwide 5.0% of adults suffer from depression.\n \n
  Depression is different from short lived mood changes as a result of challenges in everyday life. When recurrent and with moderate or severe intensity, depression may become a serious health condition. It can cause the affected person to suffer greatly. Genetics and life experiences can contribute to your risk of depression. The good news is, there are many treatments for depression and it is important to get help from your doctor if you are suffering from depression. There is always a light at the end of the tunnel. 
      `
    }
  ],
  anxiety: [
    {
      type: 'text',
      content: `
  Anxiety is your body's natural response to stress and is a normal human emotion. Most people experience some anxiety when facing a new, unknown situation, a stressful event happens or something goes wrong in their life. However, some people find themselves worrying or feeling anxious so often that it interferes with their day-to-day life. This is a sign that you may have an anxiety disorder.\n \n
  Anxiety disorders are very common. Approximately 1 in 4 New Zealanders will be affected by an anxiety disorder at some stage in their lives. The exact cause of anxiety is not known, but it’s likely a combination of genetic and environmental factors, as well as brain chemistry. People with anxiety can experience negative effects around their feelings, thoughts, physical health and behaviour. 
      `
    }
  ],
  stress: [
    {
      type: 'text',
      content: `
  Stress is our body's response to pressure. It is often triggered when we experience something new, unexpected or that threatens our sense of self.\n \n
  Stress can be positive, keeping us alert, motivated and ready to avoid danger. But stress becomes a problem when it continues without relief or periods of relaxation.\n \n
  The body has an automatic built-in stress response, the “fight-or-flight response,” which helps the body face stressful situations. When a person experiences long-term stress, the continued response causes wear and tear on the body. Physical, emotional and behavioural symptoms can develop, a common one being high blood pressure increasing the risk of heart attack and stroke. You can’t avoid stress, but there are strategies you can use to stop it becoming overwhelming.
      `
    }
  ],
  depressionAnxietyAndStress: [
    {
      type: 'title',
      content: 'About DASS21'
    },
    {
      type: 'text',
      content: `
  Depression, Anxiety and Stress Scale (DASS 21) is a list of 21 questions designed to measure the severity of symptoms of depression, anxiety and stress in the individual completing it. When answering the DASS 21 questionnaire the individual is required to indicate the presence and frequency of a symptom over the previous week. \n \n
  The DASS 21 questionnaire not only measures the severity of symptoms, but provides a means to measure the effectiveness of any treatment used by the individual.
      `
    },
    {
      type: 'title',
      content: 'About Depression'
    },
    {
      type: 'video',
      content: 'https://vimeo.com/361230758/3b0b358da4'
    },
    {
      type: 'text',
      content: `
  Depression has been said to be a cruel disease that can affect anyone. It results in a persistent feeling of sadness and loss of interest which can interfere with a person's day to day life and affect them not only mentally but physically as well. Depression is common and it is estimated that worldwide 5.0% of adults suffer from depression.\n \n
  Depression is different from short lived mood changes as a result of challenges in everyday life. When recurrent and with moderate or severe intensity, depression may become a serious health condition. It can cause the affected person to suffer greatly. Genetics and life experiences can contribute to your risk of depression. The good news is, there are many treatments for depression and it is important to get help from your doctor if you are suffering from depression. There is always a light at the end of the tunnel. 
      `
    },
    {
      type: 'title',
      content: 'About Anxiety'
    },
    {
      type: 'video',
      content: 'https://vimeo.com/361229879/b250a3eeec'
    },
    {
      type: 'text',
      content: `
  Anxiety is your body's natural response to stress and is a normal human emotion. Most people experience some anxiety when facing a new, unknown situation, a stressful event happens or something goes wrong in their life. However, some people find themselves worrying or feeling anxious so often that it interferes with their day-to-day life. This is a sign that you may have an anxiety disorder.\n \n
  Anxiety disorders are very common. Approximately 1 in 4 New Zealanders will be affected by an anxiety disorder at some stage in their lives. The exact cause of anxiety is not known, but it’s likely a combination of genetic and environmental factors, as well as brain chemistry. People with anxiety can experience negative effects around their feelings, thoughts, physical health and behaviour. 
      `
    },
    {
      type: 'title',
      content: 'About Stress'
    },
    {
      type: 'video',
      content: 'https://vimeo.com/361230129/3d80ddd4e5'
    },
    {
      type: 'text',
      content: `
  Stress is our body's response to pressure. It is often triggered when we experience something new, unexpected or that threatens our sense of self.\n \n
  Stress can be positive, keeping us alert, motivated and ready to avoid danger. But stress becomes a problem when it continues without relief or periods of relaxation.\n \n
  The body has an automatic built-in stress response, the “fight-or-flight response,” which helps the body face stressful situations. When a person experiences long-term stress, the continued response causes wear and tear on the body. Physical, emotional and behavioural symptoms can develop, a common one being high blood pressure increasing the risk of heart attack and stroke. You can’t avoid stress, but there are strategies you can use to stop it becoming overwhelming.
      `
    }
  ],
  weight: [
    {
      type: 'text',
      content: `
  Weight can be a sensitive subject for many and in the past a lot of emphasis has been placed on that number on the scales! However, this number alone does not give a true indication of your health.\n \n
  Weight measures our whole body’s heaviness including our muscle. An athletic or strong person with a large muscle mass can have a heavier weight. At KYND we measure weight, but it’s more useful to know where our fat is stored. So now, waist circumference is measured. This is more important to use in assessing health risks linked to body fat. Reducing waist circumference to healthy levels is a better focus and it will naturally bring your weight down appropriately. 
      `
    }
  ],
  height: [
    {
      type: 'text',
      content: `
  Roughly 60 to 80 percent of your height is determined by genetics, while the rest depends on your environment. The most important nutrient for height is protein in childhood. Minerals like calcium, vitamins A and D also influence height. Both men and women may begin slipping in stature as early as age 40, decreasing by half an inch a decade. This is the result of the discs in the spine's vertebrae beginning to flatten and compress over time. Osteoporosis, a bone-weakening disease, can exacerbate the problem.
      `
    }
  ],
  waistCircumference: [
    {
      type: 'video',
      content: 'https://vimeo.com/682696560/0f44c631d3'
    },
    {
      type: 'text',
      content: `
  Obesity is linked to many health conditions and while it’s important to keep an eye on your weight, knowing where  the fat is stored is more important. \n \n
  Studies show that excess fat around your midsection or waist means you’re at higher risk for heart disease, type 2 diabetes, and other health conditions, compared to those who store fat in other parts of the body. 
      `
    }
  ],
  smoking: [
    {
      type: 'text',
      content: `
  Smoking is the result of a substance being burned and the smoke being inhaled to be tasted and absorbed into the bloodstream. Not only are there the well-known negative effects of smoking on your health, smoking can also cost you financially, socially and can affect the health of your loved ones and whānau.\n \n  
  Although smoking is an incredibly addictive habit that can be very hard to kick, quitting smoking is one of the best things you can do for yourself and your family. 
      `
    }
  ],
  alcohol: [
    {
      type: 'text',
      content: `
  Many people can enjoy an occasional drink without any problems, but binge drinking or drinking heavily over longer periods of time can have very serious consequences. Alcohol misuse not only harms the individual's health but also damages relationships and society in general in terms of violence and crime, accidents and drink-driving.
      `
    }
  ],
  systolic: [
    {
      type: 'text',
      content: `
  Systolic blood pressure is the pressure when the heart is contracting or squeezing to push blood through the blood vessels and around the body. It is the first reading in a blood pressure measurement. Both numbers are equally important in monitoring heart health. However, there is a greater risk of stroke and heart disease associated with high systolic pressures compared with that of high diastolic pressures. This is especially so in people ages 50 and older, which is why doctors tend to monitor the top number more closely. 
      `
    }
  ],
  diastolic: [
    {
      type: 'text',
      content: `
  The bottom number in a blood pressure measurement is known as the diastolic pressure. This is the pressure in the arteries when the heart is resting between beats. Diastolic blood pressure measurements usually peak when people are in their 40s to 60s and then it declines. It doesn't get as much attention as systolic pressure (when the heart is beating) because this is always higher and keeps increasing with age. While systolic and diastolic blood pressure are different, they both are important in determining health risks.
      `
    }
  ],
  systolicDiastolic: [
    {
      type: 'title',
      content: 'About Blood Pressure'
    },
    {
      type: 'video',
      content: 'https://vimeo.com/745242537'
    },
    {
      type: 'text',
      content: `
  Your blood pressure is the pressure of the blood pushing on the walls of your arteries. A blood pressure reading contains two numbers, like a fraction. The top number refers to the amount of pressure in the blood vessels when your heart is contracting or beating. This is called the systolic pressure. The bottom number refers to the pressure when your heart muscle is relaxed or between beats. This is known as the diastolic pressure.\n \n
  Both numbers are important in determining the state of your heart health. For most people an ideal blood pressure is 120/75 mmHg measured at rest. High blood pressure (hypertension) often has no warning signs or symptoms. Measuring your blood pressure is the only way to know whether you have high blood pressure. Raised blood pressure can damage the blood vessels, leading to a heart attack, stroke,  heart failure, eye problems or kidney disease.
      `
    },
    {
      type: 'title',
      content: 'Systolic Blood Pressure'
    },
    {
      type: 'text',
      content: `
  Systolic blood pressure is the pressure when the heart is contracting or squeezing to push blood through the blood vessels and around the body. It is the first reading in a blood pressure measurement. Both numbers are equally important in monitoring heart health. However, there is a greater risk of stroke and heart disease associated with high systolic pressures compared with that of high diastolic pressures. This is especially so in people ages 50 and older, which is why doctors tend to monitor the top number more closely. 
      `
    },
    {
      type: 'title',
      content: 'Diastolic Blood Pressure',
    },
    {
      type: 'text',
      content: `
  The bottom number in a blood pressure measurement is known as the diastolic pressure. This is the pressure in the arteries when the heart is resting between beats. Diastolic blood pressure measurements usually peak when people are in their 40s to 60s and then it declines. It doesn't get as much attention as systolic pressure (when the heart is beating) because this is always higher and keeps increasing with age. While systolic and diastolic blood pressure are different, they both are important in determining health risks.
      `
    }
  ],
  restingHeartRate: [
    {
      type: 'video',
      content: 'https://vimeo.com/682724209/fcf299fb93'
    },
    {
      type: 'text',
      content: `
  Your heart rate is the number of times your heart beats per minute. A heart rate at rest is between 60-100 beats per minute. When you exercise your heart pumps faster to deliver more oxygen to the muscles. Other factors can increase your heart rate, these include: emotions, especially anxiety or fear, stress, caffeine, nicotine, aging, family history, some prescription medicines and recreational drugs. A normal heart rate shows your heart is working well. If your heart is beating too slow or too fast, or the beat is irregular this may be the sign of a heart condition. Lower resting heart rates are associated with improved health and high levels of fitness.
      `
    }
  ],
  lungFunction: [
    {
      type: 'video',
      content: 'https://vimeo.com/697581156'
    },
    {
      type: 'text',
      content: `
  Measuring the amount of air you can breathe out during a forced breath is known as the Forced Expiratory Volume (FEV). The number after it indicates the time frame of the breath, ie FEV1 is the volume over 1 second. The meter compares your result with the result that it would expect for you. This means if you get a result of 95% you have a lung function 95% of that expected for a person of your age, gender, weight and height.\n \n
  If you have obstructed airways, the amount of air you’re able to blow quickly out of your lungs is reduced. The measurement is used to diagnose lung diseases that make it hard to breathe efficiently. This includes asthma and chronic obstructive pulmonary disease (COPD), the latter usually caused by smoking or air pollution. 
      `
    }
  ],
  totalCholesterol: [
    {
      type: 'video',
      content: 'https://vimeo.com/682732784/b130ddaa7a'
    },
    {
      type: 'text',
      content: `
  Cholesterol is a fatty substance made by our liver and found in some foods we eat. We often think of cholesterol as bad but we need cholesterol to make hormones, build cells, help with vitamin D synthesis, and make some of the substances required to digest food. So we need small amounts. In fact it is vital.\n \n
  However, too much of a good thing is not good when it comes to cholesterol; high levels in the blood can build up and form plaques. These plaques can clog and block arteries and cause a heart attack or stroke. This is where knowing your numbers is so important.
      `
    }
  ],
  hdl: [
    {
      type: 'video',
      content: 'https://vimeo.com/682738576/30ed8aa662'
    },
    {
      type: 'text',
      content: `
  HDL is the one lipid we measure where higher levels are good! HDL acts like a vacuum cleaner in the blood. It attaches to cholesterol and transports it to the liver where it is broken down and removed from the body. A healthy HDL level will help you have a good cholesterol ratio, as we measure how much cholesterol to HDL you have. Even if your cholesterol is a little high, if you have good levels of HDL your risk for heart disease lowers.
      `
    }
  ],
  ldl: [
    {
      type: 'video',
      content: 'https://vimeo.com/682740781/efcdfe7411'
    },
    {
      type: 'text',
      content: `
  LDL (Low Density Lipoprotein) cholesterol is known as the “bad” cholesterol. It carries cholesterol around the body in the blood but unlike HDL it doesn’t take it anywhere to be broken down and removed. It just keeps circulating in the blood vessels. If levels get too high it can collect on the walls of your arteries leading to "plaques" which can cause blockages and increase your risk for a heart attack or stroke.
      `
    }
  ],
  triglycerides: [
    {
      type: 'video',
      content: 'https://vimeo.com/682745054/69b2b795ba'
    },
    {
      type: 'text',
      content: `
  Triglycerides are a type of fat found in your blood. They are made from the foods we eat and if there is too much food the triglycerides are stored in fat cells in our body. They also provide us with energy. Triglycerides are the only lipid we measure where the result can be susceptible to food eaten the day of testing.\n \n
      `
    }
  ],
  ratioCholesterolHdl: [
    {
      type: 'video',
      content: 'https://vimeo.com/682758050/5ba1e34d17'
    },
    {
      type: 'text',
      content: `
  The cholesterol ratio is another measurement that can tell you whether you may need to take steps towards a healthier lifestyle. It is like a summary result.\n \n
  Your ratio compares how much cholesterol you have compared to that of heart protective HDL cholesterol. It gives a better indication of your overall risk of heart disease than cholesterol alone. You may have slightly high cholesterol but if HDL levels are great your ratio will be good. Higher levels of HDL help remove excess cholesterol. A healthy ratio means you have good levels of both, or one of the values is very good.
      `
    }
  ],
  hbA1C: [
    {
      type: 'video',
      content: 'https://vimeo.com/682761962/2ceac99c77'
    },
    {
      type: 'text',
      content: `
  The HbA1c test measures your average blood glucose (or sugar) levels over the last three months. It’s different to a blood glucose reading which measures blood sugar at that moment in time.\n \n
  The Hb stands for Haemoglobin, a protein in the blood that carries oxygen to our muscles. The sugar binds or “sticks” to the haemoglobin. The higher the HbA1c the more sugar is stuck to the red blood cell. Type 2 diabetes and pre-diabetes can be helped with a commitment to healthy eating, regular exercise and keeping body fat levels in the healthy range.\n \n      
  Left untreated the sugary or “sticky” blood can cause all sorts of health issues as it affects circulation. Preventing or reversing type 2 diabetes by knowing your numbers and making changes if needed to prevent serious conditions such as nerve damage, kidney disease, eye damage and heart disease. 
      `
    }
  ],
  hearingSurvey: [
    {
      type: 'text',
      content: `
Too much noise can permanently damage hearing.\n\n
So, the foreman, or whoever is in charge, should be regularly checking how much noise workers are exposed to. This includes when something changes, like a new machine coming on to the worksite.\n\n
Reducing noise at source, for example by repairing damaged mufflers or choosing quieter equipment, is the best way to protect people's hearing. Earmuffs are secondary protection.\n\n
If someone's been exposed to a lot of noise, a hearing test should be done to see if they have hearing loss.
      `
    }
  ],
  noiseLevelMonitor: [
    {
      type: 'text',
      content: `
A person controlling a worksite must ensure workers (and people visiting the worksite) are not exposed to noise levels:\n \n
  - Equivalent to 85 decibels averaged over 8 hours, or
  - A peak noise level above 140 decibels.\n \n
These are NZ standards and always apply, regardless of whether hearing protection is worn.\n \n
The Sound Meter app is a tool that measures noise levels.\n\n
For Android use the below link:
<a target="_blank" href="https://play.google.com/store/apps/details?id=com.gamebasic.decibel&hl=pt_BR&gl=US">Sound Meter</a>\n\n
For iOS use the below link:
<a target="_blank" href="https://apps.apple.com/us/app/niosh-sound-level-meter/id1096545820">NIOSH Sound Level Meter</a>\n\n
After installation, open the App.\n\n
The current noise level (in decibels) is displayed.
The graph below shows the level of noise over time and the Minimum, Average and Maximum Levels for the monitoring period.\n\n
Take note of the Average and Total time (sec).\n\n
Go back to the KYND app.
Inside Noise Level Monitor select ADD NEW then Get Started.\n\n
Add your Work Task.
Add the Average and Maximum Noise Level (dB) value.
Add the monitoring time period (sec).`
    }
  ],
  eyesightSurvey: [
    {
      type: 'text',
      content: `
The Eyesight Survey helps you find out if problems with your eyes are affecting you at work.\n\n
Our eyesight can change as we get older. So, it's a good idea to get your eyes tested as part of your annual health check.\n\n
If you're worried your eyesight could be affecting your ability to work safely, talk to your boss or doctor.
      `
    }
  ],
  hearingCheck: [
    {
      type: 'text',
      content: `
  We depend on good hearing for our safety and everyday living. Too much noise can permanently damage hearing. Checks should be made on how much noise workers are exposed to, especially when something changes, like a new machine being used on site.\n \n
  Use the link on the KYND app to determine if the noise levels at home or work could be hazardous. If someone’s been exposed to a lot of noise, a hearing test should be done to see if they have hearing loss.
      `
    }
  ],
  visionCheck: [
    {
      type: 'text',
      content: `
  Good vision is a key factor for living a healthy, happy life. The earlier we start looking after the health of our eyes, the better our chance of maintaining it. From age 40 onwards, it’s normal to experience changes in your vision. You are also at much higher risk of certain eye conditions; some progressing without your knowledge.\n \n
  Colour blindness is a condition you are born with. Up to 1 in 3 people with colour blindness go undiagnosed due to not being tested. If you're worried your eyesight could be affecting your ability to work safely, talk to your doctor.
      `
    }
  ],
  structuralHealth: [
    {
      type: 'text',
      content: 'A series of tests may be undertaken as part of your health screen to assess this aspect of your health. These tests determine whether your muscles and joints are functioning at a level that allows you to perform your work safely, and to reduce your chance of injury. The tests are designed to test the following:'
    },
    {
      type: 'title',
      content: 'Differential %'
    },
    {
      type: 'text',
      content: 'This is the difference between how the left and right side of the body work. Large differences in strength, range of movement or position can result in negative effects on tissues and joints, and potential injury.'
    },
    {
      type: 'title',
      content: 'ROM - Range of movement'
    },
    {
      type: 'text',
      content: 'Is how far you can move a limb through a particular action. Being able to move too much is considered hyper-mobile, and can result in poor joint control. Too little range of movement is considered immobile; this can prevent you from the movement required to perform your job safely.'
    },
    {
      type: 'title',
      content: 'Force production'
    },
    {
      type: 'text',
      content: 'This is a measure of your strength.  By testing your body in positions that are associated with a high risk of injury, we can check if you have enough strength for these movements.'
    },
    {
      type: 'title',
      content: 'Functional assessment'
    },
    {
      type: 'text',
      content: 'Involves checking an action involving several joints in the body e.g. squats, push up, hops. These movements are used to assess how much power you produce in relation to your body weight. Both sides of the body should have a similar power output.'
    },
    {
      type: 'title',
      content: 'Bias'
    },
    {
      type: 'text',
      content: 'A test using a fixed position e.g standing posture, single leg balance. This is of less importance than loss of range of movement, or force production imbalances. It provides a baseline to measure changes in posture across time.'
    }
  ]
};
