import 'dotenv/config';
import { useRoutes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';

import GlobalStyle from './styles/global';
import { ThemeProviderWrapper } from './styles/theme/ThemeProvider';
import { AppProvider } from './hooks';
import { Routes } from './routes';

export const App: React.FC = () => {
  const content = useRoutes(Routes());

  return (
    <ThemeProviderWrapper>
      <AppProvider>
        <CssBaseline />
        {content}
      </AppProvider>

      <GlobalStyle />
    </ThemeProviderWrapper>
  );
};
