import styled from 'styled-components';

interface ImageProps {
  isIncluded: boolean;
}

export const Wrapper = styled.div`
  padding: 40px;

  .authorPhotoEntry {
    display: none;
  }
`;

export const BackButton = styled.button`
  background: #33333333;
  border: none;
  border-radius: 10px;

  padding: 10px;
  height: 40px;
  width: fit-content;
  margin-bottom: 10px;

  transition: background 0.2s;

  &:hover {
    background: #33333355;
  }
`;

export const FieldDescription = styled.span`
  font-size: 9px;
  margin-left: 5px;
  color: #333333aa;
`;

export const ImageBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 7px;

  border: 2px solid #6e759f;
  border-radius: 12px;
  padding: 7px;
`;

export const Figure = styled.figure`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #6e759f10;
  border-radius: 6px;
  padding: 7px 7px 0 7px;

  .buttonSizeImageControl {
    margin-top: 5px;
    height: 20px;
  }

  img {
    width: 100%;
    height: 100px;
    object-fit: contain;
    border-radius: 6px;
  }

  div svg {
    cursor: pointer;
  }
`;

export const ImageArticle = styled.img<ImageProps>`
  opacity: ${(props) => (props.isIncluded ? 0.3 : 1)};
`;

export const BoxCropPicture = styled.main`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 10px;
  border: 2px solid #12a79e;
  border-radius: 10px;

  max-height: 90vh;
  max-width: 700px;

  height: 400px;
  background-color: white;

  box-shadow: 5px;

  .crop-container {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 80px;
  }

  .controls {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 80%;
    height: 80px;
    transform: translateX(-50%);
    align-items: center;
  }

  .slider {
    padding: 22px 0px;
  }
`;
