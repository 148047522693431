import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Autocomplete,
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  ListItemText,
  Select,
  Checkbox
} from '@mui/material';
import { WhereFilterOp } from 'firebase/firestore';

import { OrganisationFilter } from '../../../components/OrganisationFilter';
import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { PageTitle } from '../../../components/PageTitle';
import { Spinner } from '../../../components/Spinner';
import { getQuestions } from '../../../functions/questions';
import { findUsersToNotification } from '../../../functions/users';
import { addOrganisationNotification } from '../../../functions/organisations';
import { firebaseFunctions } from '../../../services/firebase';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { useOrganisation } from '../../../hooks/organisations';
import { groupsQuestionnaire } from '../../../utils/groupsQuestionnaire';

import { Wrapper } from './styles';

const colorsNames = ['red', 'orange', 'green'];

interface Query {
  fieldPath: string;
  opStr: WhereFilterOp;
  value: string | number | string[];
}

export const CreateNotification: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { currentOrganisation, chosenOrganisation } = useOrganisation();

  const [newNotification, setNewNotification] = useState({
    type: 'general',
    color: [] as string[],
    measurement: '',
    subject: '',
    text: ''
  });
  const [measurements, setMeasurements] = useState<string[]>([]);
  const [querys, setQuerys] = useState<Query[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      const organisationId = currentOrganisation.id;

      if (chosenOrganisation === 'All' || chosenOrganisation === 'all') {
        return addToast({
          title: 'Please, choose an organisation',
          type: 'info'
        });
      }

      if (!newNotification.subject || !newNotification.text) {
        return;
      }

      if (newNotification.type !== 'general') {
        if (newNotification.measurement === '' || !newNotification.color) {
          return;
        }
      }

      const formattedText = newNotification.text.replace(
        /(?:\r\n|\r|\n)/g,
        ' <br/> '
      );

      setIsLoading(true);

      const tokens: string[] = [];

      try {
        const sendNotification =
          firebaseFunctions.httpsCallable('sendNotification');

        const { usersToNotification, usersToRegisterNotification } =
          await findUsersToNotification(querys as Query[]);

        const registerUsersNotifications = firebaseFunctions.httpsCallable(
          'registerUsersNotifications'
        );

        const userNotification = {
          title: newNotification.subject,
          body: formattedText
        };

        if (usersToRegisterNotification.length) {
          registerUsersNotifications({
            usersIds: usersToRegisterNotification,
            notification: userNotification
          });
        }

        await addOrganisationNotification(organisationId, {
          title: newNotification.subject,
          body: formattedText,
          color: newNotification.color,
          measurement: newNotification.measurement
        });

        if (usersToNotification.length > 0) {
          usersToNotification.forEach(({ token }) => {
            if (token) tokens.push(token);
          });

          const message = {
            notification: {
              title: newNotification.subject,
              body: newNotification.text
            },
            tokens
          };

          sendNotification(message);
        }

        addToast({
          title: 'Message sent',
          type: 'success'
        });

        setIsLoading(false);
      } catch (error) {
        console.log(error);
        addToast({
          title: 'Error sending message',
          description: 'Please, try again later',
          type: 'error'
        });
        setIsLoading(false);
      }
    },
    [chosenOrganisation, currentOrganisation, addToast, newNotification, querys]
  );

  const getMeasurements = async () => {
    const forbiddenQuestionnaires = [
      'Weight',
      'Height',
      'Hearing Survey',
      'Hearing Check',
      'Vision Check',
      'Eyesight Survey',
      'Noise Level Monitor'
    ];

    const measurementsTemp: string[] = [];
    const response = await getQuestions();
    response.forEach((e) => {
      if (forbiddenQuestionnaires.includes(e.questionnaire)) return;

      if (!measurementsTemp.includes(e.questionnaire)) {
        measurementsTemp.push(e.questionnaire);
      }
    });

    measurementsTemp.sort((a, b) => (a > b ? 1 : -1));

    setMeasurements(measurementsTemp);
  };

  useEffect(() => {
    getMeasurements();
  }, []);

  useEffect(() => {
    if (newNotification.type === 'general') {
      setQuerys([
        {
          fieldPath: 'organisation.id',
          opStr: '==',
          value: currentOrganisation.id
        }
      ]);
    }

    if (newNotification.type !== 'general') {
      const groupPath = groupsQuestionnaire.filter((group: any) => {
        return group.questionnaire === newNotification.measurement;
      })[0];

      setQuerys([
        {
          fieldPath: 'organisation.id',
          opStr: '==',
          value: currentOrganisation.id
        }
      ]);

      if (newNotification.measurement && newNotification.color) {
        setQuerys([
          {
            fieldPath: 'organisation.id',
            opStr: '==',
            value: currentOrganisation.id
          },
          {
            fieldPath: `${groupPath.fieldPath}`,
            opStr: 'in',
            value: newNotification.color
          }
        ]);
      }
    }
  }, [
    user,
    newNotification.type,
    newNotification.measurement,
    newNotification.color,
    currentOrganisation
  ]);

  return (
    <Wrapper>
      <OrganisationFilter hideDivision hideUserSearch />

      <Helmet>
        <title>Notifications - Create new</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle heading="Create notification" />
      </PageTitleWrapper>

      <div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <Card>
                <CardContent>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 1, pl: 1, pr: 1 }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          select
                          label="Type"
                          value={newNotification.type}
                          required
                          onChange={(e) =>
                            setNewNotification({
                              ...newNotification,
                              type: e.target.value
                            })
                          }
                        >
                          <MenuItem key="general" value="general">
                            General (everyone in the organisation)
                          </MenuItem>
                          <MenuItem key="risk-level" value="risk-level">
                            Risk Level (specific group in the organisation)
                          </MenuItem>
                        </TextField>
                      </Grid>

                      {newNotification.type !== 'general' && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                              <InputLabel>Color indicators *</InputLabel>
                              <Select
                                label="Color indicators"
                                placeholder="Color indicators"
                                fullWidth
                                required
                                multiple
                                value={newNotification.color}
                                onChange={(e) => {
                                  const {
                                    target: { value }
                                  } = e;
                                  setNewNotification({
                                    ...newNotification,
                                    color:
                                      typeof value === 'string'
                                        ? value.split(',')
                                        : value
                                  });
                                }}
                                input={
                                  <OutlinedInput label="Color indicators *" />
                                }
                                renderValue={(selected) => selected.join(', ')}
                              >
                                {colorsNames.map((color) => (
                                  <MenuItem key={color} value={color}>
                                    <Checkbox
                                      checked={
                                        newNotification.color.indexOf(color) >
                                        -1
                                      }
                                    />
                                    <ListItemText primary={color} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Autocomplete
                              options={measurements}
                              disableClearable
                              onChange={(e) =>
                                setNewNotification({
                                  ...newNotification,
                                  measurement: e.currentTarget.innerHTML
                                })
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  fullWidth
                                  label="Measurements"
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="subject"
                          required
                          fullWidth
                          id="subject"
                          label="Subject"
                          value={newNotification.subject}
                          onChange={(e) =>
                            setNewNotification({
                              ...newNotification,
                              subject: e.target.value
                            })
                          }
                          autoFocus
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Message"
                          fullWidth
                          required
                          multiline
                          rows={4}
                          value={newNotification.text}
                          onChange={(e) =>
                            setNewNotification({
                              ...newNotification,
                              text: e.target.value
                            })
                          }
                        />
                      </Grid>
                    </Grid>

                    <Button
                      type="submit"
                      variant="contained"
                      style={{ color: '#fff', marginTop: '16px' }}
                    >
                      Send message
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </div>

      {isLoading && <Spinner message="Sending message..." />}
    </Wrapper>
  );
};
