import firebase from 'firebase/compat/app';
import * as Sentry from '@sentry/react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { WhereFilterOp, DocumentData, writeBatch } from 'firebase/firestore';

import { User } from '../models/user';
import { Notification } from '../models/notification';
import {
  auth,
  firebaseFunctions,
  firestore,
  isProduction,
  subscriptionOrgId
} from '../services/firebase';

import { addOrganisationManager, getOrganisation } from './organisations';
import { Organisation } from '../models/organisation';

const collectionUsers = firestore.collection('users');
const collectionOrganisations = firestore.collection('organisations');

export const createUser = async (data: User) => {
  const { firstName, lastName, email, password, organisation, role } = data;

  try {
    const { user } = await auth.createUserWithEmailAndPassword(
      email!,
      password || ''
    );

    if (user) {
      // eslint-disable-next-line no-param-reassign
      delete data.password;
      await collectionUsers.doc(user.uid).set(data);

      if (role === 'Org Admin' && organisation.id) {
        const manager = { id: user.uid, name: firstName + ' ' + lastName };
        await addOrganisationManager(organisation.id, manager);
      }
    }

    // eslint-disable-next-line consistent-return
    return user || undefined;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const resetUserPassword = async (userEmail: string) => {
  try {
    const firebaseAuth = getAuth();

    await sendPasswordResetEmail(firebaseAuth, userEmail);

    return userEmail;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const updateUser = async (user: User) => {
  try {
    await collectionUsers.doc(user.id).set(user, { merge: true });

    return user;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const getUsers = async (
  filterProp: string,
  filterType: WhereFilterOp,
  filterValue: string
) => {
  const usersFormatted: User[] = [];

  try {
    const users = await collectionUsers
      .where(filterProp, filterType, filterValue)
      .get();

    users.docs.forEach((user) => {
      const userData = user.data() as Omit<User, 'id'>;

      usersFormatted.push({
        id: user.id,
        ...userData
      });
    });

    return usersFormatted;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const getClinicians = async () => {
  const usersFormatted: User[] = [];

  try {
    const users = await collectionUsers.where('role', '==', 'Clinician').get();

    users.docs.forEach((user) => {
      const userData = user.data() as Omit<User, 'id'>;

      usersFormatted.push({
        id: user.id,
        ...userData
      });
    });

    return usersFormatted;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const getUserData = async (id: string): Promise<User | undefined> => {
  try {
    const user = await collectionUsers.doc(id).get();

    return user.data() as User;
  } catch (error) {
    const typedError = error as Error;
    throw new Error(typedError.message);
  }
};

export const toggleUserActivation = async (
  user: User,
  status: boolean,
  removeFromOrg: boolean
) => {
  try {
    const userId = user.id!;
    const userOrgId = user.organisation.id;

    if (removeFromOrg) {
      await collectionUsers.doc(userId).update({
        isActive: true,
        organisation: {
          id: subscriptionOrgId,
          name: 'Subscriptions'
        },
        isSubscriptionUser: true
      });

      collectionOrganisations.doc(userOrgId).update({
        employees: firebase.firestore.FieldValue.arrayRemove(userId)
      });

      collectionOrganisations.doc(subscriptionOrgId).update({
        employees: firebase.firestore.FieldValue.arrayUnion(userId)
      });
    } else {
      await collectionUsers
        .doc(userId)
        .update({ isActive: status, isSubscriptionUser: !status });
    }

    const scheduleOrgScoreCalculation = firebaseFunctions.httpsCallable(
      'scheduleOrgScoreCalculation'
    );

    scheduleOrgScoreCalculation({
      organisationId: user.organisation.id,
      divisionId: user.division.id
    });

    return user;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const updateUserRole = async (userId: string, role: string) => {
  try {
    const user = await collectionUsers.doc(userId).update({ role });

    return user;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

interface UserDivision {
  id: string;
  name: string;
}

export const updateUserDivision = async (
  user: User,
  division: UserDivision
) => {
  try {
    const userId = user.id!;

    await collectionUsers.doc(userId).update({ division });

    const scheduleOrgScoreCalculation = firebaseFunctions.httpsCallable(
      'scheduleOrgScoreCalculation'
    );

    scheduleOrgScoreCalculation({
      organisationId: user.organisation.id,
      divisionId: user.division.id
    });

    scheduleOrgScoreCalculation({
      organisationId: user.organisation.id,
      divisionId: division.id
    });

    return user;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

interface Query {
  fieldPath: string;
  opStr: WhereFilterOp;
  value: string | number | string[];
}

export const findUsersToNotification = async (querys: Query[]) => {
  const usersFormatted: Pick<User, 'id' | 'token'>[] = [];
  const usersToRegisterNotification: Pick<User, 'id' | 'token'>[] = [];

  try {
    let queryCollectionUser: DocumentData = firestore.collection('users');

    querys.forEach((query: Query) => {
      queryCollectionUser = queryCollectionUser.where(
        query.fieldPath,
        query.opStr,
        query.value
      );
    });

    const users = await queryCollectionUser.get();

    users.docs.forEach((user: any) => {
      const { token, isReceivingPushNotifications } = user.data() as User;

      usersToRegisterNotification.push(user.id);

      if (token && isReceivingPushNotifications) {
        usersFormatted.push({
          id: user.id,
          token
        });
      }
    });

    return {
      usersToNotification: usersFormatted,
      usersToRegisterNotification
    };
  } catch (error) {
    throw new Error();
  }
};

export const addUserNotification = async (
  userId: string,
  notification: Pick<Notification, 'title' | 'body'>
): Promise<Omit<Notification, 'seenAt'>> => {
  try {
    const newNotification = {
      seen: false,
      createdAt: new Date(),
      ...notification
    };

    await collectionUsers
      .doc(userId)
      .collection('notifications')
      .add(newNotification);

    return newNotification;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const checkIfUserIsActive = async (userId: string) => {
  try {
    const userSnaptshot = await collectionUsers.doc(userId).get();
    const user = userSnaptshot.data() as User;

    return user.isActive;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

interface ChangeUserOrganisationProps {
  userId: string;
  oldOrgId: string;
  oldDivisionId: string;
  newOrgName: string;
  newOrgId: string;
  newDivisionName: string;
  newDivisionId: string;
}

export const changeUserOrganisation = async ({
  userId,
  oldOrgId,
  oldDivisionId,
  newOrgName,
  newOrgId,
  newDivisionName,
  newDivisionId
}: ChangeUserOrganisationProps) => {
  try {
    await collectionUsers.doc(userId).update({
      organisation: {
        id: newOrgId,
        name: newOrgName
      },
      division: {
        id: newDivisionId,
        name: newDivisionName
      }
    });

    await collectionOrganisations.doc(newOrgId).update({
      employees: firebase.firestore.FieldValue.arrayUnion(userId)
    });

    await collectionOrganisations.doc(oldOrgId).update({
      employees: firebase.firestore.FieldValue.arrayRemove(userId)
    });

    const scheduleOrgScoreCalculation = firebaseFunctions.httpsCallable(
      'scheduleOrgScoreCalculation'
    );

    scheduleOrgScoreCalculation({
      organisationId: oldOrgId,
      divisionId: oldDivisionId
    });

    scheduleOrgScoreCalculation({
      organisationId: newOrgId,
      divisionId: newDivisionId
    });
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
  }
};

export const toggleUsersSubscriptionFlag = async (
  toggleValue: boolean,
  organisationId: string
) => {
  // If org is inactive, users must be avaible for subscription
  // So org isActive: false = isSubscriptionUser: true, thus !toggleValue
  const updateObject = { isSubscriptionUser: !toggleValue };

  const organisationData =
    (await getOrganisation(organisationId)) ?? ({} as Organisation);
  const { employees } = organisationData;

  let batch = writeBatch(firestore);
  let batchCount = 0;

  try {
    // eslint-disable-next-line no-restricted-syntax
    for (const element of employees) {
      const userId = element;

      const userRef = collectionUsers.doc(userId) as any;

      batch.update(userRef, updateObject);
      batchCount += 1;

      if (batchCount >= 450) {
        // eslint-disable-next-line no-await-in-loop
        await batch.commit();
        batch = writeBatch(firestore);
        batchCount = 0;
      }
    }

    await batch.commit();
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
  }
};

export const toggleUserSubscriptionFlag = async (
  userId: string,
  newSubscriptionStatus: boolean
) => {
  try {
    await collectionUsers
      .doc(userId)
      .update({ isSubscriptionUser: newSubscriptionStatus });
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
  }
};
