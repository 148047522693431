import { orderBy } from 'lodash';

export const isCustomQuestionnaireUpdated = (
  organisationQuestionnaires: string[] | undefined = [],
  newQuestionnaires: string[] | undefined = []
) => {
  const list1 = orderBy(organisationQuestionnaires);
  const list2 = orderBy(newQuestionnaires);

  return JSON.stringify(list1) === JSON.stringify(list2);
};
