export default {
  apiKey: 'AIzaSyAfv0UHfNyH13chjmjBhVK4UaFqFg2B-VY',
  authDomain: 'kynd-development.firebaseapp.com',
  projectId: 'kynd-development',
  storageBucket: 'kynd-development.appspot.com',
  messagingSenderId: '875450003032',
  appId: '1:875450003032:web:db0004a2b9b7e7cb2de42d',
  measurementId: 'G-VYN4LF9PB3',
  subscriptionOrgId: '4IZWXIvHysyu353Ukpx8',
  subscriptionCode: 'SUBSCRIPTION_ACCESS'
};
