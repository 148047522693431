import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px;

  .activation-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      border-radius: 50%;
      background-color: #fff;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ArticleImage = styled.img`
  width: 60px !important;
  height: 60px !important;
  object-fit: cover;
  background-color: #33333311;
`;
