import { Area } from 'react-easy-crop/types';
import Resizer from 'react-image-file-resizer';

export const bytesToSize = (bytes: number, separator = ''): string => {
  const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';

  const sizeArrayIndex = parseInt(
    Math.floor(Math.log(bytes) / Math.log(1024)).toString(),
    10
  );

  if (sizeArrayIndex === 0)
    return `${bytes}${separator}${sizes[sizeArrayIndex]}`;

  const writtenSize = `${(bytes / 1024 ** sizeArrayIndex).toFixed(
    1
  )}${separator} ${sizes[sizeArrayIndex]}`;
  return writtenSize;
};

export const resizeFile = (file: File): Promise<File> => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1080,
      1080,
      'JPEG',
      75,
      0,
      (uri) => resolve(uri as File),
      'file'
    );
  });
};

function createImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });
}

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

export async function cropImage(
  fileName: string,
  imageSrc: string,
  pixelCrop?: Area,
  rotation = 0
): Promise<File | null> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) return null;

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop ? pixelCrop.width : canvas.width;
  canvas.height = pixelCrop ? pixelCrop.height : canvas.height;

  ctx.putImageData(
    data,
    Math.round(
      0 - safeArea / 2 + image.width * 0.5 - (pixelCrop ? pixelCrop.x : 0)
    ),
    Math.round(
      0 - safeArea / 2 + image.height * 0.5 - (pixelCrop ? pixelCrop.y : 0)
    )
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) =>
      resolve(new File([blob!], fileName, { type: 'image/jpeg' }))
    );
  });
}
