import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
import { BrowserRouter as Router } from 'react-router-dom';

import { App } from './App';
import { reportWebVitals } from './reportWebVitals';

import { ErrorBoundary } from './components/ErrorBoundary';

// Sentry.init({
//   dsn: 'https://9f293aab2448429c9da1f929f96614de@o1152774.ingest.sentry.io/6231089',
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0
// });

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
