import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Column } from 'react-table';
import { Check, Close, ModeEdit } from '@mui/icons-material';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  Box,
  Select,
  MenuItem
} from '@mui/material';
import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { PageTitle } from '../../../components/PageTitle';
import { Table } from '../../../components/Table';
import { Spinner } from '../../../components/Spinner';

import { getDivisions, updateDivision } from '../../../functions/divisions';
import {
  getOrganisationCodes,
  updateCodeStatus
} from '../../../functions/codes';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import { Division } from '../../../models/division';
import { OrganisationCode } from '../../../models/code';

import { Container, DivisionCodesView } from './styles';

interface DivisionsTableRow {
  index: number;
  name: string;
  description: string;
  codes: JSX.Element;
  actions: JSX.Element;
}

export const Divisions: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingDivision, setEditingDivision] = useState({} as Division);

  const [isLoadingDivisions, setIsLoadingDivisions] = useState(true);
  const [isUpdatingDivision, setIsUpdatingDivision] = useState(false);
  const [divisions, setDivisions] = useState<Division[]>([]);
  const [divisionsCodes, setDivisionsCodes] = useState<OrganisationCode[]>([]);

  const [editingForm, setEditingForm] = useState<Division>({
    id: '',
    name: '',
    description: '',
    codes: []
  });

  useEffect(() => {
    const getDivisionsAsync = async () => {
      try {
        const response = await getDivisions(user?.organisation.id);
        if (response) setDivisions(response);

        const codesArray = await getOrganisationCodes(user?.organisation.id);
        if (codesArray) setDivisionsCodes(codesArray);

        setIsLoadingDivisions(false);
      } catch (error) {
        setIsLoadingDivisions(false);
        addToast({
          title: 'Error fetching divisions',
          description: 'Try again later',
          type: 'error'
        });
      }
    };
    getDivisionsAsync();
  }, [user, addToast]);

  // const handleDeleteDivision = useCallback(
  //   async (division: Division) => {
  //     setIsRemovingDivision(true);

  //     const organisationId = user?.organisation.id;
  //     if (!organisationId) return;

  //     await deleteDivision({ division, organisationId });

  //     const remainingDivisions = divisions.filter(
  //       (depart) => depart.id !== division.id
  //     );

  //     setDivisions(remainingDivisions);
  //     setIsRemovingDivision(false);
  //     addToast({
  //       type: 'success',
  //       title: 'Division removed'
  //     });
  //   },
  //   [addToast, divisions, user?.organisation.id]
  // );

  const handleUpdateDivision = useCallback(async () => {
    setIsEditModalOpen(false);
    setIsUpdatingDivision(true);

    const organisationId = user?.organisation.id;
    if (!organisationId) return;

    const division = { ...editingDivision };

    division.name = editingForm.name;
    division.description = editingForm.description;

    await updateDivision({
      originalDivision: editingDivision,
      editedDivision: division,
      organisationId
    });

    const filteredDivisions = divisions.filter(
      (depart) => depart.id !== division.id
    );

    setDivisions([...filteredDivisions, division]);
    setIsUpdatingDivision(false);
    setEditingForm({ id: '', name: '', description: '', codes: [] as any });
    setEditingDivision({} as Division);
    addToast({
      type: 'success',
      title: 'Division updated'
    });
  }, [
    addToast,
    divisions,
    editingDivision,
    user?.organisation.id,
    editingForm
  ]);

  const handleEditDivision = useCallback((division: Division) => {
    const { id, name, description, codes } = division;

    setEditingDivision(division);
    setEditingForm({
      id,
      name,
      description,
      codes
    });

    setIsEditModalOpen(true);
  }, []);

  const handleChangeCodeStatus = useCallback(
    async (code: OrganisationCode, codeIndex: number) => {
      const status = !code.isActive;
      await updateCodeStatus(code, status);

      const depCodes = [...divisionsCodes];
      divisionsCodes[codeIndex].isActive = status;

      setDivisionsCodes(depCodes);
    },
    [divisionsCodes]
  );

  const divisionsColumns: Column<DivisionsTableRow>[] = useMemo(
    () => [
      {
        Header: `#`,
        accessor: 'index'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Description',
        accessor: 'description'
      },
      {
        Header: 'Codes',
        accessor: 'codes'
      },
      {
        Header: 'Actions',
        accessor: 'actions'
      }
    ],
    []
  );

  const divisionsTableData = useMemo(() => {
    const divisionsDataArray = [] as DivisionsTableRow[];

    divisions.map(({ id, name, description, codes }, index) => {
      const divisionRow = {
        index: index + 1,
        name,
        description,
        codes: (
          <Select style={{ width: '100%' }} value="">
            {divisionsCodes.map(
              (code, codeIndex) =>
                code.divisionId === id && (
                  <MenuItem
                    key={code.id}
                    value={code.id}
                    style={{ cursor: 'default' }}
                  >
                    <DivisionCodesView>
                      <div>
                        <p>{code.code}</p>
                        <p>
                          {code.currentUsage}/{code.maximumUsage}
                        </p>
                      </div>

                      <button
                        type="button"
                        title={
                          code.isActive
                            ? 'Active code. Click to make it inactivate, so people can no longer use it'
                            : 'Inactive code. Click to make it active again, so people can use it again'
                        }
                        onClick={() =>
                          handleChangeCodeStatus({ ...code }, codeIndex)
                        }
                      >
                        {code.isActive ? (
                          <Check color="success" />
                        ) : (
                          <Close color="error" />
                        )}
                      </button>
                    </DivisionCodesView>
                  </MenuItem>
                )
            )}
          </Select>
        ),
        actions: (
          <aside>
            {name !== 'Default division' ? (
              <button
                type="button"
                title="Edit division"
                onClick={() =>
                  handleEditDivision({
                    id,
                    name,
                    description,
                    codes
                  } as Division)
                }
              >
                <ModeEdit color="secondary" />
              </button>
            ) : (
              <>--</>
            )}
            {/* {name !== 'Default division' ? (
              <button
                type="button"
                title="Remove division"
                onClick={() =>
                  handleDeleteDivision({
                    id,
                    name,
                    description,
                    codes
                  } as Division)
                }
              >
                <Delete color="error" />
              </button>
            ) : (
              <></>
            )} */}
          </aside>
        )
      };

      return divisionsDataArray.push(divisionRow);
    });

    return divisionsDataArray;
  }, [divisions, handleEditDivision, divisionsCodes, handleChangeCodeStatus]);

  return (
    <Container>
      {isLoadingDivisions && <Spinner message="Loading divisions" />}
      {isUpdatingDivision && <Spinner message="Updating division" />}
      <Helmet>
        <title>Divisions - List</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle heading="Divisions List" />
      </PageTitleWrapper>

      <Table
        columns={divisionsColumns}
        data={divisionsTableData}
        rowHeight={60}
      />

      <Dialog open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
        <DialogTitle>Edit division</DialogTitle>
        <DialogContent>
          <Box component="form" noValidate sx={{ minWidth: 360 }}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <TextField
                  name="name"
                  fullWidth
                  id="name"
                  margin="dense"
                  label="Name"
                  defaultValue={editingForm.name}
                  onChange={(e) =>
                    setEditingForm({ ...editingForm, name: e.target.value })
                  }
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  name="description"
                  fullWidth
                  id="description"
                  margin="dense"
                  label="Description"
                  defaultValue={editingForm.description}
                  onChange={(e) =>
                    setEditingForm({
                      ...editingForm,
                      description: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditModalOpen(false)}>Cancel</Button>
          <Button onClick={() => handleUpdateDivision()}>Save</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
