import * as Sentry from '@sentry/react';
import { firestore, isProduction } from '../services/firebase';

import { InitialMessage } from '../models/initialMessage';

const collectionInitialMessages = firestore.collection('initialMessages');

export const createInitialMessage = async (data: InitialMessage) => {
  const { category, measurement, red, green, orange } = data;

  try {
    const message = await collectionInitialMessages.add({
      category,
      measurement,
      red: {
        ...red,
        isActive: false,
        createdAt: new Date()
      },
      green: {
        ...green,
        isActive: false,
        createdAt: new Date()
      },
      orange: {
        ...orange,
        isActive: false,
        createdAt: new Date()
      },
      createdAt: new Date()
    });

    return message;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const updateInitialMessage = async (data: InitialMessage) => {
  const { id, category, measurement, red, green, orange } = data;

  try {
    const message = await collectionInitialMessages.doc(id).update({
      category,
      measurement,
      red: {
        ...red,
        updatedAt: new Date()
      },
      green: {
        ...green,
        updatedAt: new Date()
      },
      orange: {
        ...orange,
        updatedAt: new Date()
      },
      updatedAt: new Date()
    });

    return message;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const getInitialMessage = async (
  id: string
): Promise<InitialMessage | undefined> => {
  try {
    const message = await collectionInitialMessages.doc(id).get();

    return message.data() as InitialMessage;
  } catch (error) {
    throw new Error();
  }
};

export const getInitialMessages = async (): Promise<InitialMessage[]> => {
  const initialMessagesFormatted: InitialMessage[] = [];

  try {
    const messages = await collectionInitialMessages.get();

    messages.docs.forEach((message) => {
      const messageData = message.data() as Omit<InitialMessage, 'id'>;

      initialMessagesFormatted.push({
        id: message.id,
        ...messageData
      });
    });

    return initialMessagesFormatted;
  } catch (error) {
    throw new Error();
  }
};

export const deleteInitialMessage = async (
  initialMessage: InitialMessage
): Promise<boolean> => {
  try {
    await collectionInitialMessages.doc(initialMessage.id).delete();

    return true;
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
    return false;
  }
};
