import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import 'firebase/compat/functions';
import 'firebase/compat/messaging';

import DEVELOPMENT_SETTINGS from './firebase/development';
import PRODUCTION_SETTINGS from './firebase/production';

// export const isProduction = true;
export const isProduction = window.location.hostname === 'my.kyndwellness.com';

const SETTINGS = isProduction ? PRODUCTION_SETTINGS : DEVELOPMENT_SETTINGS;
export const { subscriptionOrgId, subscriptionCode } = SETTINGS;

firebase.initializeApp(SETTINGS);

const REGION = 'australia-southeast1';

export const firestore = firebase.firestore();
export const realtimeDatabase = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export const firebaseFunctions = firebase.app().functions(REGION);
export const firebaseSDK = firebase;
