import * as Sentry from '@sentry/react';
import { OrganisationCode } from '../models/code';
import { firestore, isProduction } from '../services/firebase';

import { getDivisions, updateDivision } from './divisions';

const collectionCodes = firestore.collection('codes');

export const getCodes = async () => {
  const codesFormatted: OrganisationCode[] = [];

  try {
    const codes = await collectionCodes.get();

    codes.docs.forEach((code) => {
      const codeData = code.data() as Omit<OrganisationCode, 'id'>;

      codesFormatted.push({
        id: code.id,
        ...codeData
      });
    });

    return codesFormatted;
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
    return null;
  }
};

export const getOrganisationCodes = async (
  organisationId: string | undefined
) => {
  const codesFormatted: OrganisationCode[] = [];

  try {
    const codes = await collectionCodes
      .where('organisationId', '==', organisationId)
      .get();

    codes.docs.forEach((code) => {
      const codeData = code.data() as Omit<OrganisationCode, 'id'>;

      codesFormatted.push({
        id: code.id,
        ...codeData
      });
    });

    return codesFormatted;
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
    return null;
  }
};

export const createCode = async (code: Omit<OrganisationCode, 'id'>) => {
  try {
    const divisions = await getDivisions(code.organisationId);
    const division = divisions?.find((dep) => dep.id === code.divisionId);

    if (!division) throw new Error('Missing code departmend Id');

    const copyDivision = { ...division };
    const divisionCodes = [...copyDivision.codes];

    const codeCreated = await collectionCodes.add(code);

    const { organisationId, organisationName, maximumUsage } = code;

    const divisionCode = {
      id: codeCreated.id,
      code: code.code,
      organisationId,
      organisationName,
      maximumUsage
    };

    divisionCodes.push(divisionCode);
    copyDivision.codes = [...divisionCodes];
    await updateDivision({
      originalDivision: division,
      editedDivision: copyDivision,
      organisationId: code.organisationId
    });

    return code;
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
    return null;
  }
};

export const verifyIfCodeExists = async (plainCode: string) => {
  try {
    const codeSnapshot = await collectionCodes
      .where('code', '==', plainCode)
      .get();
    const code = codeSnapshot.docs.map((_code) => _code.data())[0];

    return !!code;
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
    return false;
  }
};

export const updateCodeStatus = async (
  code: OrganisationCode,
  status: boolean
) => {
  try {
    await collectionCodes.doc(code.id).update({
      isActive: status
    });

    return code;
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
    return null;
  }
};

export const updateCodeCurrentUsage = async (
  code: OrganisationCode,
  usage: number
) => {
  if (usage > code.maximumUsage) {
    throw new Error(`Usage exceded ${usage}/${code.maximumUsage}`);
  }

  try {
    if (usage === code.maximumUsage) {
      await collectionCodes.doc(code.id).update({
        currentUsage: usage,
        isActive: false
      });
    } else {
      await collectionCodes.doc(code.id).update({
        currentUsage: usage
      });
    }

    return code;
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
    return null;
  }
};

interface CodeMaxUsageProps {
  codeId: string;
  maxUsage: number;
}

export const updateCodesMaxUsage = async (
  codesToUpdate: CodeMaxUsageProps[]
) => {
  try {
    for (let codeIndex = 0; codeIndex < codesToUpdate.length; codeIndex += 1) {
      const { codeId, maxUsage } = codesToUpdate[codeIndex];

      // eslint-disable-next-line no-await-in-loop
      await collectionCodes.doc(codeId).update({
        maximumUsage: maxUsage
      });
    }

    return codesToUpdate;
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
    return null;
  }
};
