import * as Sentry from '@sentry/react';
import { Article } from '../models/article';
import { firestore, isProduction } from '../services/firebase';

const collectionArticles = firestore.collection('articles');

export const createArticle = async (art: Article) => {
  try {
    const article = await collectionArticles.add({
      title: art.title,
      body: art.body,
      coverImageUrl: art.coverImageUrl,
      bodyImagesUrls: art.bodyImagesUrls,
      hashtags: art.hashtags,
      categories: art.categories,
      questionnaires: art.questionnaires,
      authorName: art.authorName,
      authorImage: art.authorImage,
      authorImageUrl: art.authorImageUrl,
      isActive: art.isActive,
      publishedAt: art.publishedAt,
      createdAt: art.createdAt
    });

    return article;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const updateArticle = async (art: Article) => {
  try {
    const article = await collectionArticles.doc(art.id).update({
      title: art.title,
      body: art.body,
      coverImageUrl: art.coverImageUrl,
      bodyImagesUrls: art.bodyImagesUrls,
      hashtags: art.hashtags,
      categories: art.categories,
      questionnaires: art.questionnaires,
      authorName: art.authorName,
      authorImage: art.authorImage,
      authorImageUrl: art.authorImageUrl,
      isActive: art.isActive,
      publishedAt: art.publishedAt
    });

    return article;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const getArticle = async (
  articleId: string
): Promise<Article | undefined> => {
  try {
    const article = await collectionArticles.doc(articleId).get();

    return {
      id: articleId,
      ...article.data()
    } as Article;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const getArticles = async () => {
  const articlesFormatted: Article[] = [];

  try {
    const articles = await collectionArticles
      .orderBy('publishedAt', 'desc')
      .get();

    articles.docs.forEach((article) => {
      const articleData = article.data() as Omit<Article, 'id'>;

      articlesFormatted.push({
        id: article.id,
        ...articleData
      });
    });

    return articlesFormatted;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

export const deleteArticles = async (articleId: string) => {
  try {
    await collectionArticles.doc(articleId).delete();

    return true;
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
    return false;
  }
};
