import { format, differenceInDays } from 'date-fns';
import moment from 'moment';

interface Timestamp {
  nanoseconds: number;
  seconds: number;
  _seconds?: number;
}

interface FormatDateProps {
  date: Timestamp | string;
}

export const formatDate = ({ date }: FormatDateProps): string => {
  let dateFormatted = '';

  if (date) {
    if (typeof date === 'string') {
      dateFormatted = format(Date.parse(date), `dd MMM yyyy',' p`);
    } else if (date.seconds) {
      dateFormatted = format(new Date(date.seconds * 1000), `dd MMM yyyy',' p`);
    } else if (date._seconds) {
      dateFormatted = format(
        new Date(date._seconds * 1000),
        `dd MMM yyyy',' p`
      );
    } else {
      dateFormatted = format(date as any, `dd MMM yyyy',' p`);
    }
  }

  return dateFormatted;
};

export const getDay = (date: Timestamp | string): string => {
  let day = '';

  if (date) {
    if (typeof date === 'string') {
      day = format(Date.parse(date), `dd`);
    } else if (date.seconds) {
      day = format(new Date(date.seconds * 1000), `dd`);
    } else if (date._seconds) {
      day = format(new Date(date._seconds * 1000), `dd`);
    } else {
      day = format(date as any, `dd`);
    }
  }

  return day;
};

export const getMonth = (date: Timestamp | string): string => {
  let month = '';

  if (date) {
    if (typeof date === 'string') {
      month = format(Date.parse(date), `MMM`);
    } else if (date.seconds) {
      month = format(new Date(date.seconds * 1000), `MMM`);
    } else if (date._seconds) {
      month = format(new Date(date._seconds * 1000), `MMM`);
    } else {
      month = format(date as any, `MMM`);
    }
  }

  return month;
};

export const getMonthAsNumber = (date: Timestamp | string): string => {
  let month = '';

  if (date) {
    if (typeof date === 'string') {
      month = format(Date.parse(date), `LL`);
    } else if (date.seconds) {
      month = format(new Date(date.seconds * 1000), `LL`);
    } else if (date._seconds) {
      month = format(new Date(date._seconds * 1000), `LL`);
    } else {
      month = format(date as any, `LL`);
    }
  }

  return month;
};

export const getYear = (date: Timestamp | string): string => {
  let year = '';

  if (date) {
    if (typeof date === 'string') {
      year = format(Date.parse(date), `yyyy`);
    } else if (date.seconds) {
      year = format(new Date(date.seconds * 1000), `yyyy`);
    } else if (date._seconds) {
      year = format(new Date(date._seconds * 1000), `yyyy`);
    } else {
      year = format(date as any, `yyyy`);
    }
  }

  return year;
};

export const getFormattedDayMonthYear = (
  date: Timestamp | string,
  addYear: boolean
) => {
  const day = getDay(date);
  const month = getMonth(date);
  const year = getYear(date);

  return addYear ? `${day}/${month}/${year}` : `${day}/${month}`;
};

export const getDifferenceInDays = (
  first: Timestamp | string,
  second: Timestamp | string
) => {
  let firstDate = new Date();
  let secondDate = new Date();

  if (typeof first === 'string') {
    firstDate = new Date(first);
  } else if (first.seconds) {
    firstDate = new Date(first.seconds);
  } else if (first._seconds) {
    firstDate = new Date(first._seconds);
  }

  if (typeof second === 'string') {
    secondDate = new Date(second);
  } else if (second.seconds) {
    secondDate = new Date(second.seconds);
  } else if (second._seconds) {
    secondDate = new Date(second._seconds);
  }

  const difference = differenceInDays(secondDate, firstDate);

  return difference;
};

export const getUnixSeconds = (date: any): number => {
  let dateUnix = 0;

  if (date) {
    if (typeof date === 'string') {
      dateUnix = Date.parse(date);
    } else if (date.seconds) {
      dateUnix = date.seconds * 1000;
    } else if (date._seconds) {
      dateUnix = date._seconds * 1000;
    } else {
      dateUnix = new Date(date).getTime();
    }
  }

  return dateUnix;
};

export const parseToISODate = (date: any): string => {
  return moment.utc(getUnixSeconds(date)).toISOString();
};
