import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px;
`;

export const OrganisationsContainer = styled.main`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 5px;

  margin-top: 24px;
  border-radius: 10px;
  background-color: #fff;
  padding: 5px;
`;

export const OrganisationCard = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;

  background-color: rgba(0, 0, 0, 0.05);
`;
