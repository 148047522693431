import { FC, ReactNode, useState } from 'react';
import { uniqueId } from 'lodash';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { Button, Badge, Collapse, ListItem } from '@mui/material';

import { useSideBar } from '../../../../hooks/sidebar';
import { useAuth } from '../../../../hooks/auth';

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  badge?: string;
  active?: string;
  open?: boolean;
  role?: string;
  name: string;
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link = '',
  icon: Icon,
  badge,
  role = '',
  open: openParent,
  name,
  ...rest
}) => {
  const [menuToggle, setMenuToggle] = useState(openParent);

  const { user } = useAuth();
  const { toggleSidebar } = useSideBar();
  const navigate = useNavigate();

  const handleLinkNavigation = (navigationLink: string) => {
    navigate(navigationLink);
    toggleSidebar();
  };

  const toggleMenu = (): void => {
    setMenuToggle((Open) => !Open);
  };

  if (children && role) {
    return (
      <ListItem
        component="div"
        className="Mui-children"
        key={uniqueId()}
        {...rest}
      >
        {user && user.role === role && (
          <>
            <Button
              className={clsx({ 'Mui-active': menuToggle })}
              startIcon={Icon && <Icon />}
              endIcon={
                menuToggle ? (
                  <ExpandLessTwoToneIcon />
                ) : (
                  <ExpandMoreTwoToneIcon />
                )
              }
              onClick={toggleMenu}
            >
              {name}
            </Button>
            <Collapse in={menuToggle}>{children}</Collapse>
          </>
        )}
      </ListItem>
    );
  }

  if (children) {
    return (
      <ListItem
        component="div"
        className="Mui-children"
        key={uniqueId()}
        {...rest}
      >
        <Button
          className={clsx({ 'Mui-active': menuToggle })}
          startIcon={Icon && <Icon />}
          endIcon={
            menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />
          }
          onClick={toggleMenu}
        >
          {name}
        </Button>
        <Collapse in={menuToggle}>{children}</Collapse>
      </ListItem>
    );
  }

  if (role) {
    return (
      <ListItem component="div" key={uniqueId()} {...rest}>
        {user && user.role === role && (
          <Button
            onClick={() => handleLinkNavigation(link)}
            startIcon={Icon && <Icon />}
          >
            {name}
            {badge && <Badge badgeContent={badge} />}
          </Button>
        )}
      </ListItem>
    );
  }

  return (
    <ListItem component="div" key={uniqueId()} {...rest}>
      <Button
        onClick={() => handleLinkNavigation(link)}
        startIcon={Icon && <Icon />}
      >
        {name}
        {badge && <Badge badgeContent={badge} />}
      </Button>
    </ListItem>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.string,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired
};

SidebarMenuItem.defaultProps = {
  open: false,
  active: 'false'
};

export default SidebarMenuItem;
