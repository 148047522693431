import * as Sentry from '@sentry/react';
import { orderBy, trim } from 'lodash';
import { Industry } from '../pages/Industries/AllIndustries';

import { firestore, isProduction } from '../services/firebase';

const collectionIndustries = firestore.collection('industries');

export const createIndustry = async (industry: string) => {
  const createdAt = new Date();
  const updatedAt = new Date();

  try {
    const createdindustry = await collectionIndustries.add({
      name: industry,
      createdAt,
      updatedAt
    });

    return {
      id: createdindustry.id,
      name: industry,
      createdAt,
      updatedAt
    } as Industry;
  } catch (error) {
    throw new Error();
  }
};

export const updateIndustry = async (industry: Industry) => {
  const { name } = industry;

  try {
    await collectionIndustries.doc(industry.id).update({
      name,
      updatedAt: new Date()
    });

    return industry;
  } catch (error) {
    throw new Error();
  }
};

export const getIndustries = async () => {
  const industriesFormatted: Industry[] = [];

  try {
    const industries = await collectionIndustries.get();

    industries.docs.forEach((industry) => {
      const industryData = industry.data() as Omit<Industry, 'id'>;

      industriesFormatted.push({
        ...industryData,
        id: industry.id,
        name: trim(industryData.name)
      });
    });

    return orderBy(industriesFormatted, ['name'], ['asc']);
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
    throw new Error();
  }
};

export const getIndustry = async (
  industryId: string
): Promise<Industry | undefined> => {
  try {
    const industry = await collectionIndustries.doc(industryId).get();

    return industry.data() as Industry;
  } catch (error) {
    throw new Error();
  }
};

export const deleteIndustry = async (industryId: string) => {
  try {
    await collectionIndustries.doc(industryId).delete();

    return true;
  } catch (error) {
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }
    return false;
  }
};
