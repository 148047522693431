import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 40px 40px;
`;

export const Hint = styled.h4`
  text-align: center;

  strong {
    text-decoration: underline;
    font-size: 120%;
  }
`;
