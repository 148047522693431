import { useCallback, useMemo, useState } from 'react';
import { FirstPage, LastPage, Search, LegendToggle } from '@mui/icons-material';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { isEmpty, orderBy } from 'lodash';

import { useOrganisation } from '../../hooks/organisations';
import { useAuth } from '../../hooks/auth';

import {
  Container,
  OrganisationComparasionButton,
  PropFilterBox,
  PropFilterWrapper,
  SearchUsersButton,
  UserSearchBox
} from './styles';

interface OrganisationFilterProps {
  showOrgsComparison?: boolean;
  hideDivision?: boolean;
  hideUserSearch?: boolean;
  hideOrganisation?: boolean;
  hideAllOptionOrgFilter?: boolean;
  additionalFilters?: JSX.Element[];
}

export function OrganisationFilter({
  showOrgsComparison = false,
  hideDivision = false,
  hideUserSearch = false,
  hideOrganisation = false,
  additionalFilters = [],
  hideAllOptionOrgFilter = false
}: OrganisationFilterProps) {
  const {
    currentOrganisation,
    industriesNames,
    allIndustries,
    organisationsNames,
    divisionsNames,
    chosenIndustry,
    chosenOrganisation,
    chosenDivision,
    handleChangeIndustry,
    handleChangeOrganisation,
    handleChangeDivision,
    userSearch,
    setUserSearch,
    fetchSearchedUsers,
    isComparingOrganisations,
    setIsComparingOrganisations,
    handleChangeIndustryToCompare,
    industryToCompare,
    allOrganisations
  } = useOrganisation();

  const { user } = useAuth();

  const [propToFilter, setPropToFilter] = useState<
    'lowerFirstName' | 'lowerLastName'
  >('lowerFirstName');

  const allIndustriesNames = useMemo(() => {
    const mixedIndustriesNames = allIndustries.map(({ name }) => name);

    const filteredIndustries = mixedIndustriesNames.filter((industryName) => {
      const filteredOrganisations = allOrganisations.filter((org) =>
        org.industry.includes(industryName)
      );

      const enableIndustry =
        user?.role === 'Kynd Admin' || filteredOrganisations.length >= 2;

      return enableIndustry;
    });

    const sortedIndustriesNames = orderBy(filteredIndustries, 'asc');

    return sortedIndustriesNames;
  }, [allIndustries, allOrganisations, user?.role]);

  const getOrganisationNames = useCallback(() => {
    if (!hideAllOptionOrgFilter) return organisationsNames;

    return organisationsNames.filter((value) => value !== 'All');
  }, [organisationsNames, hideAllOptionOrgFilter]);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item lg={1}>
          {currentOrganisation.name !== 'Summary' && showOrgsComparison && (
            <OrganisationComparasionButton
              title={
                isComparingOrganisations
                  ? 'Click to toggle OFF organisations comparison'
                  : 'Click to toggle ON organisations comparison'
              }
              isActive={isComparingOrganisations}
              onClick={() =>
                setIsComparingOrganisations(!isComparingOrganisations)
              }
            >
              <LegendToggle />
            </OrganisationComparasionButton>
          )}
        </Grid>

        {isComparingOrganisations && (
          <Grid item xs={12} sm={2}>
            <Autocomplete
              disablePortal
              id="industries-comparison-combo-box"
              options={allIndustriesNames}
              value={industryToCompare}
              freeSolo={false}
              disableClearable
              onChange={(e) =>
                handleChangeIndustryToCompare(
                  (e.currentTarget as HTMLElement).innerText
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  label="Industry to compare"
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={3}>
          <Autocomplete
            disablePortal
            id="industries-combo-box"
            options={industriesNames}
            value={chosenIndustry}
            freeSolo={false}
            disableClearable
            onChange={(e) =>
              handleChangeIndustry((e.currentTarget as HTMLElement).innerText)
            }
            renderInput={(params) => (
              <TextField {...params} required fullWidth label="Industry" />
            )}
          />
        </Grid>

        {!hideOrganisation && (
          <Grid item xs={12} sm={3}>
            <Autocomplete
              disablePortal
              id="organisations-combo-box"
              options={getOrganisationNames()}
              value={chosenOrganisation}
              freeSolo={false}
              disableClearable
              onChange={(e) =>
                handleChangeOrganisation(
                  (e.currentTarget as HTMLElement).innerText
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  label="Organisation"
                />
              )}
            />
          </Grid>
        )}

        {!hideDivision && (
          <Grid item xs={12} sm={3}>
            <Autocomplete
              disablePortal
              id="divisions-combo-box"
              options={divisionsNames}
              value={chosenDivision}
              freeSolo={false}
              disableClearable
              onChange={(e) =>
                handleChangeDivision((e.currentTarget as HTMLElement).innerText)
              }
              renderInput={(params) => (
                <TextField {...params} required fullWidth label="Division" />
              )}
            />
          </Grid>
        )}

        {!hideUserSearch && (
          <Grid item xs={12} sm={3}>
            <UserSearchBox>
              <TextField
                fullWidth
                label="Search user"
                value={userSearch}
                onChange={(e) => setUserSearch(e.target.value)}
              />

              <PropFilterWrapper>
                <PropFilterBox
                  type="button"
                  onClick={() => setPropToFilter('lowerFirstName')}
                  isActive={propToFilter === 'lowerFirstName'}
                  title="Filter by first name"
                >
                  <FirstPage />
                </PropFilterBox>

                <PropFilterBox
                  type="button"
                  onClick={() => setPropToFilter('lowerLastName')}
                  isActive={propToFilter === 'lowerLastName'}
                  title="Filter by last name"
                >
                  <LastPage />
                </PropFilterBox>
              </PropFilterWrapper>

              <SearchUsersButton
                type="button"
                onClick={() => fetchSearchedUsers(propToFilter)}
                title="Search users - blank search to fetch all users at once (not recommended)"
              >
                <Search />
              </SearchUsersButton>
            </UserSearchBox>
          </Grid>
        )}

        {!isEmpty(additionalFilters) && additionalFilters}
      </Grid>
    </Container>
  );
}
