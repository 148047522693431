import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 40px 10px;
`;

export const ToggleDivisions = styled.button`
  position: fixed;
  right: 70px;
  top: 130px;
  z-index: 2;

  border: none;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;

  height: 50px;
  width: 50px;

  transition: background-color 0.2s;
  color: #333333aa;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 700px) {
    top: 100px;
  }

  @media (max-width: 1100px) {
    right: 20px;
  }

  @media (max-width: 500px) {
    right: 5px;
  }

  svg {
    pointer-events: none;
  }
`;

export const ToggleViewWrapper = styled.div`
  display: flex;
`;

export const UsersWrapper = styled.main`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 5px;

  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 5px;
`;

export const UserCard = styled.div`
  display: flex;
  align-items: start;

  background-color: #fff;
  border-radius: 10px;
  padding: 3px;

  color: #333333aa;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;

  overflow: hidden;
`;

export const MainData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

interface UserIndexProps {
  background: string;
}

export const UserIndex = styled.div<UserIndexProps>`
  background-color: ${(props) => props.background};
  border-radius: 10px 0 0 10px;
  padding: 0 3px;
  font-weight: bold;
  height: 100%;
  margin-right: 3px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  button {
    border: none;
    background: transparent;
    padding: 3px 0 3px 2px;

    transition: filter 0.1s;

    &:hover {
      filter: brightness(1.7);
    }

    svg {
      width: 15px;
      height: 15px;
    }

    .newPassword {
      transform: rotate(-30deg);
    }
  }
`;

export const KyndAdminOptions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
`;

export const UserName = styled.div`
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ActivationButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const CreatedAt = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;

  p {
    font-size: 10px;
    margin-right: 5px;
  }

  strong {
    font-size: 10px;
  }
`;

export const DivisionRoleColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  gap: 5px;
  width: 100%;
  margin-top: 5px;
`;

export const DivisionName = styled.h4``;
