export default {
  apiKey: 'AIzaSyALBBx8g7Yph0SbEzpo9TeFhq9DFYrWCt4',
  authDomain: 'kyndwellnesscloud.firebaseapp.com',
  databaseURL: 'https://kyndwellnesscloud.firebaseio.com',
  projectId: 'kyndwellnesscloud',
  storageBucket: 'kyndwellnesscloud.appspot.com',
  messagingSenderId: '809458009455',
  appId: '1:809458009455:web:d7fb385478c7a8c16a549a',
  measurementId: 'G-V174DWKJJM',
  subscriptionOrgId: 'm2f49RJJppzNxlaD0V8s',
  subscriptionCode: 'KYND_SUBSCRIPTION'
};
