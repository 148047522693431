import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import * as Sentry from '@sentry/react';

import { cloneDeep, orderBy, trim } from 'lodash';
import { useAuth } from './auth';
import { useToast } from './toast';

import {
  getOrganisation,
  getOrganisations,
  getSearchedUsers
} from '../functions/organisations';

import { Industry } from '../pages/Industries/AllIndustries';
import { Spinner } from '../components/Spinner';
import { User } from '../models/user';
import { Organisation } from '../models/organisation';
import { getIndustries } from '../functions/industries';
import { calculateAllOrgsSummary } from '../utils/allOrgsSummary';
import { isProduction } from '../services/firebase';

interface OrganisationsContext {
  currentOrganisations: Organisation[];
  currentOrganisation: Organisation;
  users: User[];
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
  isLoadingUsers: boolean;
  industriesNames: string[];
  organisationsNames: string[];
  divisionsNames: string[];
  chosenIndustry: string;
  chosenOrganisation: string;
  chosenDivision: string;
  chosenDivisionId: string;
  userSearch: string;
  allIndustries: Industry[];
  organisations: Organisation[];
  orgIndustryComparison: Organisation;
  industryToCompare: string;
  isComparingOrganisations: boolean;
  allOrganisations: Organisation[];
  setOrganisations: React.Dispatch<React.SetStateAction<Organisation[]>>;
  handleChangeIndustry: (industryName: string) => void;
  handleChangeIndustryToCompare: (industryName: string) => void;
  handleChangeOrganisation: (organisationName: string) => void;
  handleChangeDivision: (divisionName: string) => void;
  setUserSearch: (search: string) => void;
  setCurrentOrganisations: (organisations: Organisation[]) => void;
  setAllOrganisations: (organisations: Organisation[]) => void;
  setCurrentOrganisation: React.Dispatch<React.SetStateAction<Organisation>>;
  setDivisionsNames: React.Dispatch<React.SetStateAction<string[]>>;
  setAllIndustries: (industries: Industry[]) => void;
  fetchSearchedUsers: (
    propToFilter: 'lowerFirstName' | 'lowerLastName'
  ) => void;
  setIsComparingOrganisations: React.Dispatch<React.SetStateAction<boolean>>;
  MINIMUM_USERS_TO_SEE_STATISTICS: number;
}

const OrganisationsContext = createContext<OrganisationsContext>(
  {} as OrganisationsContext
);

export const OrganisationProvider: React.FC = ({ children }) => {
  const [currentOrganisations, setCurrentOrganisations] = useState(
    [] as Organisation[]
  );
  const [currentOrganisation, setCurrentOrganisation] = useState(
    {} as Organisation
  );
  const [organisations, setOrganisations] = useState([] as Organisation[]);
  const [allOrganisations, setAllOrganisations] = useState(
    [] as Organisation[]
  );
  const [orgIndustryComparison, setOrgIndustryComparison] = useState(
    {} as Organisation
  );
  const [industryToCompare, setIndustryToCompare] = useState('');
  const [allIndustries, setAllIndustries] = useState([] as Industry[]);
  const [industriesNames, setIndustriesNames] = useState([] as string[]);
  const [organisationsNames, setOrganisationsNames] = useState([] as string[]);
  const [divisionsNames, setDivisionsNames] = useState(['All']);
  const [chosenIndustry, setChosenIndustry] = useState('Accounting');
  const [chosenOrganisation, setChosenOrganisation] = useState('');
  const [chosenDivision, setChosenDivision] = useState('All');
  const [chosenDivisionId, setChosenDivisionId] = useState('all');
  const [userSearch, setUserSearch] = useState('');
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [users, setUsers] = useState([] as User[]);
  const [isLoading, setIsLoading] = useState(false);
  const [isComparingOrganisations, setIsComparingOrganisations] =
    useState(false);

  const MINIMUM_USERS_TO_SEE_STATISTICS = 5;

  const { user } = useAuth();
  const { addToast } = useToast();

  const isSuperUser = useMemo(() => {
    if (!user) return false;

    const superAdminEmails = [
      'livi@kyndwellness.com',
      'tom@drtomonamission.com',
      'tania@kyndwellness.com',
      'dee@kyndwellness.com',
      'jo.davies@kyndwellness.com'
    ];

    return !!(
      superAdminEmails.includes(user.email || '') || user.role === 'Kynd Admin'
    );
  }, [user]);

  useEffect(() => {
    setUsers([]);

    const getAllOrganisations = async () => {
      setIsLoading(true);

      if (!user) {
        setIndustriesNames(['N/A']);
        setOrganisationsNames(['N/A']);
        setDivisionsNames(['N/A']);
        setChosenIndustry('N/A');
        setChosenOrganisation('N/A');
        setChosenDivision('N/A');

        setIsLoading(false);
        return;
      }

      let allOrganisationsArray: Organisation[] = [];
      const organisationsFromDatabase = await getOrganisations();
      setAllOrganisations(cloneDeep(organisationsFromDatabase));

      if (isSuperUser) {
        allOrganisationsArray = organisationsFromDatabase;
      } else {
        const userOrg = await getOrganisation(user.organisation.id);

        if (!userOrg) {
          setIsLoading(false);
          return;
        }

        allOrganisationsArray.push(userOrg);
      }

      allOrganisationsArray.sort((orgA, orgB) =>
        orgA.name > orgB.name ? 1 : -1
      );

      setOrganisations(allOrganisationsArray);

      if (allOrganisationsArray.length) {
        const firstOrganisation = allOrganisationsArray[0];

        const filteredCurrentOrganisations = allOrganisationsArray.filter(
          (org) => org.industry.includes(firstOrganisation.industry)
        );

        setCurrentOrganisations(filteredCurrentOrganisations);
        setCurrentOrganisation(firstOrganisation);
        setChosenIndustry(firstOrganisation.industry);
        setChosenOrganisation(firstOrganisation.name);
        setChosenDivision('All');

        const orgsComparisonData = calculateAllOrgsSummary(
          organisationsFromDatabase,
          firstOrganisation.industry
        );
        setOrgIndustryComparison(orgsComparisonData);
        setIndustryToCompare(firstOrganisation.industry);

        const divisions = [] as string[];

        firstOrganisation.divisions.forEach(({ name }) => {
          divisions.push(name);
        });

        setDivisionsNames(['All', ...orderBy(divisions, [], 'asc')]);

        const organisationsNamesArray = [] as string[];
        const industries = [] as string[];

        allOrganisationsArray.forEach(({ industry, name }) => {
          if (trim(industry) === trim(firstOrganisation.industry)) {
            organisationsNamesArray.push(name);
          }

          if (!industries.includes(industry)) {
            industries.push(industry);
          }
        });

        industries.sort((a, b) => (a.trim() > b.trim() ? 1 : -1));

        setIndustriesNames(industries);
        setOrganisationsNames(
          isSuperUser
            ? ['All', ...orderBy(organisationsNamesArray, [], 'asc')]
            : organisationsNamesArray
        );
      }

      setIsLoading(false);
    };

    getAllOrganisations();

    const getIndustriesAsync = async () => {
      try {
        const response = await getIndustries();

        setAllIndustries(response);
      } catch (error) {
        console.log(error);
      }
    };

    getIndustriesAsync();
  }, [isSuperUser, user]);

  useEffect(() => {
    setOrganisations(allOrganisations);
  }, [allOrganisations]);

  const handleChangeIndustry = useCallback(
    async (industryName: string) => {
      setIsLoading(true);
      setChosenIndustry(industryName);

      const firstOrganisation = organisations.find(
        (org) => trim(org.industry) === trim(industryName)
      );

      if (!firstOrganisation) {
        setIsLoading(false);
        return;
      }

      setCurrentOrganisation(firstOrganisation);
      setChosenOrganisation(firstOrganisation.name);

      const divisions = [] as string[];

      firstOrganisation.divisions.forEach((division) => {
        divisions.push(division.name);
      });

      setDivisionsNames(['All', ...orderBy(divisions, [], 'asc')]);
      setChosenDivision('All');
      setChosenDivisionId('all');

      const organisationsNamesArray = [] as string[];
      const currentOrganisationsArray = [] as Organisation[];

      organisations.forEach((org) => {
        if (trim(org.industry) === trim(industryName)) {
          organisationsNamesArray.push(org.name);
          currentOrganisationsArray.push(org);
        }
      });

      setCurrentOrganisations(currentOrganisationsArray);

      setUsers([]);
      setUserSearch('');

      setOrganisationsNames(
        user!.role === 'Kynd Admin'
          ? ['All', ...orderBy(organisationsNamesArray, [], 'asc')]
          : organisationsNamesArray
      );
      setIsLoading(false);
    },
    [organisations, user]
  );

  const handleChangeOrganisation = useCallback(
    async (organisationName: string) => {
      setIsLoading(true);

      setUsers([]);
      setUserSearch('');

      if (organisationName === 'All') {
        const generalOrganisation = calculateAllOrgsSummary(
          currentOrganisations,
          chosenIndustry
        );

        setCurrentOrganisation(generalOrganisation);
        setDivisionsNames(['All']);
        setChosenDivision('All');
        setChosenDivisionId('all');
        setIsComparingOrganisations(false);
      } else {
        try {
          const organisationSelected = organisations.find(
            (org) => trim(org.name) === trim(organisationName)
          );

          if (!organisationSelected) {
            setIsLoading(false);
            return;
          }

          setCurrentOrganisation(organisationSelected);
          const divisions = ['All'];

          organisationSelected.divisions.forEach((division) => {
            divisions.push(division.name);
          });

          setDivisionsNames(divisions);
          setChosenDivision('All');
          setChosenDivisionId('all');
        } catch (error) {
          console.log(error);
        }
      }

      setChosenOrganisation(organisationName);
      setIsLoading(false);
    },
    [organisations, chosenIndustry, currentOrganisations]
  );

  const handleChangeDivision = useCallback(
    (divisionName: string) => {
      const fixedDivisionName = divisionName.replace('&amp;', '&');

      setChosenDivision(fixedDivisionName);

      if (divisionName === 'All') {
        setChosenDivisionId('all');
      } else {
        const selectedDivision = currentOrganisation.divisions.find(
          (division) => trim(division.name) === trim(fixedDivisionName)
        );

        if (!selectedDivision) return;

        setChosenDivisionId(selectedDivision.id);
      }
    },
    [currentOrganisation]
  );

  const handleChangeIndustryToCompare = useCallback(
    (industryName: string) => {
      const orgsComparisonData = calculateAllOrgsSummary(
        allOrganisations,
        industryName
      );
      setOrgIndustryComparison(orgsComparisonData);
      setIndustryToCompare(industryName);
    },
    [allOrganisations]
  );

  const fetchSearchedUsers = useCallback(
    async (propToFilter: 'lowerFirstName' | 'lowerLastName') => {
      if (!currentOrganisation.id) {
        window.alert('Select an organisation to find users');
        return;
      }

      setIsLoadingUsers(true);

      try {
        const organisationUsers = await getSearchedUsers(
          propToFilter,
          userSearch,
          currentOrganisation.id
        );

        if (!organisationUsers) return;

        organisationUsers.sort((userA, userB) => {
          const nameA = `${userA.firstName} ${userA.lastName}`;
          const nameB = `${userB.firstName} ${userB.lastName}`;

          return nameA > nameB ? 1 : -1;
        });

        setUsers(organisationUsers);
        setIsLoadingUsers(false);
      } catch (error) {
        setIsLoadingUsers(false);

        if (isProduction) {
          Sentry.captureException(error);
        }

        addToast({
          title: 'Error fetching users',
          description: 'Please, try again later',
          type: 'error'
        });
      }
    },
    [userSearch, currentOrganisation.id, addToast]
  );

  return (
    <OrganisationsContext.Provider
      value={{
        currentOrganisations,
        currentOrganisation,
        industriesNames,
        organisationsNames,
        divisionsNames,
        chosenIndustry,
        chosenOrganisation,
        chosenDivision,
        chosenDivisionId,
        handleChangeIndustry,
        handleChangeOrganisation,
        handleChangeDivision,
        userSearch,
        setUserSearch,
        setCurrentOrganisations,
        allIndustries,
        setAllIndustries,
        organisations,
        orgIndustryComparison,
        setOrganisations,
        users,
        setUsers,
        isLoadingUsers,
        isComparingOrganisations,
        setIsComparingOrganisations,
        fetchSearchedUsers,
        setCurrentOrganisation,
        setAllOrganisations,
        setDivisionsNames,
        handleChangeIndustryToCompare,
        industryToCompare,
        allOrganisations,
        MINIMUM_USERS_TO_SEE_STATISTICS
      }}
    >
      {isLoading && <Spinner />}
      {children}
    </OrganisationsContext.Provider>
  );
};

export function useOrganisation(): OrganisationsContext {
  const context = useContext(OrganisationsContext);

  return context;
}
