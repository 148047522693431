import { FormEvent, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  Box,
  Container,
  MenuItem,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';

import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { PageTitle } from '../../../components/PageTitle';

import { createQuestion } from '../../../functions/questions';
import { useToast } from '../../../hooks/toast';

import { Question } from '../../../models/question';
import { Spinner } from '../../../components/Spinner';
import { CustomAnswerWrapper, Wrapper } from './styles';
import { AnswerCard } from '../components/AnswerCard';

export const CreateQuestion: React.FC = () => {
  const [isCreatingQuestion, setIsCreatingQuestion] = useState(false);
  const [answers, setAnswers] = useState<string[]>([]);
  const [form, setForm] = useState<Omit<Question, 'id'>>({
    category: 'Body',
    section: '',
    label: '',
    type: 'How often',
    questionnaire: '',
    unit: '',
    order: 1,
    invertedAnswers: false,
    clinicianQuestion: false,
    icon: '',
    answers: []
  });

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setIsCreatingQuestion(true);

      const question = {} as Question;

      question.category = form.category;
      question.section = form.section;
      question.label = form.label;
      question.type = form.type;
      question.questionnaire = form.questionnaire;
      question.unit = form.unit;
      question.order = form.order;
      question.invertedAnswers = form.invertedAnswers;
      question.clinicianQuestion = form.clinicianQuestion;
      question.icon = form.icon;
      question.answers = answers;

      try {
        await createQuestion(question);

        setIsCreatingQuestion(false);

        setForm({
          ...form,
          label: '',
          unit: '',
          order: form.order + 1,
          invertedAnswers: false,
          clinicianQuestion: false,
          icon: '',
          answers: []
        });

        addToast({
          title: 'Question created',
          type: 'success'
        });
      } catch (error) {
        setIsCreatingQuestion(false);
        addToast({
          title: 'Error creating question',
          description: 'Try again later',
          type: 'error'
        });
      }
    },
    [addToast, form, answers]
  );

  const handleCategoryChange = (category: Question['category']) => {
    setForm({ ...form, category });
  };

  const handleTypeChange = useCallback((type: Question['type']) => {
    setForm((oldState) => ({ ...oldState, type }));

    if (type === 'Custom options') {
      setAnswers(['', '']);
    } else {
      setAnswers([]);
    }
  }, []);

  const handleAddAnswer = () => {
    if (answers.length === 6) return;
    setAnswers([...answers, '']);
  };

  const handleRemoveAnswer = useCallback(
    (answerIndex: number) => {
      if (answers.length === 2) return;
      setAnswers(answers.filter((_, index) => index !== answerIndex));
    },
    [answers]
  );

  const handleChangeAnswer = (content: string, answerIndex: number) => {
    const answersContent = [...answers];
    answersContent[answerIndex] = content;

    setAnswers([...answersContent]);
  };

  const questionCategories = ['Body', 'Life', 'Mind'];
  const questionTypes = [
    'How often',
    'Agree or disagree',
    'Percentage range',
    'Number',
    'Text answer',
    'Custom options'
  ];

  const oftenAnswers = ['Never', 'Rarely', 'Sometimes', 'Often', 'Very often'];
  const agreeDisagreeAnswers = [
    'Strongly agree',
    'Agree',
    'Neutral',
    'Disagree',
    'Strongly disagree'
  ];

  return (
    <Wrapper>
      {isCreatingQuestion && <Spinner message="Creating question" />}
      <Helmet>
        <title>Questions - Create new</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle heading="New Question" />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <Card>
                <CardContent>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 1, pl: 1, pr: 1 }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="label"
                          required
                          fullWidth
                          multiline
                          rows={7}
                          id="label"
                          label="Question"
                          value={form.label}
                          onChange={(e) =>
                            setForm({ ...form, label: e.target.value })
                          }
                          autoFocus
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            required
                            select
                            label="Select question category"
                            value={form.category}
                            onChange={(e) =>
                              handleCategoryChange(
                                e.target.value as Question['category']
                              )
                            }
                          >
                            {questionCategories.map((category) => (
                              <MenuItem key={category} value={category}>
                                {category}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            required
                            select
                            label="Select answer type"
                            value={form.type}
                            onChange={(e) =>
                              handleTypeChange(
                                e.target.value as Question['type']
                              )
                            }
                          >
                            {questionTypes.map((type) => (
                              <MenuItem key={type} value={type}>
                                {type}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="section"
                            required
                            fullWidth
                            id="section"
                            label="Section (e.g. Physycal)"
                            value={form.section}
                            onChange={(e) =>
                              setForm({ ...form, section: e.target.value })
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="questionnaire"
                            required
                            fullWidth
                            id="questionnaire"
                            label="Questionnaire (e.g. Weight)"
                            value={form.questionnaire}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                questionnaire: e.target.value
                              })
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="order"
                            required
                            fullWidth
                            id="order"
                            label="Order"
                            type="number"
                            value={form.order}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                order:
                                  Number(e.target.value) < 1
                                    ? 1
                                    : Number(e.target.value)
                              })
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControlLabel
                            title="Inverted answers: It means answers like Strongly agree, or Very often, will have a negative impact on the overal score"
                            control={
                              <Checkbox
                                name="invertedAnswers"
                                id="invertedAnswers"
                                checked={form.invertedAnswers}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    invertedAnswers: e.target.checked
                                  });
                                }}
                              />
                            }
                            label="Inverted Answers"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControlLabel
                            title="Question that will only be shown to Clinicians"
                            control={
                              <Checkbox
                                name="clinicianQuestion"
                                id="clinicianQuestion"
                                checked={form.clinicianQuestion}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    clinicianQuestion: e.target.checked
                                  });
                                }}
                              />
                            }
                            label="Clinician Question"
                          />
                        </Grid>
                      </Grid>

                      {form.type === 'Number' && (
                        <Grid item xs={12} sm={6} mb={2}>
                          <TextField
                            name="unit"
                            fullWidth
                            id="unit"
                            label="Answer unit (e.g. kg)"
                            value={form.unit}
                            onChange={(e) =>
                              setForm({ ...form, unit: e.target.value })
                            }
                          />
                        </Grid>
                      )}

                      {form.type === 'Custom options' && (
                        <>
                          <Grid item xs={12} sm={12} className="custom-options">
                            <h3>Custom options</h3>

                            {answers.length < 6 && (
                              <button
                                type="button"
                                onClick={handleAddAnswer}
                                className="add-answer answer-button"
                                title="Add answer"
                              >
                                <Add fontSize="large" />
                              </button>
                            )}
                          </Grid>

                          {answers.map((answer, answerIndex) => (
                            <Grid item xs={12} sm={4} key={answerIndex}>
                              <CustomAnswerWrapper>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleRemoveAnswer(answerIndex)
                                  }
                                  className="remove-answer answer-button"
                                  title="Remove answer"
                                >
                                  <Close />
                                </button>

                                <TextField
                                  name={`option${answerIndex + 1}`}
                                  fullWidth
                                  id={`option${answerIndex + 1}`}
                                  label={`Option ${answerIndex + 1}`}
                                  value={answers[answerIndex]}
                                  onChange={(e) =>
                                    handleChangeAnswer(
                                      e.target.value,
                                      answerIndex
                                    )
                                  }
                                />
                              </CustomAnswerWrapper>
                            </Grid>
                          ))}
                        </>
                      )}

                      {form.type === 'How often' && (
                        <AnswerCard title="Answers" cardsText={oftenAnswers} />
                      )}

                      {form.type === 'Agree or disagree' && (
                        <AnswerCard
                          title="Answers"
                          cardsText={agreeDisagreeAnswers}
                        />
                      )}
                    </Grid>

                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isCreatingQuestion}
                      style={{ color: '#fff' }}
                    >
                      {isCreatingQuestion
                        ? 'Creating question...'
                        : 'Create question'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};
