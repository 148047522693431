import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 40px 40px;

  aside {
    display: flex;
    justify-content: center;
    align-items: center;

    height: fit-content;
  }

  p {
    margin: 0;
  }

  .status-button {
    display: flex;
    justify-content: center;
    align-items: center;

    height: fit-content;
    width: fit-content;

    background-color: #fff;
    margin: 0 auto;
    border-radius: 50%;

    svg {
      cursor: pointer;
      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.5);
      }
    }
  }
`;

export const BorderlessButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
`;

export const CodeUsageButton = styled.button`
  display: flex;
  align-items: center;
  margin: 5px 0;
  border: none;
  padding: 10px;
  border-radius: 10px;

  transition: background-color 0.2s;

  &:hover {
    background-color: #fff;
  }
`;

export const SaveCodesMaxUsageButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  width: 100%;
  padding: 5px;

  transition: background-color 0.2s;

  &:hover {
    background-color: #f2f5f9;
  }
`;

export const ManagerLine = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  button {
    margin: 0 5px 0 0;
    padding: 0;
    border: none;
    background-color: #fff;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    cursor: pointer;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.5);
    }
  }
`;

export const OrganisationCodeLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  p {
    margin: 0;
    height: fit-content;
  }

  input {
    width: 80px;
    padding: 0 5px;
    border-radius: 5px;
    border: 1px solid #333;
    margin-left: 2px;
  }

  button {
    margin: 0 0 0 10px;
    padding: 0;
    border: none;
    background-color: #fff;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.5);
      }
    }
  }

  i {
    font-size: 0.1px;
  }
`;
