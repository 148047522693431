import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px;
`;

export const QuestionsGridWrapper = styled.main`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
`;

export const ToggleViewWrapper = styled.div`
  margin-left: auto;
  margin-bottom: 15px;

  display: flex;
  justify-content: start;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  input {
    border-radius: 10px;
    border: 1px solid #333333aa;
    height: 30px;
    padding: 0 5px;
  }
`;
