import { orderBy } from 'lodash';
import React, { createContext, useContext, useState, useEffect } from 'react';

import { getArticles } from '../functions/articles';

import { Article } from '../models/article';
import { useAuth } from './auth';

interface ArticleContextData {
  articles: Article[];
  isLoadingArticles: boolean;
  setArticles: (articles: Article[]) => void;
}

const ArticleContext = createContext<ArticleContextData>(
  {} as ArticleContextData
);

const ArticleProvider: React.FC = ({ children }) => {
  const [articles, setArticles] = useState([] as Article[]);
  const [isLoadingArticles, setIsLoadingArticles] = useState(true);

  const { user } = useAuth();

  useEffect(() => {
    if (!user) return;
    if (articles.length) return;

    const getAllArticles = async () => {
      const response = await getArticles();

      orderBy(response, ['publishedAt'], ['desc']);

      setIsLoadingArticles(false);

      setArticles(response);
    };

    getAllArticles();
  }, [user, articles]);

  return (
    <ArticleContext.Provider
      value={{ articles, isLoadingArticles, setArticles }}
    >
      {children}
    </ArticleContext.Provider>
  );
};

function useArticles(): ArticleContextData {
  const context = useContext(ArticleContext);

  return context;
}

export { ArticleProvider, useArticles };
