import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Delete, ModeEdit, Save } from '@mui/icons-material';
import lodash from 'lodash';

import { useToast } from '../../../../hooks/toast';
import { Question } from '../../../../models/question';
import {
  updateQuestion,
  deleteQuestion
} from '../../../../functions/questions';

import { Container, HintBottom, HintBox } from './styles';

interface QuestionCardProps {
  question: Question;
  setQuestions: Dispatch<SetStateAction<Question[]>>;
  setIsDeletingQuestion: Dispatch<React.SetStateAction<boolean>>;
  setIsUpdatingQuestion: Dispatch<React.SetStateAction<boolean>>;
}

export function QuestionCard({
  question,
  setQuestions,
  setIsDeletingQuestion,
  setIsUpdatingQuestion
}: QuestionCardProps) {
  const [mockQuestion, setMockQuestion] = useState({ ...question });
  const [isEditingQuestion, setIsEditingQuestion] = useState(false);

  const { addToast } = useToast();

  const handleRemoveQuestion = useCallback(async () => {
    const alertResponse = window.confirm(
      'Are you sure you want to delete this question?'
    );

    if (!alertResponse) return;

    try {
      setIsDeletingQuestion(true);
      await deleteQuestion(question);

      setQuestions((oldState) =>
        oldState.filter((_question) => question.id !== _question.id)
      );

      setIsDeletingQuestion(false);
      addToast({
        title: 'Question deleted',
        type: 'success'
      });
    } catch (error) {
      setIsDeletingQuestion(false);
      addToast({
        title: 'Error trying to delete question',
        type: 'error',
        description: 'Please, try again later'
      });
    }
  }, [question, addToast, setIsDeletingQuestion, setQuestions]);

  const handleUpdateQuestion = useCallback(async () => {
    setIsEditingQuestion(false);

    const questionHasChanged = !lodash.isEqual(question, mockQuestion);
    if (!questionHasChanged) return;

    try {
      setIsUpdatingQuestion(true);
      const copyMockQuestion = { ...mockQuestion };
      copyMockQuestion.invertedAnswers = !!mockQuestion.invertedAnswers;

      await updateQuestion(copyMockQuestion);

      setQuestions((oldState) => {
        const questionIndex = oldState.findIndex(
          (_question) => _question.id === question.id
        );
        const questionsArray = [...oldState];
        questionsArray[questionIndex] = { ...copyMockQuestion };

        return questionsArray;
      });

      setIsUpdatingQuestion(false);
      addToast({
        title: 'Question updated',
        type: 'success'
      });
    } catch (error) {
      console.log(error);
      setIsUpdatingQuestion(false);

      addToast({
        title: 'Error updating question',
        description: 'Please, try again later',
        type: 'error'
      });
    }
  }, [question, mockQuestion, setIsUpdatingQuestion, setQuestions, addToast]);

  return (
    <Container questionCategory={question.category}>
      <strong>
        {question.category}
        <div>
          {!isEditingQuestion && (
            <button
              title="Edit question"
              type="button"
              onClick={() => setIsEditingQuestion(true)}
            >
              <ModeEdit color="secondary" />
            </button>
          )}

          {isEditingQuestion && (
            <button
              title="Confirm edit"
              type="button"
              onClick={handleUpdateQuestion}
            >
              <Save color="secondary" />
            </button>
          )}

          <button
            title="Remove question"
            type="button"
            onClick={handleRemoveQuestion}
          >
            <Delete color="secondary" />
          </button>
        </div>
      </strong>

      <HintBox>
        <p>Section</p>
        <h5>{mockQuestion.section}</h5>
      </HintBox>

      <HintBox>
        <p>Questionnaire</p>
        <h5>{mockQuestion.questionnaire}</h5>
      </HintBox>

      <HintBox>
        <p>Question</p>
        {isEditingQuestion ? (
          <input
            type="text"
            value={mockQuestion.label}
            onChange={(e) =>
              setMockQuestion({ ...mockQuestion, label: e.target.value })
            }
          />
        ) : (
          <h5>{mockQuestion.label}</h5>
        )}
      </HintBox>

      <HintBox>
        <p>Answer type</p>
        <h5>
          {mockQuestion.type}
          {mockQuestion.type === 'Number' && ` (${mockQuestion.unit})`}
        </h5>
      </HintBox>

      {isEditingQuestion && mockQuestion.type === 'Number' && (
        <HintBox>
          <p>Unit</p>
          <input
            type="text"
            value={mockQuestion.unit}
            onChange={(e) =>
              setMockQuestion({
                ...mockQuestion,
                unit: e.target.value
              })
            }
          />
        </HintBox>
      )}

      {/* {isEditingQuestion ? (
        <HintBox>
          <p>Order</p>
          <input
            type="number"
            value={mockQuestion.order}
            onChange={(e) =>
              setMockQuestion({
                ...mockQuestion,
                order: Number(e.target.value)
              })
            }
          />
        </HintBox>
      ) : (
        <HintBox>
          <p>Order</p>
          <p>{mockQuestion.order}</p>
        </HintBox>
      )} */}

      <HintBox>
        <p>Order</p>
        <p>{mockQuestion.order}</p>
      </HintBox>

      {isEditingQuestion && (
        <HintBox title="Inverted answers: It means answers like Strongly agree, or Very often, will have a negative impact on the overal score">
          <p>Inverted answers</p>
          <input
            type="checkbox"
            checked={!!mockQuestion.invertedAnswers}
            onChange={(e) => {
              setMockQuestion({
                ...mockQuestion,
                invertedAnswers: e.target.checked
              });
            }}
          />
        </HintBox>
      )}

      {!isEditingQuestion && (
        <HintBottom>
          {/* <HintBox>
            <p>Order</p>
            <h5>{mockQuestion.order}</h5>
          </HintBox> */}

          <HintBox>
            <p>Inverted answers</p>
            <input
              type="checkbox"
              disabled
              checked={mockQuestion.invertedAnswers}
            />
          </HintBox>
        </HintBottom>
      )}
    </Container>
  );
}
