import { useCallback, useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import { useLocation, matchPath } from 'react-router-dom';
import { ListSubheader } from '@mui/material';

import { MenuWrapper, SubMenuWrapper } from './styles';

import menuItems, { MenuItem } from './items';
import SidebarMenuItem from './item';
import { useAuth } from '../../../../hooks/auth';
import { useOrganisation } from '../../../../hooks/organisations';
import { Organisation } from '../../../../models/organisation';

interface ChildRoutesProps {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
}

interface SideBarMenuItems {
  // eslint-disable-next-line react/no-unused-prop-types
  items: MenuItem[];
  // eslint-disable-next-line react/no-unused-prop-types
  path: string;
}

function SidebarMenu() {
  const location = useLocation();
  const { user } = useAuth();
  const { organisations } = useOrganisation();

  const [currentUserOrganisation, setCurrentUserOrganisation] =
    useState<Organisation>();

  useEffect(() => {
    if (!user || !organisations) return;

    const userOrganisation = organisations.find(
      ({ id }) => id === user.organisation.id
    );

    if (userOrganisation) setCurrentUserOrganisation(userOrganisation);
  }, [user, organisations]);

  const reduceChildRoutes = useCallback(
    ({ ev, path, item }: ChildRoutesProps): Array<JSX.Element> => {
      const key = uniqueId();

      const userHasNoAccessToCurrentMenuItem =
        item?.hasPermission &&
        !item?.hasPermission(user, currentUserOrganisation);

      if (userHasNoAccessToCurrentMenuItem) {
        // Return the current list of menu items. So it avoid update the list with the current menu item.
        return ev;
      }

      const Path = (link: string, end: boolean) => {
        return !!matchPath(
          {
            path: link,
            end
          },
          path
        );
      };

      const exactMatch = item.link ? Path(item.link, true) : false;

      if (item.items) {
        const partialMatch = item.link ? Path(item.link, false) : false;

        ev.push(
          <SidebarMenuItem
            key={key}
            active={partialMatch ? 'true' : 'false'}
            open={partialMatch}
            name={item.name}
            icon={item.icon}
            link={item.link}
            badge={item.badge}
            role={item.role}
          >
            {renderSidebarMenuItems({
              path,
              items: item.items
            })}
          </SidebarMenuItem>
        );
      } else {
        ev.push(
          <SidebarMenuItem
            key={key}
            active={exactMatch ? 'true' : 'false'}
            name={item.name}
            link={item.link}
            badge={item.badge}
            icon={item.icon}
            role={item.role}
          />
        );
      }

      return ev;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, currentUserOrganisation]
  );

  const renderSidebarMenuItems = useCallback(
    ({ items, path }: SideBarMenuItems): JSX.Element => {
      return (
        <SubMenuWrapper>
          {items.reduce(
            (ev, item) => reduceChildRoutes({ ev, item, path }),
            [] as JSX.Element[]
          )}
        </SubMenuWrapper>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reduceChildRoutes]
  );

  return (
    <>
      {menuItems.map((section) => (
        <MenuWrapper
          key={section.heading}
          subheader={
            <ListSubheader component="div" disableSticky>
              {section.heading}
            </ListSubheader>
          }
        >
          {renderSidebarMenuItems({
            items: section.items,
            path: location.pathname
          })}
        </MenuWrapper>
      ))}
    </>
  );
}

export default SidebarMenu;
