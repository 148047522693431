import { Link } from 'react-router-dom';

import { Box, Hidden, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import { useSideBar } from '../../../hooks/sidebar';
import { useAuth } from '../../../hooks/auth';

import { version } from '../../../../package.json';

import HeaderUserbox from './Userbox';
import { PureLightTheme } from '../../../styles/schemes/PureLightTheme';

import Logo from '../../../assets/logo.png';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-color: ${PureLightTheme.colors.primary.light};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;

        .logo-img {
          width: 40%;
          padding: 5px;
          background-color: #fff;
          border-radius: 10px;
        }

        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
  const { user } = useAuth();
  const { sidebarToggle, toggleSidebar } = useSideBar();

  return (
    <>
      {user && (
        <HeaderWrapper display="flex" alignItems="center">
          <div>
            <h2 style={{ color: '#333333aa' }}>KYND Dashboard</h2>
            <p>Version {version}</p>
          </div>

          <Box display="flex" alignItems="center">
            <Hidden lgUp>
              <Link to="/">
                <img className="logo-img" src={Logo} alt="Logo" />
              </Link>
            </Hidden>
          </Box>
          <Box display="flex" alignItems="center">
            {/* <HeaderButtons /> */}
            <HeaderUserbox />
            <Hidden lgUp>
              <Tooltip arrow title="Toggle Menu">
                <IconButton color="primary" onClick={toggleSidebar}>
                  {!sidebarToggle ? (
                    <MenuTwoToneIcon color="secondary" />
                  ) : (
                    <CloseTwoToneIcon color="secondary" />
                  )}
                </IconButton>
              </Tooltip>
            </Hidden>
          </Box>
        </HeaderWrapper>
      )}
    </>
  );
}

export default Header;
